import { capitalize } from "@health/common";
import { i18n, i18next } from "@health/i18n";
import { Order, OrderDirection, OrderSortField } from "@health/queries";
import { CustomTableColumnProps } from "@health/ui";
import _ from "lodash";
import moment from "moment-timezone";
import "moment/locale/ar";
import "moment/locale/en-il";
import { EditStatusModel } from "./components/EditStatus/EditStatus.component";
import { LifeCycleModel } from "./components/LifeCycle.components";
import { MedicationsModel } from "./components/Medications.components";
import { Status } from "./components/Status.components";
import { formatTimeSlotForColumn } from "./PrescriptionsFilter.components";
// eslint-disable-next-line prettier/prettier
import { priceFormatter } from "./util";

export const PrescriptionsColumns = (t: i18next.TFunction, refetch): CustomTableColumnProps<Order>[] => [
  {
    key: "Number",
    header: t("eRx Number"),
    accessor: ({ prescription }) => prescription?.referenceNumber,
    isHidden: false,
    hideFromSettings: true,
    disableToggleVisibility: true,
  },
  {
    key: "number",
    header: t("Order Number"),
    accessor: ({ number }) => number,
    isSortable: true,
    sortDirection: OrderDirection?.Asc,
    sortColumnEnum: OrderSortField.Number,
  },
  {
    key: "type",
    header: t("Order Type"),
    accessor: ({ type }) => (type?.includes("_") ? capitalize(type.toLowerCase().split("_")) : capitalize([String(type)])),
  },
  {
    key: "patientId",
    header: t("Patient Id"),
    accessor: ({ prescription }) => String(prescription?.patientIdentifier ?? "-"),
  },
  {
    key: "patient name",
    header: t("Patient Name"),
    accessor: ({ prescription }) => String(prescription?.patient?.firstName ?? "") + " " + String(prescription?.patient?.lastName ?? ""),
  },
  {
    key: "contactNumber",
    header: t("Patient Mobile"),
    accessor: ({ prescription }) => <span className='mobile-phone-rtl-fix'>{String(prescription?.patient?.contactNumber ?? "")}</span>,
  },

  {
    key: "paymentPrice",
    header: t("Payment Price"),
    accessor: ({ assignedShippingPrice }) => String(priceFormatter(assignedShippingPrice)),
  },
  {
    key: "Medications",
    header: t("Medications"),
    accessor: ({ lines, children }) => {
      const _lines = children?.length ? _.flatten(children.map(child => child?.lines!)) : lines;
      return <MedicationsModel lines={_lines} />;
    },
  },
  {
    key: "Created",
    header: t("Created"),
    accessor: ({ created }) => {
      moment.localeData("ar");
      const createdTz = moment.tz.guess();
      moment.locale(i18n.language);
      return moment(created).tz(createdTz).format("DD MMM YYYY hh:mm a");
    },
    isSortable: true,
    sortDirection: OrderDirection?.Asc,
    sortColumnEnum: OrderSortField.CreationDate,
  },
  {
    key: "Order Status",
    header: t("Order Status"),
    accessor: ({ status }) => <Status status={status} />,
  },
  {
    key: "Delivery Status",
    header: t("Delivery Status"),
    accessor: ({ deliveryStatus }) => <Status status={deliveryStatus} />,
  },
  {
    key: "Source",
    header: t("Source"),
    accessor: ({ source }) => String(capitalize(source.toLowerCase().split("_")) ?? "-"),
  },
  {
    key: "Requested Delivery Date",
    header: t("Requested Delivery Date"),
    accessor: ({ deliveryDate, deliveryTimeSlot }) => {
      if (!deliveryDate) return "-";
      return `
              ${moment(deliveryDate?.toString(), "YYYY-MM-DD").locale(i18n.language).format("DD MMM YYYY")},
              ${formatTimeSlotForColumn(deliveryTimeSlot?.startTime, i18n.language)} -  ${formatTimeSlotForColumn(
        deliveryTimeSlot?.endTime,
        i18n.language
      )}`;
    },
  },
  // todo
  // {
  //   key: "orders",
  //   header: t("Orders"),
  //   accessor: ({ children }) => <OrdersInfo ordersData={children} />,
  // },

  {
    key: "auditHistory",
    header: t("Audit History"),
    accessor: ({ id, prescription, shipmentInformation }) => (
      <LifeCycleModel
        orderId={id}
        referenceNumber={prescription?.referenceNumber!}
        dispenseStatus={prescription?.dispenseStatus!}
        shipmentInformation={shipmentInformation?.data}
      />
    ),
  },
  {
    key: "editStatus",
    header: t("Edit Status"),
    accessor: ({ id }) => {
      return <EditStatusModel id={id} refetch={refetch} />;
    },
  },
];
