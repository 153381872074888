/* eslint-disable max-lines */
import { BulkFileStatusChangedNotificationEvent, EVENT_TOPIC, TOPIC_LISTENER, useSubscribeToNotificationTopic } from "@health/common";
import { i18n, useTranslation } from "@health/i18n";
import {
  BranchesQueryVariables,
  BulkGraphqlError,
  BulkTemplateCode,
  formatMessageErrors,
  Order,
  OrderDeliveryStatusEnum,
  OrderDirection,
  OrderSortField,
  OrderSource,
  OrderSourcesEnum,
  OrderStatus,
  useBranchesQuery,
  useDataGridState,
  usePrescriptionsAdminQuery,
  useUploadBulkFileMutation,
} from "@health/queries";
import {
  CustomTableColumnProps,
  getTranslatedNameField,
  mapActiveFiltersForHeader,
  tableSettingsUtils,
  useAddToast,
  useAutocompleteCustomHook,
} from "@health/ui";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrescriptionsColumns } from "./PrescriptionsColumns";
import { formatDeliveryTimeSlot, usePrescriptionsFilter } from "./PrescriptionsFilter.components";

export const usePrescriptionsPage = () => {
  const { t } = useTranslation("Admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [bulkFileStatus, setBulkFileStatus] = useState<BulkFileStatusChangedNotificationEvent["data"]>();
  const [errors, setError] = useState<BulkGraphqlError[]>([]);
  const [isUploadDialogOpened, setIsUploadDialogOpened] = useState(false);

  const [uploadBulkFile, { data: importData, loading: isCreateLoading }] = useUploadBulkFileMutation({
    onCompleted: data => {
      const errors = data.uploadBulkFile?.errors;
      if (errors && errors?.length > 0) {
        const error = formatMessageErrors(errors);
        failed(t(error));
        setError(errors as BulkGraphqlError[]);
      } else {
        succeeded(t("Customer Service Order Created Successfully"));
        navigate(-1);
      }
    },
    onError: error => {
      failed(t("Failed to Create Customer Service Order"));
      console.error(t("error"), error);
    },
  });

  const inputs = {
    first: 10,
    after: undefined,
    before: undefined,
    last: undefined,
    filter: {
      parentOrders: true,
      source: [OrderSourcesEnum.PrescriptionDispense, OrderSourcesEnum.PrescriptionRefill, OrderSourcesEnum.CsTicket],
    },
    sortBy: {
      direction: OrderDirection.Desc,
      field: OrderSortField.Number,
    },
  };

  const { data, isLoading, refetch, pageSize, doChangePageSize, doSort, doFilter, doGoToNext, doGoToPrevious } = useDataGridState({
    useCustomQuery: usePrescriptionsAdminQuery,
    input: inputs,
  });

  const dataAccessorForBranches = "branches";
  const inputsBranches: BranchesQueryVariables = {
    first: 10,
    after: null,
    before: null,
    last: null,
    filter: {
      isActive: true,
    },
  };

  const {
    data: branches,
    isLoading: loadingBranches,
    onFetchMoreData: onFetchMoreBranches,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useBranchesQuery,
    input: inputsBranches,
    dataAccessor: dataAccessorForBranches,
  });
  const pageInfoForBranches = branches?.[dataAccessorForBranches]?.pageInfo;
  const hasMoreForInfoForBranches = pageInfoForBranches?.hasNextPage;
  const pharmacyBranches = branches?.[dataAccessorForBranches]?.edges?.map(item => item?.node)!;
  const branchesData = {
    loading: loadingBranches,
    onFetchMore: onFetchMoreBranches,
    hasMore: hasMoreForInfoForBranches,
    options: pharmacyBranches,
  };

  const [columns, setColumns] = useState<CustomTableColumnProps<Order>[]>(PrescriptionsColumns(t, refetch));
  const prescriptions = (data?.orders?.edges?.map(prescription => prescription?.node) as Order[]) || [];
  const pageInfo = data?.orders?.pageInfo;
  const totalCount = data?.orders?.totalCount;
  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});
  const handleGotoNext = () => {
    doGoToNext();
  };
  const handleGoToPrevious = () => {
    doGoToPrevious();
  };

  const handlePageSizeChange = (size: number): void => {
    doChangePageSize(size);
  };
  const handleSortData = row => {
    doSort(row.sortColumnEnum);
  };

  const handleApplyFilters = filteredData => {
    const { createdTo, status, deliveryStatus, ordersNumbers, orderType, ...rest } = filteredData;
    const filteredStatus = status ? [OrderStatus[status.replace(/\s+/g, "")]] : [];
    setActiveFilters({ ...filteredData, [t("orderType")]: filteredData.orderType });

    const deliveryStatusData = deliveryStatus ? [OrderDeliveryStatusEnum[deliveryStatus.replace(/\s+/g, "")]] : [];
    doFilter({
      ...rest,
      source: filteredData.source ? OrderSource[filteredData.source.replace(/\s/g, "")] : [],
      isDelivery: orderType ? deliveryTypeOptions.find(item => item?.label === orderType)?.value : undefined,
      branches: filteredData?.branches?.id ? [filteredData?.branches?.id] : null,
      deliveryStatus: deliveryStatusData || [],
      numbers: ordersNumbers && !isNaN(ordersNumbers) ? [String(ordersNumbers)] : [],
      parentOrders: true,
      status: filteredStatus || [],
      deliveryTimeSlot: filteredData?.deliveryTimeSlot?.id,
      deliveryDate: filteredData?.deliveryDate || undefined,
      created:
        filteredData?.created || createdTo
          ? {
              lte: filteredData?.createdTo || null,
              gte: filteredData?.created || null,
            }
          : null,
    });
  };

  const onBulkFileChanged: TOPIC_LISTENER<BulkFileStatusChangedNotificationEvent>[EVENT_TOPIC.BULK_FILE] = useCallback(({ data }) => {
    if (data) {
      setBulkFileStatus(data);
    }
  }, []);
  useSubscribeToNotificationTopic(EVENT_TOPIC.BULK_FILE, onBulkFileChanged);

  const handleToggleColumnVisibility = updatedColumn => {
    setColumns(tableSettingsUtils.toggleColumnVisibility(columns, updatedColumn));
  };

  const handleSetAllColumnVisibility = (isHidden: boolean) => {
    setColumns(tableSettingsUtils.toggleAllColumnsVisibility(columns, isHidden));
  };
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const handleToggleFilters = () => {
    setIsFilterOpened(state => !state);
  };

  const pageHeaderFiltersMappers = {
    branches: value => (!value ? undefined : `${getTranslatedNameField(value)} `),
    deliveryTimeSlot: value => (!value ? undefined : `${formatDeliveryTimeSlot(value?.startTime, value?.endTime, i18n.language)} `),
  };
  const activeFiltersDisplay = mapActiveFiltersForHeader(activeFilters, pageHeaderFiltersMappers);
  const { filters, deliveryTypeOptions } = usePrescriptionsFilter(activeFilters, branchesData);

  const handleAddNewFailedOrder = () => navigate("/prescriptions/new");

  const handleOpenImport = () => {
    setIsUploadDialogOpened(true);
  };

  const onCloseImportDialog = () => {
    setIsUploadDialogOpened(false);
  };

  const handleOnUpload = (file: any) => {
    uploadBulkFile({
      variables: {
        fileName: file,
        code: BulkTemplateCode.PrescriptionOrder,
      },
    });
  };

  return {
    branches: branchesData,
    filters,
    columns,
    errors,
    isLoading,
    pageSize,
    pageInfo,
    totalCount,
    prescriptions,
    activeFilters,
    isFilterOpened,
    bulkFileStatus,
    activeFiltersDisplay,
    isCreateLoading,
    importData,
    isUploadDialogOpened,
    handleOnUpload,
    onCloseImportDialog,
    handleToggleFilters,
    refetch,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleSortData,
    handleOpenImport,
    handleApplyFilters,
    handleToggleColumnVisibility,
    handleSetAllColumnVisibility,
    handleAddNewFailedOrder,
  };
};
