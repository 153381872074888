/* eslint-disable */
/* prettier-ignore */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: any;
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: any;
  Decimal: any;
  DictType: any;
  Double: any;
  H_Long: any;
  Instant: any;
  JSONString: any;
  LocalDateTime: any;
  P_Date: any;
  Time: any;
  UUID: any;
  /** A custom scalar that represents files */
  Upload: any;
  WeightScalar: any;
};

export type AccountAddressCreate = {
  __typename?: 'AccountAddressCreate';
  accountErrors: Array<AccountError>;
  address?: Maybe<Address>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAddressDelete = {
  __typename?: 'AccountAddressDelete';
  accountErrors: Array<AccountError>;
  address?: Maybe<Address>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAddressUpdate = {
  __typename?: 'AccountAddressUpdate';
  accountErrors: Array<AccountError>;
  address?: Maybe<Address>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAvatarDelete = {
  __typename?: 'AccountAvatarDelete';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAvatarUpdate = {
  __typename?: 'AccountAvatarUpdate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountError = {
  __typename?: 'AccountError';
  code: AccountErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum AccountErrorCode {
  ActivateOwnAccount = 'ACTIVATE_OWN_ACCOUNT',
  ActivateSuperuserAccount = 'ACTIVATE_SUPERUSER_ACCOUNT',
  DeactivateOwnAccount = 'DEACTIVATE_OWN_ACCOUNT',
  DeactivateSuperuserAccount = 'DEACTIVATE_SUPERUSER_ACCOUNT',
  DeleteConsumerAccount = 'DELETE_CONSUMER_ACCOUNT',
  DeleteNonStaffUser = 'DELETE_NON_STAFF_USER',
  DeleteOwnAccount = 'DELETE_OWN_ACCOUNT',
  DeleteStaffAccount = 'DELETE_STAFF_ACCOUNT',
  DeleteSuperuserAccount = 'DELETE_SUPERUSER_ACCOUNT',
  DeleteSupervendorAccount = 'DELETE_SUPERVENDOR_ACCOUNT',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  IntegrationTokenError = 'INTEGRATION_TOKEN_ERROR',
  Invalid = 'INVALID',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  InvalidPassword = 'INVALID_PASSWORD',
  LeftNotManageablePermission = 'LEFT_NOT_MANAGEABLE_PERMISSION',
  NotFound = 'NOT_FOUND',
  OutOfScopeGroup = 'OUT_OF_SCOPE_GROUP',
  OutOfScopePermission = 'OUT_OF_SCOPE_PERMISSION',
  OutOfScopeServiceAccount = 'OUT_OF_SCOPE_SERVICE_ACCOUNT',
  OutOfScopeUser = 'OUT_OF_SCOPE_USER',
  PasswordEntirelyNumeric = 'PASSWORD_ENTIRELY_NUMERIC',
  PasswordTooCommon = 'PASSWORD_TOO_COMMON',
  PasswordTooShort = 'PASSWORD_TOO_SHORT',
  PasswordTooSimilar = 'PASSWORD_TOO_SIMILAR',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type AccountInput = {
  defaultBillingAddress?: InputMaybe<AddressInput>;
  defaultShippingAddress?: InputMaybe<AddressInput>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type AccountReSendEmailOtpCode = {
  __typename?: 'AccountReSendEmailOTPCode';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars['String']>;
};

export type AccountReSendOtpCode = {
  __typename?: 'AccountReSendOTPCode';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars['String']>;
};

export type AccountRegisterInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobile: Scalars['String'];
  nationalId: Scalars['String'];
  password: Scalars['String'];
};

export type AccountSetDefaultAddress = {
  __typename?: 'AccountSetDefaultAddress';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type Action = {
  __typename?: 'Action';
  actionFields?: Maybe<Array<Maybe<ActionField>>>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
};

export type ActionDefinitionField = {
  __typename?: 'ActionDefinitionField';
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<ActionFieldType>;
};

export type ActionField = {
  __typename?: 'ActionField';
  code?: Maybe<Scalars['String']>;
  type: ActionFieldType;
  valueBoolean?: Maybe<Scalars['Boolean']>;
  valueDate?: Maybe<Scalars['Date']>;
  valueNumber?: Maybe<Scalars['Float']>;
  valueString?: Maybe<Scalars['String']>;
};

export type ActionFieldInput = {
  code?: InputMaybe<Scalars['String']>;
  type: ActionFieldType;
  valueBoolean?: InputMaybe<Scalars['Boolean']>;
  valueDate?: InputMaybe<Scalars['Date']>;
  valueNumber?: InputMaybe<Scalars['Float']>;
  valueString?: InputMaybe<Scalars['String']>;
};

export enum ActionFieldType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Number = 'NUMBER',
  String = 'STRING'
}

export type ActiveDispensedPrescription = {
  __typename?: 'ActiveDispensedPrescription';
  activities?: Maybe<Array<Maybe<Activity>>>;
  overallStatus?: Maybe<Scalars['String']>;
  prescriptionDate?: Maybe<Scalars['String']>;
};

export type Activity = {
  __typename?: 'Activity';
  activityReference?: Maybe<Scalars['String']>;
  alternativeReason?: Maybe<Scalars['String']>;
  arabicInstructions?: Maybe<Scalars['String']>;
  clinician?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  denialCode?: Maybe<Array<Maybe<DenialCode>>>;
  dispenseTrack?: Maybe<Array<Maybe<DispenseTrack>>>;
  dispensedActivityID?: Maybe<Scalars['String']>;
  dispensedActivityId?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  frequencies?: Maybe<Array<Maybe<Frequency>>>;
  id: Scalars['ID'];
  instructionType?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  nHICCode?: Maybe<Scalars['String']>;
  net?: Maybe<Scalars['Float']>;
  observations?: Maybe<Array<Maybe<Observation>>>;
  patientShare?: Maybe<Scalars['Float']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  pharmacistInstructions?: Maybe<Scalars['String']>;
  priorAuthorizationID?: Maybe<Scalars['String']>;
  prn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  refills?: Maybe<Scalars['Int']>;
  remainingRefills?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<ActivityResult>>>;
  routOfAdmin?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  unitId?: Maybe<Scalars['Float']>;
};

export type ActivityResult = {
  __typename?: 'ActivityResult';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type AddVendorToFavorite = {
  __typename?: 'AddVendorToFavorite';
  customerFavoriteVendor?: Maybe<CustomerFavoriteVendor>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
};

export type Address = Node & {
  __typename?: 'Address';
  area?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  city: City;
  coordinates?: Maybe<LocationType>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDefaultBillingAddress?: Maybe<Scalars['Boolean']>;
  isDefaultShippingAddress?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  streetAddress1?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<AddressTranslation>>>;
  unitNumber?: Maybe<Scalars['String']>;
};

export type AddressCountableConnection = {
  __typename?: 'AddressCountableConnection';
  edges: Array<AddressCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AddressCountableEdge = {
  __typename?: 'AddressCountableEdge';
  cursor: Scalars['String'];
  node: Address;
};

export type AddressInput = {
  area?: InputMaybe<Scalars['String']>;
  buildingName?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['ID']>;
  coordinates?: InputMaybe<LocationInput>;
  district?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  streetAddress1?: InputMaybe<Scalars['String']>;
  streetAddress2?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<AddressTranslationInput>>>;
  unitNumber?: InputMaybe<Scalars['String']>;
};

export type AddressTranslation = Node & {
  __typename?: 'AddressTranslation';
  area: Scalars['String'];
  buildingName: Scalars['String'];
  district: Scalars['String'];
  id: Scalars['ID'];
  language: LanguageDisplay;
  name?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  streetAddress1: Scalars['String'];
  streetAddress2: Scalars['String'];
};

export type AddressTranslationInput = {
  area?: InputMaybe<Scalars['String']>;
  buildingName?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  languageCode: LanguageCodeEnum;
  name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  streetAddress1?: InputMaybe<Scalars['String']>;
  streetAddress2?: InputMaybe<Scalars['String']>;
};

export enum AddressTypeEnum {
  Billing = 'BILLING',
  Shipping = 'SHIPPING'
}

export type AdminUserCreateInput = {
  addGroups?: InputMaybe<Array<Scalars['ID']>>;
  appRole: AppRoleTypes;
  appType: AppTypes;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobile: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  vendor?: InputMaybe<Scalars['ID']>;
};

export type Aggregation = {
  __typename?: 'Aggregation';
  key: Scalars['String'];
  value: Scalars['Int'];
};

export enum AmountSourceType {
  Order = 'ORDER'
}

export enum AppRoleTypes {
  Admin = 'ADMIN',
  User = 'USER'
}

export enum AppTypes {
  Admin = 'ADMIN',
  Aggregator = 'AGGREGATOR',
  Customer = 'CUSTOMER',
  Vendor = 'VENDOR'
}

export type ApproveVendor = {
  __typename?: 'ApproveVendor';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type AssignUserToBranch = {
  __typename?: 'AssignUserToBranch';
  branchErrors: Array<BranchError>;
  branchUser?: Maybe<BranchUser>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type Attachment = Node & {
  __typename?: 'Attachment';
  alt: Scalars['String'];
  contentType: Scalars['String'];
  created: Scalars['DateTime'];
  file?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  messages: MessageCountableConnection;
  modified: Scalars['DateTime'];
  sortOrder?: Maybe<Scalars['Int']>;
};


export type AttachmentMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AttachmentInput = {
  alt?: InputMaybe<Scalars['String']>;
  contentType: Scalars['String'];
  file: Scalars['String'];
};

export type Attribute = Node & ObjectWithBasicTranslatableFields & {
  __typename?: 'Attribute';
  availableInGrid: Scalars['Boolean'];
  filterableInDashboard: Scalars['Boolean'];
  filterableInStorefront: Scalars['Boolean'];
  id: Scalars['ID'];
  inputType?: Maybe<AttributeInputTypeEnum>;
  name?: Maybe<Scalars['String']>;
  productTypes: ProductTypeCountableConnection;
  productVariantTypes: ProductTypeCountableConnection;
  slug?: Maybe<Scalars['String']>;
  storefrontSearchPosition: Scalars['Int'];
  translation?: Maybe<AttributeTranslation>;
  valueRequired: Scalars['Boolean'];
  values?: Maybe<Array<Maybe<AttributeValue>>>;
  visibleInStorefront: Scalars['Boolean'];
};


export type AttributeProductTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AttributeProductVariantTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AttributeTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type AttributeAssign = {
  __typename?: 'AttributeAssign';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductAttributeError>;
  productType?: Maybe<ProductType>;
};

export type AttributeAssignInput = {
  id: Scalars['ID'];
  type: AttributeTypeEnum;
};

export type AttributeBulkDelete = {
  __typename?: 'AttributeBulkDelete';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type AttributeCountableConnection = {
  __typename?: 'AttributeCountableConnection';
  edges: Array<AttributeCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AttributeCountableEdge = {
  __typename?: 'AttributeCountableEdge';
  cursor: Scalars['String'];
  node: Attribute;
};

export type AttributeCreate = {
  __typename?: 'AttributeCreate';
  attribute?: Maybe<Attribute>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type AttributeCreateInput = {
  availableInGrid?: InputMaybe<Scalars['Boolean']>;
  filterableInDashboard?: InputMaybe<Scalars['Boolean']>;
  filterableInStorefront?: InputMaybe<Scalars['Boolean']>;
  inputType?: InputMaybe<AttributeInputTypeEnum>;
  isVariantOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  storefrontSearchPosition?: InputMaybe<Scalars['Int']>;
  valueRequired?: InputMaybe<Scalars['Boolean']>;
  values?: InputMaybe<Array<InputMaybe<AttributeValueCreateInput>>>;
  visibleInStorefront?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeDelete = {
  __typename?: 'AttributeDelete';
  attribute?: Maybe<Attribute>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type AttributeFilterInput = {
  availableInGrid?: InputMaybe<Scalars['Boolean']>;
  filterableInDashboard?: InputMaybe<Scalars['Boolean']>;
  filterableInStorefront?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  inCategory?: InputMaybe<Scalars['ID']>;
  inCollection?: InputMaybe<Scalars['ID']>;
  isVariantOnly?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  valueRequired?: InputMaybe<Scalars['Boolean']>;
  visibleInStorefront?: InputMaybe<Scalars['Boolean']>;
};

export enum AttributeInputTypeEnum {
  Dropdown = 'DROPDOWN',
  Multiselect = 'MULTISELECT'
}

export type AttributeReorderValues = {
  __typename?: 'AttributeReorderValues';
  attribute?: Maybe<Attribute>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export enum AttributeSortField {
  AvailableInGrid = 'AVAILABLE_IN_GRID',
  FilterableInDashboard = 'FILTERABLE_IN_DASHBOARD',
  FilterableInStorefront = 'FILTERABLE_IN_STOREFRONT',
  IsVariantOnly = 'IS_VARIANT_ONLY',
  Name = 'NAME',
  Slug = 'SLUG',
  StorefrontSearchPosition = 'STOREFRONT_SEARCH_POSITION',
  ValueRequired = 'VALUE_REQUIRED',
  VisibleInStorefront = 'VISIBLE_IN_STOREFRONT'
}

export type AttributeSortingInput = {
  direction: OrderDirection;
  field: AttributeSortField;
};

export type AttributeTranslatableContent = Node & {
  __typename?: 'AttributeTranslatableContent';
  attribute?: Maybe<Attribute>;
  id: Scalars['ID'];
  name: Scalars['String'];
  translation?: Maybe<AttributeTranslation>;
};


export type AttributeTranslatableContentTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type AttributeTranslate = {
  __typename?: 'AttributeTranslate';
  attribute?: Maybe<Attribute>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  translationErrors: Array<TranslationError>;
};

export type AttributeTranslation = Node & {
  __typename?: 'AttributeTranslation';
  id: Scalars['ID'];
  language: LanguageDisplay;
  name: Scalars['String'];
};

export enum AttributeTypeEnum {
  Product = 'PRODUCT',
  Variant = 'VARIANT'
}

export type AttributeUnassign = {
  __typename?: 'AttributeUnassign';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productType?: Maybe<ProductType>;
};

export type AttributeUpdate = {
  __typename?: 'AttributeUpdate';
  attribute?: Maybe<Attribute>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type AttributeUpdateInput = {
  addValues?: InputMaybe<Array<InputMaybe<AttributeValueCreateInput>>>;
  availableInGrid?: InputMaybe<Scalars['Boolean']>;
  filterableInDashboard?: InputMaybe<Scalars['Boolean']>;
  filterableInStorefront?: InputMaybe<Scalars['Boolean']>;
  isVariantOnly?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  removeValues?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  slug?: InputMaybe<Scalars['String']>;
  storefrontSearchPosition?: InputMaybe<Scalars['Int']>;
  valueRequired?: InputMaybe<Scalars['Boolean']>;
  visibleInStorefront?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeValue = Node & ObjectWithBasicTranslatableFields & {
  __typename?: 'AttributeValue';
  id: Scalars['ID'];
  inputType?: Maybe<AttributeInputTypeEnum>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  translation?: Maybe<AttributeValueTranslation>;
  type?: Maybe<AttributeValueType>;
};


export type AttributeValueTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type AttributeValueBulkDelete = {
  __typename?: 'AttributeValueBulkDelete';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type AttributeValueCreate = {
  __typename?: 'AttributeValueCreate';
  attribute?: Maybe<Attribute>;
  attributeValue?: Maybe<AttributeValue>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type AttributeValueCreateInput = {
  name: Scalars['String'];
};

export type AttributeValueDelete = {
  __typename?: 'AttributeValueDelete';
  attribute?: Maybe<Attribute>;
  attributeValue?: Maybe<AttributeValue>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type AttributeValueInput = {
  id?: InputMaybe<Scalars['ID']>;
  values: Array<InputMaybe<Scalars['String']>>;
};

export type AttributeValueTranslatableContent = Node & {
  __typename?: 'AttributeValueTranslatableContent';
  attributeValue?: Maybe<AttributeValue>;
  id: Scalars['ID'];
  name: Scalars['String'];
  translation?: Maybe<AttributeValueTranslation>;
};


export type AttributeValueTranslatableContentTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type AttributeValueTranslate = {
  __typename?: 'AttributeValueTranslate';
  attributeValue?: Maybe<AttributeValue>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  translationErrors: Array<TranslationError>;
};

export type AttributeValueTranslation = Node & {
  __typename?: 'AttributeValueTranslation';
  id: Scalars['ID'];
  language: LanguageDisplay;
  name: Scalars['String'];
};

export enum AttributeValueType {
  Color = 'COLOR',
  Gradient = 'GRADIENT',
  String = 'STRING',
  Url = 'URL'
}

export type AttributeValueUpdate = {
  __typename?: 'AttributeValueUpdate';
  attribute?: Maybe<Attribute>;
  attributeValue?: Maybe<AttributeValue>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type AuditLog = H_Node & {
  __typename?: 'AuditLog';
  auditLogDirtyFields?: Maybe<Array<Maybe<AuditLogDirtyField>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Instant']>;
  entityClassSimpleName?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  lastModifiedBy?: Maybe<Scalars['String']>;
  lastModifiedDate?: Maybe<Scalars['Instant']>;
  operationType?: Maybe<AuditLogOperationType>;
};

export type AuditLogCountableConnection = {
  __typename?: 'AuditLogCountableConnection';
  edges: Array<AuditLogCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']>;
};

export type AuditLogCountableEdge = {
  __typename?: 'AuditLogCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AuditLog;
};

export type AuditLogDirtyField = H_Node & {
  __typename?: 'AuditLogDirtyField';
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Instant']>;
  currentValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedBy?: Maybe<Scalars['String']>;
  lastModifiedDate?: Maybe<Scalars['Instant']>;
  name?: Maybe<Scalars['String']>;
  previousValue?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AuditLogFilterInput = {
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  entityNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  operationTypes?: InputMaybe<Array<InputMaybe<AuditLogOperationType>>>;
};

export enum AuditLogOperationType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type Block = Node & {
  __typename?: 'Block';
  city: City;
  coordinates?: Maybe<Array<Maybe<LocationType>>>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  nameAr?: Maybe<Scalars['String']>;
};

export type BlockCountableConnection = {
  __typename?: 'BlockCountableConnection';
  edges: Array<BlockCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type BlockCountableEdge = {
  __typename?: 'BlockCountableEdge';
  cursor: Scalars['String'];
  node: Block;
};

export type BlockCreate = {
  __typename?: 'BlockCreate';
  block?: Maybe<Block>;
  blockErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BlockDelete = {
  __typename?: 'BlockDelete';
  block?: Maybe<Block>;
  blockErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BlockError = {
  __typename?: 'BlockError';
  code: BlockErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum BlockErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type BlockFilterInput = {
  location?: InputMaybe<LocationInput>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
};

export type BlockInput = {
  city?: InputMaybe<Scalars['ID']>;
  coordinates?: InputMaybe<Array<LocationInput>>;
  name?: InputMaybe<Scalars['String']>;
  nameAr?: InputMaybe<Scalars['String']>;
};

export type BlockOrder = {
  direction: OrderDirection;
  field?: InputMaybe<BlockOrderField>;
};

export enum BlockOrderField {
  Created = 'CREATED',
  Name = 'NAME'
}

export type BlockUpdate = {
  __typename?: 'BlockUpdate';
  block?: Maybe<Block>;
  blockErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type Branch = Node & {
  __typename?: 'Branch';
  acceptsDelivery: Scalars['Boolean'];
  acceptsPickup: Scalars['Boolean'];
  address?: Maybe<Address>;
  branchuser: BranchUserCountableConnection;
  consumers: UserCountableConnection;
  contactEmail?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  description: Scalars['String'];
  healthLicense?: Maybe<Scalars['String']>;
  healthLicenseEndDate?: Maybe<Scalars['Date']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isOpen?: Maybe<Scalars['Boolean']>;
  isTrained: Scalars['Boolean'];
  messages?: Maybe<MessageCountableConnection>;
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  nameAr?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  type?: Maybe<BranchType>;
  unReadMessagesCountForCurrentUser?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<User>>>;
  vendor: Vendor;
  workingHours?: Maybe<Array<Maybe<WorkingHour>>>;
  workingHoursOverride?: Maybe<Array<Maybe<WorkingHourOverride>>>;
};


export type BranchBranchuserArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type BranchConsumersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type BranchMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type BranchCountableConnection = {
  __typename?: 'BranchCountableConnection';
  edges: Array<BranchCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type BranchCountableEdge = {
  __typename?: 'BranchCountableEdge';
  cursor: Scalars['String'];
  node: Branch;
};

export type BranchCreate = {
  __typename?: 'BranchCreate';
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BranchDelete = {
  __typename?: 'BranchDelete';
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BranchError = {
  __typename?: 'BranchError';
  code: VendorErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type BranchFilterInput = {
  acceptsDelivery?: InputMaybe<Scalars['Boolean']>;
  acceptsPickup?: InputMaybe<Scalars['Boolean']>;
  branchGlobalSearch?: InputMaybe<Scalars['String']>;
  contactNumber?: InputMaybe<Scalars['String']>;
  contactNumber_Icontains?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  description_Icontains?: InputMaybe<Scalars['String']>;
  hasChatWith?: InputMaybe<Scalars['Boolean']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isActiveBranch?: InputMaybe<Scalars['Boolean']>;
  isIntegrated?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<DistanceFilterInput>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<InputMaybe<VendorBranchTypeEnum>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type BranchInput = {
  acceptsDelivery?: InputMaybe<Scalars['Boolean']>;
  acceptsPickup?: InputMaybe<Scalars['Boolean']>;
  addressInput?: InputMaybe<AddressInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  healthLicense?: InputMaybe<Scalars['String']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isTrained?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nameAr?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<VendorBranchTypeEnum>;
  workingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  workingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
};

export type BranchInvoice = Node & {
  __typename?: 'BranchInvoice';
  branch?: Maybe<Branch>;
  branchId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  totalCashOrders: Scalars['Float'];
  totalCreditOrders: Scalars['Float'];
  totalOrders: Scalars['Int'];
  totalPaidShippingPrice?: Maybe<Scalars['Float']>;
  totalServiceCommission: Scalars['Float'];
  totalShippingPrice?: Maybe<Scalars['Float']>;
  totalWalletOrders: Scalars['Float'];
  vendorInvoice: VendorInvoice;
};

export type BranchOrder = {
  direction: OrderDirection;
  field?: InputMaybe<BranchOrderField>;
};

export enum BranchOrderField {
  Created = 'CREATED',
  LastMessageSent = 'LAST_MESSAGE_SENT',
  Name = 'NAME',
  Nearest = 'NEAREST'
}

export enum BranchType {
  MedicalDeliveryOnly = 'MEDICAL_DELIVERY_ONLY',
  PartnerWarehouse = 'PARTNER_WAREHOUSE',
  Pharmacy = 'PHARMACY',
  Phc = 'PHC',
  ReferencePharmacy = 'REFERENCE_PHARMACY'
}

export type BranchUpdate = {
  __typename?: 'BranchUpdate';
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BranchUser = {
  __typename?: 'BranchUser';
  branch: Branch;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  user: User;
};

export type BranchUserCountableConnection = {
  __typename?: 'BranchUserCountableConnection';
  edges: Array<BranchUserCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type BranchUserCountableEdge = {
  __typename?: 'BranchUserCountableEdge';
  cursor: Scalars['String'];
  node: BranchUser;
};

export enum BulkErrorCodes {
  BulkCancelBulkFile = 'BULK_CANCEL_BULK_FILE',
  BulkDuplicateRowData = 'BULK_DUPLICATE_ROW_DATA',
  BulkFieldRequired = 'BULK_FIELD_REQUIRED',
  BulkFileNotFound = 'BULK_FILE_NOT_FOUND',
  BulkFileServiceNotReachable = 'BULK_FILE_SERVICE_NOT_REACHABLE',
  BulkInvalidDateFormat = 'BULK_INVALID_DATE_FORMAT',
  BulkInvalidFileExtension = 'BULK_INVALID_FILE_EXTENSION',
  BulkNoneNegativePageSize = 'BULK_NONE_NEGATIVE_PAGE_SIZE',
  BulkPermissionDenied = 'BULK_PERMISSION_DENIED',
  BulkTemplateNotEnabled = 'BULK_TEMPLATE_NOT_ENABLED',
  BulkUnknown = 'BULK_UNKNOWN',
  BulkUnknownType = 'BULK_UNKNOWN_TYPE',
  FileAlreadyExist = 'FILE_ALREADY_EXIST'
}

export type BulkFile = {
  __typename?: 'BulkFile';
  code?: Maybe<BulkTemplateCode>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  errors?: Maybe<Array<Maybe<BulkGraphqlError>>>;
  failedRecordCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  processedRecordCount?: Maybe<Scalars['Int']>;
  recordsCount?: Maybe<Scalars['Int']>;
  status?: Maybe<BulkProcessingStatus>;
  updateDate?: Maybe<Scalars['Date']>;
};

export type BulkFileConnection = {
  __typename?: 'BulkFileConnection';
  edges?: Maybe<Array<Maybe<BulkFileEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type BulkFileEdge = {
  __typename?: 'BulkFileEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<BulkFile>;
};

export enum BulkFileSortField {
  CreatedDate = 'CREATED_DATE',
  UpdatedDate = 'UPDATED_DATE'
}

export type BulkFilesFilterInput = {
  code: BulkTemplateCode;
  createdBy?: InputMaybe<Scalars['ID']>;
  createdDate?: InputMaybe<Scalars['Date']>;
  entityID?: InputMaybe<Scalars['ID']>;
  processingStatus?: InputMaybe<BulkProcessingStatus>;
  updateDate?: InputMaybe<Scalars['Date']>;
};

export enum BulkFilesOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type BulkFilesSortingInput = {
  direction: BulkFilesOrderDirection;
  field: BulkFileSortField;
};

export type BulkGraphqlError = {
  __typename?: 'BulkGraphqlError';
  code?: Maybe<BulkErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum BulkProcessingStatus {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  UnderProcess = 'UNDER_PROCESS'
}

export type BulkProductError = {
  __typename?: 'BulkProductError';
  branches?: Maybe<Array<Scalars['ID']>>;
  code: ProductErrorCode;
  field?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type BulkTemplate = {
  __typename?: 'BulkTemplate';
  code?: Maybe<BulkTemplateCode>;
  description?: Maybe<Scalars['String']>;
};

export enum BulkTemplateCode {
  PrescriptionOrder = 'PRESCRIPTION_ORDER'
}

export enum CalculatedPaymentStatusForSubscription {
  PaymentChargedBack = 'PAYMENT_CHARGED_BACK',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentRejected = 'PAYMENT_REJECTED',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  RefundChargedBack = 'REFUND_CHARGED_BACK',
  RefundPending = 'REFUND_PENDING',
  RefundRejected = 'REFUND_REJECTED',
  RefundSucceeded = 'REFUND_SUCCEEDED'
}

export type CardIssuer = {
  __typename?: 'CardIssuer';
  bank?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CatalogueInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  collections?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Category = Node & ObjectWithExtendedTranslatableFields & {
  __typename?: 'Category';
  ancestors?: Maybe<CategoryCountableConnection>;
  backgroundImage?: Maybe<Image>;
  children?: Maybe<CategoryCountableConnection>;
  description?: Maybe<Scalars['String']>;
  descriptionJson?: Maybe<Scalars['JSONString']>;
  icon?: Maybe<Image>;
  id: Scalars['ID'];
  level: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Category>;
  products?: Maybe<ProductCountableConnection>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  translation?: Maybe<CategoryTranslation>;
};


export type CategoryAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CategoryTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type CategoryBulkDelete = {
  __typename?: 'CategoryBulkDelete';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type CategoryCountableConnection = {
  __typename?: 'CategoryCountableConnection';
  edges: Array<CategoryCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CategoryCountableEdge = {
  __typename?: 'CategoryCountableEdge';
  cursor: Scalars['String'];
  node: Category;
};

export type CategoryCreate = {
  __typename?: 'CategoryCreate';
  category?: Maybe<Category>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type CategoryDelete = {
  __typename?: 'CategoryDelete';
  category?: Maybe<Category>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type CategoryFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parent?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
};

export type CategoryInput = {
  backgroundImage?: InputMaybe<Scalars['String']>;
  backgroundImageAlt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionJson?: InputMaybe<Scalars['JSONString']>;
  icon?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  seo?: InputMaybe<SeoInput>;
  slug?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export enum CategorySortField {
  Name = 'NAME',
  ProductCount = 'PRODUCT_COUNT',
  SubcategoryCount = 'SUBCATEGORY_COUNT'
}

export type CategorySortingInput = {
  direction: OrderDirection;
  field: CategorySortField;
};

export type CategoryTranslatableContent = Node & {
  __typename?: 'CategoryTranslatableContent';
  category?: Maybe<Category>;
  description: Scalars['String'];
  descriptionJson: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  translation?: Maybe<CategoryTranslation>;
};


export type CategoryTranslatableContentTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type CategoryTranslate = {
  __typename?: 'CategoryTranslate';
  category?: Maybe<Category>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  translationErrors: Array<TranslationError>;
};

export type CategoryTranslation = Node & {
  __typename?: 'CategoryTranslation';
  description: Scalars['String'];
  descriptionJson: Scalars['String'];
  id: Scalars['ID'];
  language: LanguageDisplay;
  name: Scalars['String'];
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
};

export type CategoryUpdate = {
  __typename?: 'CategoryUpdate';
  category?: Maybe<Category>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type ChatError = {
  __typename?: 'ChatError';
  code: ChatErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum ChatErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED'
}

export type Checkout = Node & {
  __typename?: 'Checkout';
  billingAddress?: Maybe<OrderAddress>;
  created: Scalars['DateTime'];
  discount?: Maybe<Money>;
  discountName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  isShippingRequired: Scalars['Boolean'];
  lastChange: Scalars['DateTime'];
  lines?: Maybe<Array<Maybe<CheckoutLine>>>;
  note: Scalars['String'];
  quantity: Scalars['Int'];
  shippingAddress?: Maybe<OrderAddress>;
  shippingPrice?: Maybe<TaxedMoney>;
  source?: Maybe<Scalars['String']>;
  subtotalPrice?: Maybe<TaxedMoney>;
  token: Scalars['UUID'];
  totalPrice?: Maybe<TaxedMoney>;
  translatedDiscountName?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type CheckoutAddNote = {
  __typename?: 'CheckoutAddNote';
  checkout?: Maybe<Checkout>;
  checkoutErrors: Array<CheckoutError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CheckoutComplete = {
  __typename?: 'CheckoutComplete';
  checkoutErrors: Array<CheckoutError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orders?: Maybe<Array<Maybe<Order>>>;
};

export type CheckoutCountableConnection = {
  __typename?: 'CheckoutCountableConnection';
  edges: Array<CheckoutCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CheckoutCountableEdge = {
  __typename?: 'CheckoutCountableEdge';
  cursor: Scalars['String'];
  node: Checkout;
};

export type CheckoutCreate = {
  __typename?: 'CheckoutCreate';
  checkout?: Maybe<Checkout>;
  checkoutErrors: Array<CheckoutError>;
  created?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CheckoutCreateInput = {
  billingAddress?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']>;
  lines: Array<InputMaybe<CheckoutLineInput>>;
  shippingAddress?: InputMaybe<AddressInput>;
  source?: InputMaybe<Scalars['String']>;
};

export type CheckoutCustomerAttach = {
  __typename?: 'CheckoutCustomerAttach';
  checkout?: Maybe<Checkout>;
  checkoutErrors: Array<CheckoutError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CheckoutDelete = {
  __typename?: 'CheckoutDelete';
  checkout?: Maybe<Checkout>;
  checkoutErrors: Array<CheckoutError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CheckoutEmailUpdate = {
  __typename?: 'CheckoutEmailUpdate';
  checkout?: Maybe<Checkout>;
  checkoutErrors: Array<CheckoutError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CheckoutError = {
  __typename?: 'CheckoutError';
  branch?: Maybe<Scalars['ID']>;
  code: CheckoutErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum CheckoutErrorCode {
  BillingAddressNotSet = 'BILLING_ADDRESS_NOT_SET',
  CheckoutNotFullyPaid = 'CHECKOUT_NOT_FULLY_PAID',
  DiscountNotApplicable = 'DISCOUNT_NOT_APPLICABLE',
  GraphqlError = 'GRAPHQL_ERROR',
  InsufficientStock = 'INSUFFICIENT_STOCK',
  Invalid = 'INVALID',
  InvalidShippingMethod = 'INVALID_SHIPPING_METHOD',
  NotFound = 'NOT_FOUND',
  ProductNotPublished = 'PRODUCT_NOT_PUBLISHED',
  QuantityGreaterThanLimit = 'QUANTITY_GREATER_THAN_LIMIT',
  Required = 'REQUIRED',
  ShippingAddressNotSet = 'SHIPPING_ADDRESS_NOT_SET',
  ShippingMethodNotApplicable = 'SHIPPING_METHOD_NOT_APPLICABLE',
  ShippingMethodNotSet = 'SHIPPING_METHOD_NOT_SET',
  ShippingNotRequired = 'SHIPPING_NOT_REQUIRED',
  TaxError = 'TAX_ERROR',
  Unique = 'UNIQUE',
  ZeroQuantity = 'ZERO_QUANTITY'
}

export type CheckoutLine = Node & {
  __typename?: 'CheckoutLine';
  branch?: Maybe<Branch>;
  children?: Maybe<Array<Maybe<CheckoutLine>>>;
  id: Scalars['ID'];
  isExpired?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Money>;
  quantity: Scalars['Int'];
  requiresShipping?: Maybe<Scalars['Boolean']>;
  totalPrice?: Maybe<TaxedMoney>;
  variant: ProductVariant;
};

export type CheckoutLineCountableConnection = {
  __typename?: 'CheckoutLineCountableConnection';
  edges: Array<CheckoutLineCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CheckoutLineCountableEdge = {
  __typename?: 'CheckoutLineCountableEdge';
  cursor: Scalars['String'];
  node: CheckoutLine;
};

export type CheckoutLineDelete = {
  __typename?: 'CheckoutLineDelete';
  checkout?: Maybe<Checkout>;
  checkoutErrors: Array<CheckoutError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CheckoutLineInput = {
  branch: Scalars['ID'];
  lines?: InputMaybe<Array<InputMaybe<CheckoutLineInput>>>;
  quantity: Scalars['Int'];
  variantId: Scalars['ID'];
};

export type CheckoutLinesAdd = {
  __typename?: 'CheckoutLinesAdd';
  checkout?: Maybe<Checkout>;
  checkoutErrors: Array<CheckoutError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CheckoutLinesUpdate = {
  __typename?: 'CheckoutLinesUpdate';
  checkout?: Maybe<Checkout>;
  checkoutErrors: Array<CheckoutError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CheckoutShippingAddressUpdate = {
  __typename?: 'CheckoutShippingAddressUpdate';
  checkout?: Maybe<Checkout>;
  checkoutErrors: Array<CheckoutError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type City = Node & {
  __typename?: 'City';
  addresses: AddressCountableConnection;
  area?: Maybe<Scalars['String']>;
  blocks: BlockCountableConnection;
  created: Scalars['DateTime'];
  currency: Scalars['String'];
  deliveryPrice?: Maybe<Money>;
  deliveryPriceAmount: Scalars['Float'];
  id: Scalars['ID'];
  maxNumberOfRounds?: Maybe<Scalars['Int']>;
  maxNumberOfTries?: Maybe<Scalars['Int']>;
  maxRadiusForPickup: Scalars['Float'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  nameAr?: Maybe<Scalars['String']>;
  rounds?: Maybe<Array<Maybe<CityRound>>>;
  timeOutPeriod?: Maybe<Scalars['Int']>;
};


export type CityAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CityBlocksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CityCountableConnection = {
  __typename?: 'CityCountableConnection';
  edges: Array<CityCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CityCountableEdge = {
  __typename?: 'CityCountableEdge';
  cursor: Scalars['String'];
  node: City;
};

export type CityCreate = {
  __typename?: 'CityCreate';
  city?: Maybe<City>;
  cityErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CityDelete = {
  __typename?: 'CityDelete';
  city?: Maybe<City>;
  cityErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CityFilterInput = {
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
};

export type CityInput = {
  area?: InputMaybe<Scalars['String']>;
  deliveryPrice?: InputMaybe<Scalars['Float']>;
  maxNumberOfRounds?: InputMaybe<Scalars['Int']>;
  maxNumberOfTries?: InputMaybe<Scalars['Int']>;
  maxRadiusForPickup?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nameAr?: InputMaybe<Scalars['String']>;
  rounds?: InputMaybe<Array<InputMaybe<RoundInput>>>;
  timeOutPeriod?: InputMaybe<Scalars['Int']>;
};

export type CityOrder = {
  direction: OrderDirection;
  field?: InputMaybe<CityOrderField>;
};

export enum CityOrderField {
  Created = 'CREATED',
  Name = 'NAME'
}

export type CityRound = Node & {
  __typename?: 'CityRound';
  city: City;
  id: Scalars['ID'];
  maxNumberOfPharmacies: Scalars['Int'];
  pharmaciesTypes?: Maybe<Array<Maybe<VendorBranchTypeEnum>>>;
  radius: Scalars['Float'];
};

export type CityUpdate = {
  __typename?: 'CityUpdate';
  city?: Maybe<City>;
  cityErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type Clinician = {
  __typename?: 'Clinician';
  email?: Maybe<Scalars['String']>;
  englishName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  licenseEnd?: Maybe<Scalars['Date']>;
  licenseStart?: Maybe<Scalars['Date']>;
  phone?: Maybe<Scalars['String']>;
  tempPassword?: Maybe<Scalars['String']>;
};

export type CodeSystem = {
  __typename?: 'CodeSystem';
  codeSystemConcepts?: Maybe<Array<Maybe<CodeSystemConcept>>>;
  codeSystemDefinition?: Maybe<CodeSystemDefinition>;
  codeSystemHistory?: Maybe<Array<Maybe<CodeSystemHistory>>>;
  display?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  processingStatus?: Maybe<ProcessingStatus>;
  version?: Maybe<Scalars['Int']>;
};

export enum CodeSystemCode {
  Allergy = 'ALLERGY',
  Area = 'AREA',
  Clinician = 'CLINICIAN',
  DenialCode = 'DENIAL_CODE',
  Diagnosis = 'DIAGNOSIS',
  Division = 'DIVISION',
  GenericDrug = 'GENERIC_DRUG',
  GenericDrugExtraData = 'GENERIC_DRUG_EXTRA_DATA',
  HealthProvider = 'HEALTH_PROVIDER',
  MedicalService = 'MEDICAL_SERVICE',
  RejectionReason = 'REJECTION_REASON',
  RouteOfAdmin = 'ROUTE_OF_ADMIN',
  Temperature = 'TEMPERATURE',
  TradeDrug = 'TRADE_DRUG',
  TradeDrugExtraData = 'TRADE_DRUG_EXTRA_DATA',
  UnitId = 'UNIT_ID'
}

export type CodeSystemConcept = {
  __typename?: 'CodeSystemConcept';
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars['ID']>;
  properties?: Maybe<Array<Maybe<CodeSystemConceptProperty>>>;
};

export type CodeSystemConceptChange = {
  __typename?: 'CodeSystemConceptChange';
  newCodeSystemConcept?: Maybe<CodeSystemConcept>;
  oldCodeSystemConcept?: Maybe<CodeSystemConcept>;
};

export type CodeSystemConceptConnection = {
  __typename?: 'CodeSystemConceptConnection';
  edges?: Maybe<Array<Maybe<CodeSystemConceptEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CodeSystemConceptEdge = {
  __typename?: 'CodeSystemConceptEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CodeSystemConcept>;
};

export type CodeSystemConceptFieldDefinition = {
  __typename?: 'CodeSystemConceptFieldDefinition';
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  fieldType?: Maybe<Type>;
  id?: Maybe<Scalars['ID']>;
  isEditable: Scalars['Boolean'];
  isMandatory?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  toolTip?: Maybe<Scalars['String']>;
};

export type CodeSystemConceptFieldDefinitionInput = {
  code: Scalars['String'];
  codeSystemDefinition: CodeSystemDefinitionInput;
  display: Scalars['String'];
  fieldType: Type;
  id?: InputMaybe<Scalars['ID']>;
  isMandatory: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  toolTip?: InputMaybe<Scalars['String']>;
};

export type CodeSystemConceptInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  properties?: InputMaybe<Array<InputMaybe<CodeSystemConceptPropertyInput>>>;
};

export type CodeSystemConceptProperty = {
  __typename?: 'CodeSystemConceptProperty';
  code?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  fieldType?: Maybe<Type>;
  id?: Maybe<Scalars['ID']>;
  valueBoolean?: Maybe<Scalars['Boolean']>;
  valueDate?: Maybe<Scalars['Date']>;
  valueFloat?: Maybe<Scalars['Float']>;
  valueInteger?: Maybe<Scalars['Int']>;
  valueString?: Maybe<Scalars['String']>;
};

export type CodeSystemConceptPropertyInput = {
  code?: InputMaybe<Scalars['String']>;
  fieldType?: InputMaybe<Type>;
  id?: InputMaybe<Scalars['ID']>;
  valueBoolean?: InputMaybe<Scalars['Boolean']>;
  valueDate?: InputMaybe<Scalars['Date']>;
  valueFloat?: InputMaybe<Scalars['Float']>;
  valueInteger?: InputMaybe<Scalars['Int']>;
  valueString?: InputMaybe<Scalars['String']>;
};

export type CodeSystemConnection = {
  __typename?: 'CodeSystemConnection';
  edges?: Maybe<Array<Maybe<CodeSystemEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CodeSystemDefinition = {
  __typename?: 'CodeSystemDefinition';
  code?: Maybe<CodeSystemCode>;
  codeSystemConceptFields?: Maybe<Array<Maybe<CodeSystemConceptFieldDefinition>>>;
  display?: Maybe<Scalars['String']>;
  editorUserId?: Maybe<User>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars['ID']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  reviewerUserId?: Maybe<User>;
};

export type CodeSystemDefinitionInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CodeSystemEdge = {
  __typename?: 'CodeSystemEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CodeSystem>;
};

export type CodeSystemEvent = {
  __typename?: 'CodeSystemEvent';
  codeSystemCode?: Maybe<CodeSystemCode>;
  id?: Maybe<Scalars['ID']>;
  processingStatus?: Maybe<ProcessingStatus>;
};

export type CodeSystemHistory = {
  __typename?: 'CodeSystemHistory';
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars['ID']>;
  processingStatus?: Maybe<ProcessingStatus>;
  updateDate?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type CodeSystemSummery = {
  __typename?: 'CodeSystemSummery';
  activeCodeSystem?: Maybe<CodeSystem>;
  codeSystemDefinition?: Maybe<CodeSystemDefinition>;
  iconUrl?: Maybe<Scalars['String']>;
  lastCodeSystem?: Maybe<CodeSystem>;
};

export type Coding = {
  __typename?: 'Coding';
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
};

export type Collection = Node & ObjectWithExtendedTranslatableFields & {
  __typename?: 'Collection';
  backgroundImage?: Maybe<Image>;
  description?: Maybe<Scalars['String']>;
  descriptionJson?: Maybe<Scalars['JSONString']>;
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductCountableConnection>;
  publicationDate?: Maybe<Scalars['Date']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  translation?: Maybe<CollectionTranslation>;
};


export type CollectionProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CollectionTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type CollectionAddProducts = {
  __typename?: 'CollectionAddProducts';
  collection?: Maybe<Collection>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type CollectionBulkDelete = {
  __typename?: 'CollectionBulkDelete';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type CollectionBulkPublish = {
  __typename?: 'CollectionBulkPublish';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type CollectionCountableConnection = {
  __typename?: 'CollectionCountableConnection';
  edges: Array<CollectionCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CollectionCountableEdge = {
  __typename?: 'CollectionCountableEdge';
  cursor: Scalars['String'];
  node: Collection;
};

export type CollectionCreate = {
  __typename?: 'CollectionCreate';
  collection?: Maybe<Collection>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type CollectionCreateInput = {
  backgroundImage?: InputMaybe<Scalars['String']>;
  backgroundImageAlt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionJson?: InputMaybe<Scalars['JSONString']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  publicationDate?: InputMaybe<Scalars['Date']>;
  seo?: InputMaybe<SeoInput>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CollectionDelete = {
  __typename?: 'CollectionDelete';
  collection?: Maybe<Collection>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type CollectionFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  published?: InputMaybe<CollectionPublished>;
  search?: InputMaybe<Scalars['String']>;
};

export type CollectionInput = {
  backgroundImage?: InputMaybe<Scalars['String']>;
  backgroundImageAlt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionJson?: InputMaybe<Scalars['JSONString']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  publicationDate?: InputMaybe<Scalars['Date']>;
  seo?: InputMaybe<SeoInput>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum CollectionPublished {
  Hidden = 'HIDDEN',
  Published = 'PUBLISHED'
}

export type CollectionRemoveProducts = {
  __typename?: 'CollectionRemoveProducts';
  collection?: Maybe<Collection>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type CollectionReorderProducts = {
  __typename?: 'CollectionReorderProducts';
  collection?: Maybe<Collection>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export enum CollectionSortField {
  Availability = 'AVAILABILITY',
  Name = 'NAME',
  ProductCount = 'PRODUCT_COUNT'
}

export type CollectionSortingInput = {
  direction: OrderDirection;
  field: CollectionSortField;
};

export type CollectionTranslatableContent = Node & {
  __typename?: 'CollectionTranslatableContent';
  collection?: Maybe<Collection>;
  description: Scalars['String'];
  descriptionJson: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  translation?: Maybe<CollectionTranslation>;
};


export type CollectionTranslatableContentTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type CollectionTranslate = {
  __typename?: 'CollectionTranslate';
  collection?: Maybe<Collection>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  translationErrors: Array<TranslationError>;
};

export type CollectionTranslation = Node & {
  __typename?: 'CollectionTranslation';
  description: Scalars['String'];
  descriptionJson: Scalars['String'];
  id: Scalars['ID'];
  language: LanguageDisplay;
  name: Scalars['String'];
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
};

export type CollectionUpdate = {
  __typename?: 'CollectionUpdate';
  collection?: Maybe<Collection>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type Condition = {
  __typename?: 'Condition';
  conditionOperation?: Maybe<ConditionOperation>;
  conditions?: Maybe<Array<Maybe<Condition>>>;
  entityType?: Maybe<EntityType>;
  field?: Maybe<Field>;
  id?: Maybe<Scalars['ID']>;
  isGroup?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<Operator>;
  value?: Maybe<ConditionValue>;
};

export type ConditionInput = {
  conditionOperation?: InputMaybe<ConditionOperation>;
  conditions?: InputMaybe<Array<InputMaybe<ConditionInput>>>;
  entityType?: InputMaybe<EntityType>;
  field?: InputMaybe<FieldInput>;
  id?: InputMaybe<Scalars['ID']>;
  isGroup: Scalars['Boolean'];
  operator?: InputMaybe<Operator>;
  value?: InputMaybe<ConditionValueInput>;
};

export enum ConditionOperation {
  And = 'AND',
  Or = 'OR'
}

export type ConditionValue = {
  __typename?: 'ConditionValue';
  valueDate?: Maybe<Scalars['Date']>;
  valueID?: Maybe<Scalars['String']>;
  valueList?: Maybe<Scalars['ID']>;
  valueNumber?: Maybe<Scalars['Float']>;
  valueString?: Maybe<Scalars['String']>;
};

export type ConditionValueInput = {
  valueDate?: InputMaybe<Scalars['Date']>;
  valueID?: InputMaybe<Scalars['String']>;
  valueList?: InputMaybe<Scalars['ID']>;
  valueNumber?: InputMaybe<Scalars['Float']>;
  valueString?: InputMaybe<Scalars['String']>;
};

export type ConfigurationItem = {
  __typename?: 'ConfigurationItem';
  helpText?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type?: Maybe<ConfigurationTypeFieldEnum>;
  value?: Maybe<Scalars['String']>;
};

export type ConfigurationItemInput = {
  name: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export enum ConfigurationTypeFieldEnum {
  Boolean = 'BOOLEAN',
  Integer = 'INTEGER',
  Password = 'PASSWORD',
  Secret = 'SECRET',
  String = 'STRING'
}

export type ConfirmAccountEmail = {
  __typename?: 'ConfirmAccountEmail';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ConfirmAccountMobile = {
  __typename?: 'ConfirmAccountMobile';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ConfirmPasswordRestOtp = {
  __typename?: 'ConfirmPasswordRestOTP';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type ConsumerPrescriptionDispense = {
  __typename?: 'ConsumerPrescriptionDispense';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type ConsumerPrescriptionDispenseInput = {
  deliveryDate?: InputMaybe<Scalars['Date']>;
  deliveryTimeSlot?: InputMaybe<Scalars['ID']>;
  isDeliveryRequested: Scalars['Boolean'];
  nationalId?: InputMaybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  shippingAddress: AddressInput;
};

export type ConsumerViewPreference = Node & {
  __typename?: 'ConsumerViewPreference';
  data?: Maybe<Scalars['JSONString']>;
  id: Scalars['ID'];
  user: User;
  view: Scalars['String'];
};

export type ConsumerViewPreferenceError = {
  __typename?: 'ConsumerViewPreferenceError';
  code: ConsumerViewPreferenceErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum ConsumerViewPreferenceErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type ConsumerViewPreferenceInput = {
  data?: InputMaybe<Scalars['JSONString']>;
  view?: InputMaybe<Scalars['String']>;
};

export type ConsumerViewPreferenceSave = {
  __typename?: 'ConsumerViewPreferenceSave';
  consumerViewPreference?: Maybe<ConsumerViewPreference>;
  consumerViewPreferenceErrors: Array<ConsumerViewPreferenceError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type ConsumerWishlistDelete = {
  __typename?: 'ConsumerWishlistDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  wishlist?: Maybe<Wishlist>;
  wishlistErrors: Array<WishlistError>;
};

export type ConvertVirtualOrderToRealOne = {
  __typename?: 'ConvertVirtualOrderToRealOne';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderErrors: Array<OrderError>;
  orders?: Maybe<Array<Maybe<Order>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CountryDisplay = {
  __typename?: 'CountryDisplay';
  code: Scalars['String'];
  country: Scalars['String'];
};

export enum Currency {
  Aed = 'AED',
  Eur = 'EUR',
  Sar = 'SAR',
  Usd = 'USD'
}

export type CustomerAcceptDraftOrder = {
  __typename?: 'CustomerAcceptDraftOrder';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type CustomerDashboardFilterInput = {
  dateJoined?: InputMaybe<DateRangeInput>;
  joinedPeriod?: InputMaybe<ReportingPeriod>;
};

export type CustomerEvent = Node & {
  __typename?: 'CustomerEvent';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  type?: Maybe<CustomerEventsEnum>;
  user?: Maybe<User>;
};

export enum CustomerEventsEnum {
  AccountCreated = 'ACCOUNT_CREATED',
  AccountCreatedFromSso = 'ACCOUNT_CREATED_FROM_SSO',
  CustomerDeleted = 'CUSTOMER_DELETED',
  DigitalLinkDownloaded = 'DIGITAL_LINK_DOWNLOADED',
  EmailAssigned = 'EMAIL_ASSIGNED',
  EmailChanged = 'EMAIL_CHANGED',
  EmailChangedRequest = 'EMAIL_CHANGED_REQUEST',
  NameAssigned = 'NAME_ASSIGNED',
  NoteAdded = 'NOTE_ADDED',
  NoteAddedToOrder = 'NOTE_ADDED_TO_ORDER',
  PasswordChanged = 'PASSWORD_CHANGED',
  PasswordReset = 'PASSWORD_RESET',
  PasswordResetLinkSent = 'PASSWORD_RESET_LINK_SENT',
  PlacedOrder = 'PLACED_ORDER'
}

export type CustomerFavoriteVendor = Node & {
  __typename?: 'CustomerFavoriteVendor';
  customer: User;
  id: Scalars['ID'];
  vendor: Vendor;
};

export type CustomerFavoriteVendorCountableConnection = {
  __typename?: 'CustomerFavoriteVendorCountableConnection';
  edges: Array<CustomerFavoriteVendorCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CustomerFavoriteVendorCountableEdge = {
  __typename?: 'CustomerFavoriteVendorCountableEdge';
  cursor: Scalars['String'];
  node: CustomerFavoriteVendor;
};

export type CustomerFilterInput = {
  dateJoined?: InputMaybe<DateRangeInput>;
  joinedPeriod?: InputMaybe<ReportingPeriod>;
  nationalId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
};

export type CustomerMarkMessagesAsSeen = {
  __typename?: 'CustomerMarkMessagesAsSeen';
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  updatedCount?: Maybe<Scalars['Int']>;
};

export type CustomerSendMessage = {
  __typename?: 'CustomerSendMessage';
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  message?: Maybe<Message>;
};

export type DateRangeInput = {
  gte?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
};

export type DateTimeRangeInput = {
  gte?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
};

export enum DayOfWeekEnum {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED'
}

export type DecimalRangeInput = {
  gte?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
};

export type Decision = {
  __typename?: 'Decision';
  actionFields?: Maybe<Array<Maybe<ActionField>>>;
  conditions?: Maybe<Array<Maybe<Condition>>>;
  decisionPlan?: Maybe<DecisionPlan>;
  drl?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isScript?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Priority>;
};

export type DecisionConnection = {
  __typename?: 'DecisionConnection';
  edges?: Maybe<Array<Maybe<DecisionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DecisionEdge = {
  __typename?: 'DecisionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Decision>;
};

export type DecisionInput = {
  actionFields?: InputMaybe<Array<InputMaybe<ActionFieldInput>>>;
  conditions: Array<InputMaybe<ConditionInput>>;
  decisionPlan: DecisionPlanInput;
  drl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  isScript: Scalars['Boolean'];
  name: Scalars['String'];
  priority: Priority;
};

export type DecisionInputData = {
  code: Scalars['String'];
  type: EntityType;
  valueDate?: InputMaybe<Scalars['Date']>;
  valueID?: InputMaybe<Scalars['String']>;
  valueList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  valueNumber?: InputMaybe<Scalars['Float']>;
  valueString?: InputMaybe<Scalars['String']>;
};

export enum DecisionMakerErrorCodes {
  DecisionFileNotFound = 'DECISION_FILE_NOT_FOUND',
  DecisionFileServiceNotReachable = 'DECISION_FILE_SERVICE_NOT_REACHABLE',
  DecisionInvalidFileExtension = 'DECISION_INVALID_FILE_EXTENSION',
  DecisionMakerDuplicateListItem = 'DECISION_MAKER_DUPLICATE_LIST_ITEM',
  DecisionMakerDuplicateListName = 'DECISION_MAKER_DUPLICATE_LIST_NAME',
  DecisionMakerDuplicateRuleName = 'DECISION_MAKER_DUPLICATE_RULE_NAME',
  DecisionMakerEntityNotFound = 'DECISION_MAKER_ENTITY_NOT_FOUND',
  DecisionMakerFieldMandatory = 'DECISION_MAKER_FIELD_MANDATORY',
  DecisionMakerFieldSize = 'DECISION_MAKER_FIELD_SIZE',
  DecisionMakerInvalidOperator = 'DECISION_MAKER_INVALID_OPERATOR',
  DecisionMakerInvalidRule = 'DECISION_MAKER_INVALID_RULE',
  DecisionMakerNoneNegativePageSize = 'DECISION_MAKER_NONE_NEGATIVE_PAGE_SIZE',
  DecisionMakerSubListNotFound = 'DECISION_MAKER_SUB_LIST_NOT_FOUND',
  DecisionMakerUnknown = 'DECISION_MAKER_UNKNOWN',
  DecisionMakerUnknownType = 'DECISION_MAKER_UNKNOWN_TYPE',
  DecisionPermissionDenied = 'DECISION_PERMISSION_DENIED',
  InvalidOrExpiredToken = 'INVALID_OR_EXPIRED_TOKEN'
}

export type DecisionMakerGraphqlError = {
  __typename?: 'DecisionMakerGraphqlError';
  code?: Maybe<DecisionMakerErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum DecisionMakerOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DecisionPlan = {
  __typename?: 'DecisionPlan';
  actionDefinitionFields?: Maybe<Array<Maybe<ActionDefinitionField>>>;
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  field?: Maybe<Array<Maybe<Field>>>;
  id?: Maybe<Scalars['ID']>;
};

export type DecisionPlanInput = {
  id: Scalars['ID'];
};

export enum DecisionSortField {
  DecisionPlan = 'DECISION_PLAN',
  IsActive = 'IS_ACTIVE',
  Name = 'NAME',
  Type = 'TYPE'
}

export type DecisionSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: DecisionSortField;
};

export type DeliveryError = {
  __typename?: 'DeliveryError';
  code: DeliveryErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum DeliveryErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export enum DeliveryInitiatorType {
  MedicalDeliveryRequest = 'MEDICAL_DELIVERY_REQUEST',
  Order = 'ORDER'
}

export type DeliveryNotification = {
  __typename?: 'DeliveryNotification';
  aggregator?: Maybe<Scalars['String']>;
  aggregatorOrderID?: Maybe<Scalars['H_Long']>;
  allocateOrderId?: Maybe<Scalars['String']>;
  awb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Instant']>;
  deliveryInitiatorType?: Maybe<DeliveryInitiatorType>;
  dispenseOrderId?: Maybe<Scalars['H_Long']>;
  distanceGroup?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderReferenceNumber?: Maybe<Scalars['String']>;
  totalTripDistanceInKilometers?: Maybe<Scalars['Double']>;
  type?: Maybe<Scalars['String']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type DeliveryShipmentCalculateShipmentPriceRequest = {
  aggregator?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<DeliveryShipmentDeliveryType>;
  dropoffContact?: InputMaybe<DeliveryShipmentContactLocation>;
  packageHandlingInstructions?: InputMaybe<DeliveryShipmentPackageHandlingInstructions>;
  pickupLocation?: InputMaybe<Array<InputMaybe<DeliveryShipmentContactLocation>>>;
};

export type DeliveryShipmentCalculateShipmentPriceResponse = {
  __typename?: 'DeliveryShipmentCalculateShipmentPriceResponse';
  tripCost?: Maybe<Scalars['Float']>;
  tripPrice?: Maybe<Scalars['Float']>;
};

export type DeliveryShipmentCalculateShipmentPriceResponseResult = {
  __typename?: 'DeliveryShipmentCalculateShipmentPriceResponseResult';
  code?: Maybe<Scalars['Int']>;
  data?: Maybe<DeliveryShipmentCalculateShipmentPriceResponse>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  request?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
};

export type DeliveryShipmentCar = {
  __typename?: 'DeliveryShipmentCar';
  licenseNo?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
};

export type DeliveryShipmentCarrierCompany = {
  __typename?: 'DeliveryShipmentCarrierCompany';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  licenseNo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type DeliveryShipmentContact = {
  identity?: InputMaybe<DeliveryShipmentContactIdentity>;
  location?: InputMaybe<DeliveryShipmentContactLocation>;
};

export type DeliveryShipmentContactIdentity = {
  contactType?: InputMaybe<DeliveryShipmentContactType>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type DeliveryShipmentContactLocation = {
  city?: InputMaybe<Scalars['String']>;
  cityArea?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<DeliveryShipmentCoordinates>;
};

export enum DeliveryShipmentContactType {
  Person = 'Person',
  Pharmacy = 'Pharmacy'
}

export type DeliveryShipmentCoordinates = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type DeliveryShipmentCreateShipmentRequest = {
  aggregator?: InputMaybe<Scalars['String']>;
  cod?: InputMaybe<Scalars['Int']>;
  deliveryType?: InputMaybe<DeliveryShipmentDeliveryType>;
  dropoffContact?: InputMaybe<DeliveryShipmentContact>;
  packageHandlingInstructions?: InputMaybe<DeliveryShipmentPackageHandlingInstructions>;
  pharmacyDriverAssignmentFlag?: InputMaybe<Scalars['Boolean']>;
  pickupContact?: InputMaybe<Array<InputMaybe<DeliveryShipmentPickupContact>>>;
  workflowId?: InputMaybe<Scalars['String']>;
};

export type DeliveryShipmentCreateShipmentResponse = {
  __typename?: 'DeliveryShipmentCreateShipmentResponse';
  aggregatorOrderID?: Maybe<Scalars['Int']>;
  awb?: Maybe<Scalars['String']>;
  car?: Maybe<DeliveryShipmentCar>;
  carrierCompany?: Maybe<DeliveryShipmentCarrierCompany>;
  createdAtDate?: Maybe<Scalars['String']>;
  driver?: Maybe<DeliveryShipmentDriver>;
  expectedDropoffDate?: Maybe<Scalars['String']>;
  expectedPickupDate?: Maybe<Scalars['String']>;
  scheduleDelivery?: Maybe<Scalars['String']>;
  tripCost?: Maybe<Scalars['Float']>;
  tripPrice?: Maybe<Scalars['Float']>;
};

export type DeliveryShipmentCreateShipmentResponseResult = {
  __typename?: 'DeliveryShipmentCreateShipmentResponseResult';
  code?: Maybe<Scalars['Int']>;
  data?: Maybe<DeliveryShipmentCreateShipmentResponse>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  request?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
};

export enum DeliveryShipmentDeliveryType {
  Ed = 'ED',
  Nd = 'ND',
  Sd = 'SD'
}

export type DeliveryShipmentDriver = {
  __typename?: 'DeliveryShipmentDriver';
  licenseNo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type DeliveryShipmentGetShipmentResponse = {
  __typename?: 'DeliveryShipmentGetShipmentResponse';
  aggregator?: Maybe<Scalars['String']>;
  awb?: Maybe<Scalars['String']>;
  car?: Maybe<DeliveryShipmentCar>;
  carrierCompany?: Maybe<DeliveryShipmentCarrierCompany>;
  claimed?: Maybe<Scalars['Boolean']>;
  createdAtDate?: Maybe<Scalars['String']>;
  currentAuthorizationId?: Maybe<Scalars['String']>;
  deliveryFollowupWorkflowId?: Maybe<Scalars['String']>;
  deliveryWorkflowId?: Maybe<Scalars['String']>;
  dispenseOrderIdAccumulated?: Maybe<Scalars['String']>;
  dispenseOrderIdSeparator?: Maybe<Scalars['String']>;
  distanceGroup?: Maybe<Scalars['String']>;
  driver?: Maybe<DeliveryShipmentDriver>;
  expectedDropoffDate?: Maybe<Scalars['String']>;
  expectedPickupDate?: Maybe<Scalars['String']>;
  locationHistory?: Maybe<Array<Maybe<DeliveryShipmentLocationHistoryRecord>>>;
  orderReferenceNumber?: Maybe<Scalars['String']>;
  scheduleDelivery?: Maybe<Scalars['String']>;
  totalTripDistanceInKilometers?: Maybe<Scalars['Float']>;
  tripCost?: Maybe<Scalars['Float']>;
  tripPrice?: Maybe<Scalars['Float']>;
};

export type DeliveryShipmentGetShipmentResponseResult = {
  __typename?: 'DeliveryShipmentGetShipmentResponseResult';
  code?: Maybe<Scalars['Int']>;
  data?: Maybe<DeliveryShipmentGetShipmentResponse>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DeliveryShipmentLocationHistoryRecord = {
  __typename?: 'DeliveryShipmentLocationHistoryRecord';
  awb?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type DeliveryShipmentNotificationRequest = {
  awb?: InputMaybe<Scalars['String']>;
  distanceGroup?: InputMaybe<Scalars['String']>;
  orderReferenceNumber?: InputMaybe<Scalars['String']>;
  totalTripDistanceInKilometers?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  workflowId?: InputMaybe<Scalars['String']>;
};

export type DeliveryShipmentNotificationResponse = {
  __typename?: 'DeliveryShipmentNotificationResponse';
  aggregator?: Maybe<Scalars['String']>;
  aggregatorOrderID?: Maybe<Scalars['Int']>;
  allocateOrderId?: Maybe<Scalars['String']>;
  awb?: Maybe<Scalars['String']>;
  currentAuthorizationId?: Maybe<Scalars['String']>;
  deliveryFollowupWorkflowId?: Maybe<Scalars['String']>;
  deliveryInitiatorType?: Maybe<DeliveryInitiatorType>;
  deliveryWorkflowId?: Maybe<Scalars['String']>;
  distanceGroup?: Maybe<Scalars['String']>;
  orderReferenceNumber?: Maybe<Scalars['String']>;
  totalTripDistanceInKilometers?: Maybe<Scalars['Float']>;
};

export type DeliveryShipmentNotificationResponseResult = {
  __typename?: 'DeliveryShipmentNotificationResponseResult';
  code?: Maybe<Scalars['Int']>;
  data?: Maybe<DeliveryShipmentNotificationResponse>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DeliveryShipmentPackageHandlingInstructions = {
  requiredDeliveryTimeInMinutes?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['String']>;
};

export type DeliveryShipmentPickupContact = {
  currentAuthorizationId?: InputMaybe<Scalars['String']>;
  identity?: InputMaybe<DeliveryShipmentContactIdentity>;
  location?: InputMaybe<DeliveryShipmentContactLocation>;
  orderReferenceNumber?: InputMaybe<Scalars['String']>;
  pkgCost?: InputMaybe<Scalars['Float']>;
};

export type DeliveryShipmentSendShipmentNotificationReminderResponseResult = {
  __typename?: 'DeliveryShipmentSendShipmentNotificationReminderResponseResult';
  code?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  response?: Maybe<Scalars['String']>;
};

export type DeliverySortingInput = {
  direction: OrderDirection;
  field: DeliveryTimeSlotSortField;
};

export type DeliveryTimeSlot = Node & {
  __typename?: 'DeliveryTimeSlot';
  deliveryType: DeliveryTimeSlotDeliveryType;
  endTime: Scalars['Time'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  orders: OrderCountableConnection;
  startTime: Scalars['Time'];
};


export type DeliveryTimeSlotOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeliveryTimeSlotCountableConnection = {
  __typename?: 'DeliveryTimeSlotCountableConnection';
  edges: Array<DeliveryTimeSlotCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DeliveryTimeSlotCountableEdge = {
  __typename?: 'DeliveryTimeSlotCountableEdge';
  cursor: Scalars['String'];
  node: DeliveryTimeSlot;
};

export type DeliveryTimeSlotCreate = {
  __typename?: 'DeliveryTimeSlotCreate';
  deliveryTimeSlot?: Maybe<DeliveryTimeSlot>;
  deliveryTimeSlotsErrors: Array<DeliveryTimeSlotError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DeliveryTimeSlotCreateInput = {
  endTime: Scalars['Time'];
  isActive: Scalars['Boolean'];
  startTime: Scalars['Time'];
};

export enum DeliveryTimeSlotDeliveryType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export type DeliveryTimeSlotError = {
  __typename?: 'DeliveryTimeSlotError';
  code: DeliveryTimeSlotErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum DeliveryTimeSlotErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type DeliveryTimeSlotFilterInput = {
  deliveryType?: InputMaybe<DeliveryTypesEnum>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export enum DeliveryTimeSlotSortField {
  EndTime = 'END_TIME',
  IsActive = 'IS_ACTIVE',
  StartTime = 'START_TIME'
}

export type DeliveryTimeSlotUpdateStatus = {
  __typename?: 'DeliveryTimeSlotUpdateStatus';
  deliveryTimeSlot?: Maybe<DeliveryTimeSlot>;
  deliveryTimeSlotsErrors: Array<DeliveryTimeSlotError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export enum DeliveryType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export enum DeliveryTypes {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export enum DeliveryTypesEnum {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export type DenialCode = {
  __typename?: 'DenialCode';
  code?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
};

export type Diagnose = Node & {
  __typename?: 'Diagnose';
  code: Scalars['String'];
  created: Scalars['DateTime'];
  display?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  prescription: Prescription;
  type: Scalars['String'];
};

export type DiagnoseFilterInput = {
  code?: InputMaybe<Scalars['String']>;
};

export type DiscountError = {
  __typename?: 'DiscountError';
  code: DiscountErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum DiscountErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export enum DiscountStatusEnum {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Scheduled = 'SCHEDULED'
}

export enum DiscountValueTypeEnum {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE'
}

export type DispenseTrack = {
  __typename?: 'DispenseTrack';
  activityDescription?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  dispenseDate?: Maybe<Scalars['String']>;
  dispensedQuantity?: Maybe<Scalars['Float']>;
  refillNumber?: Maybe<Scalars['Float']>;
  sfdaCode?: Maybe<Scalars['String']>;
};

export type DistanceFilterInput = {
  coordinates: LocationInput;
  distance?: InputMaybe<Scalars['Decimal']>;
};

export type Division = Node & {
  __typename?: 'Division';
  created: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  vendorSet: VendorCountableConnection;
};


export type DivisionVendorSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DivisionConnection = {
  __typename?: 'DivisionConnection';
  edges: Array<Maybe<DivisionEdge>>;
  pageInfo: PageInfo;
};

export type DivisionEdge = {
  __typename?: 'DivisionEdge';
  cursor: Scalars['String'];
  node?: Maybe<Division>;
};

export type DraftOrderAcceptInput = {
  shippingAddress: AddressInput;
};

export type DraftOrderCreate = {
  __typename?: 'DraftOrderCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type DraftOrderCreateInput = {
  branchId: Scalars['ID'];
  customerNote: Scalars['String'];
  discount?: InputMaybe<Scalars['Decimal']>;
  total: Scalars['Decimal'];
  user: Scalars['ID'];
  userEmail?: InputMaybe<Scalars['String']>;
};

export type EarlyRefillReason = Node & {
  __typename?: 'EarlyRefillReason';
  created: Scalars['DateTime'];
  earlyRefillRequests: EarlyRefillRequestCountableConnection;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  reason: Scalars['String'];
  type: EarlyRefillReasonType;
};


export type EarlyRefillReasonEarlyRefillRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type EarlyRefillReasonCreate = {
  __typename?: 'EarlyRefillReasonCreate';
  earlyRefillReason?: Maybe<EarlyRefillReason>;
  earlyRefillReasonErrors: Array<EarlyRefillReasonError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type EarlyRefillReasonDelete = {
  __typename?: 'EarlyRefillReasonDelete';
  earlyRefillReason?: Maybe<EarlyRefillReason>;
  earlyRefillReasonErrors: Array<EarlyRefillReasonError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type EarlyRefillReasonError = {
  __typename?: 'EarlyRefillReasonError';
  code: EarlyRefillReasonErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum EarlyRefillReasonErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type EarlyRefillReasonInput = {
  reason: Scalars['String'];
};

export enum EarlyRefillReasonType {
  Custom = 'CUSTOM',
  System = 'SYSTEM'
}

export type EarlyRefillReasonUpdate = {
  __typename?: 'EarlyRefillReasonUpdate';
  earlyRefillReason?: Maybe<EarlyRefillReason>;
  earlyRefillReasonErrors: Array<EarlyRefillReasonError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type EarlyRefillRequest = Node & {
  __typename?: 'EarlyRefillRequest';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  prescription: Prescription;
  reason: EarlyRefillReason;
  status: EarlyRefillRequestStatus;
};

export type EarlyRefillRequestCountableConnection = {
  __typename?: 'EarlyRefillRequestCountableConnection';
  edges: Array<EarlyRefillRequestCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type EarlyRefillRequestCountableEdge = {
  __typename?: 'EarlyRefillRequestCountableEdge';
  cursor: Scalars['String'];
  node: EarlyRefillRequest;
};

export type EarlyRefillRequestCreate = {
  __typename?: 'EarlyRefillRequestCreate';
  earlyRefillRequest?: Maybe<EarlyRefillRequest>;
  earlyRefillRequestErrors: Array<RefillError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type EarlyRefillRequestFilterInput = {
  status?: InputMaybe<Scalars['String']>;
};

export type EarlyRefillRequestInput = {
  customEarlyRefillReason?: InputMaybe<Scalars['String']>;
  earlyRefillReason?: InputMaybe<Scalars['ID']>;
  prescription: Scalars['ID'];
};

export enum EarlyRefillRequestStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum EntityType {
  Date = 'DATE',
  Id = 'ID',
  List = 'LIST',
  Number = 'NUMBER',
  String = 'STRING'
}

export type Error = {
  __typename?: 'Error';
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN'
}

export type Field = {
  __typename?: 'Field';
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  fieldType?: Maybe<FieldType>;
  id?: Maybe<Scalars['ID']>;
  operators?: Maybe<Array<Maybe<FieldOperator>>>;
};

export type FieldInput = {
  id: Scalars['ID'];
};

export type FieldOperator = {
  __typename?: 'FieldOperator';
  id?: Maybe<Scalars['ID']>;
  operator?: Maybe<Operator>;
};

export type FieldType = {
  __typename?: 'FieldType';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<EntityType>;
};

export type FieldTypeInput = {
  type: EntityType;
};

export type FreeDeliveryEligibilityCheckInput = {
  patientIdentifier: Scalars['String'];
  shippingAddress: AddressInput;
};

export type Frequency = {
  __typename?: 'Frequency';
  dosageAsNeeded?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  frequencyType?: Maybe<Scalars['String']>;
  frequencyValue?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  repeatPattern?: Maybe<Scalars['Float']>;
  unitPerFrequency?: Maybe<Scalars['Float']>;
};

export type FromGlobalId = {
  __typename?: 'FromGlobalId';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  pk?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type FulfillmentLine = Node & {
  __typename?: 'FulfillmentLine';
  id: Scalars['ID'];
  orderLine?: Maybe<OrderLine>;
  quantity: Scalars['Int'];
};

export type GenericDrugDeliveryEligibility = {
  __typename?: 'GenericDrugDeliveryEligibility';
  deliveryTypes?: Maybe<Array<Maybe<DeliveryType>>>;
  genericDrug?: Maybe<Scalars['String']>;
};

export type GenericDrugProperties = {
  __typename?: 'GenericDrugProperties';
  isHazardous?: Maybe<Scalars['Boolean']>;
  isHighAlert?: Maybe<Scalars['Boolean']>;
  isLASA?: Maybe<Scalars['Boolean']>;
};

export type Group = Node & {
  __typename?: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Permission>>>;
  userCanManage: Scalars['Boolean'];
  users?: Maybe<Array<Maybe<User>>>;
};

export type GroupCountableConnection = {
  __typename?: 'GroupCountableConnection';
  edges: Array<GroupCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type GroupCountableEdge = {
  __typename?: 'GroupCountableEdge';
  cursor: Scalars['String'];
  node: Group;
};

export type H_EntityError = {
  __typename?: 'H_EntityError';
  code: H_EntityErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum H_EntityErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  Mismatch = 'MISMATCH',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type H_Error = {
  __typename?: 'H_Error';
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type H_Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export enum H_OrderDirection {
  /** Specifies an ascending sort order. */
  Asc = 'ASC',
  /** Specifies a descending sort order. */
  Desc = 'DESC'
}

export type H_PageInfo = {
  __typename?: 'H_PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type HandleOrderVendorAcceptTimeout = {
  __typename?: 'HandleOrderVendorAcceptTimeout';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderErrors: Array<OrderError>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Image = {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type IntRangeInput = {
  gte?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
};

export enum IntegrationApiErrorCodes {
  IntegrationApiDuplicateVendor = 'INTEGRATION_API_DUPLICATE_VENDOR',
  IntegrationApiPermissionDenied = 'INTEGRATION_API_PERMISSION_DENIED',
  IntegrationApiUnknown = 'INTEGRATION_API_UNKNOWN',
  IntegrationApiUnknownType = 'INTEGRATION_API_UNKNOWN_TYPE'
}

export enum IntegrationErrorCodes {
  IntegrationBranchNotFound = 'INTEGRATION_BRANCH_NOT_FOUND',
  IntegrationDuplicateBranchCredentials = 'INTEGRATION_DUPLICATE_BRANCH_CREDENTIALS',
  IntegrationPermissionDenied = 'INTEGRATION_PERMISSION_DENIED',
  IntegrationUnknown = 'INTEGRATION_UNKNOWN',
  IntegrationUnknownType = 'INTEGRATION_UNKNOWN_TYPE',
  InvalidOrExpiredToken = 'INVALID_OR_EXPIRED_TOKEN'
}

export type IntegrationGraphqlError = {
  __typename?: 'IntegrationGraphqlError';
  code?: Maybe<IntegrationErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type InvoiceError = {
  __typename?: 'InvoiceError';
  code: InvoiceErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum InvoiceErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED'
}

export type IsPrescriptionEligibleForFreeDelivery = {
  __typename?: 'IsPrescriptionEligibleForFreeDelivery';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  isFreeDeliveryEligible?: Maybe<Scalars['Boolean']>;
  prescriptionErrors: Array<PrescriptionError>;
};

export enum LanguageCodeEnum {
  Ar = 'AR',
  Az = 'AZ',
  Bg = 'BG',
  Bn = 'BN',
  Ca = 'CA',
  Cs = 'CS',
  Da = 'DA',
  De = 'DE',
  El = 'EL',
  En = 'EN',
  Es = 'ES',
  EsCo = 'ES_CO',
  Et = 'ET',
  Fa = 'FA',
  Fi = 'FI',
  Fr = 'FR',
  Hi = 'HI',
  Hu = 'HU',
  Hy = 'HY',
  Id = 'ID',
  Is = 'IS',
  It = 'IT',
  Ja = 'JA',
  Ko = 'KO',
  Lt = 'LT',
  Mn = 'MN',
  Nb = 'NB',
  Nl = 'NL',
  Pl = 'PL',
  Pt = 'PT',
  PtBr = 'PT_BR',
  Ro = 'RO',
  Ru = 'RU',
  Sk = 'SK',
  Sl = 'SL',
  Sq = 'SQ',
  Sr = 'SR',
  Sv = 'SV',
  Sw = 'SW',
  Ta = 'TA',
  Th = 'TH',
  Tr = 'TR',
  Uk = 'UK',
  Vi = 'VI',
  ZhHans = 'ZH_HANS',
  ZhHant = 'ZH_HANT'
}

export type LanguageDisplay = {
  __typename?: 'LanguageDisplay';
  code: LanguageCodeEnum;
  language: Scalars['String'];
};

export type LocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type LocationType = {
  __typename?: 'LocationType';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export enum MaritalStatusesEnum {
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export type MarkNotificationsAsSeen = {
  __typename?: 'MarkNotificationsAsSeen';
  accountErrors: Array<NotificationError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  updatedCount?: Maybe<Scalars['Int']>;
};

export type MarketplaceOrderStatusUpdate = {
  __typename?: 'MarketplaceOrderStatusUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export enum MarketplaceProductOrderStatusEnum {
  Delivered = 'DELIVERED',
  DeliveredTimedOut = 'DELIVERED_TIMED_OUT',
  Failed = 'FAILED',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  OutForDeliveryTimedOut = 'OUT_FOR_DELIVERY_TIMED_OUT',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentOnArrive = 'PAYMENT_ON_ARRIVE',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentTimedOut = 'PAYMENT_TIMED_OUT',
  ReadyForDelivery = 'READY_FOR_DELIVERY',
  Returned = 'RETURNED',
  ReturnedTimedOut = 'RETURNED_TIMED_OUT',
  ReturnInProgress = 'RETURN_IN_PROGRESS',
  VendorResponseTimedOut = 'VENDOR_RESPONSE_TIMED_OUT'
}

export type MedicalDeliveryEvent = Node & {
  __typename?: 'MedicalDeliveryEvent';
  date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  type?: Maybe<OrderEventsEnum>;
  user?: Maybe<User>;
};

export type MedicalDeliveryRequest = Node & {
  __typename?: 'MedicalDeliveryRequest';
  branch?: Maybe<Branch>;
  carrierName?: Maybe<Scalars['String']>;
  cost?: Maybe<Money>;
  created: Scalars['DateTime'];
  deliveryStatus: MedicalDeliveryRequestDeliveryStatus;
  deliveryType: MedicalDeliveryRequestDeliveryType;
  description?: Maybe<Scalars['String']>;
  distanceGroup?: Maybe<Scalars['String']>;
  dropOffAddress: OrderAddress;
  dropOffContactName?: Maybe<Scalars['String']>;
  dropOffContactType: MedicalDeliveryRequestDropOffContactType;
  dropOffPhoneNumber?: Maybe<Scalars['String']>;
  events?: Maybe<Array<Maybe<MedicalDeliveryEvent>>>;
  failReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSupportTicket: Scalars['Boolean'];
  modified: Scalars['DateTime'];
  number?: Maybe<Scalars['String']>;
  requiredDeliveryTime?: Maybe<Scalars['Int']>;
  shipmentInformation?: Maybe<DeliveryShipmentGetShipmentResponseResult>;
  shippingPrice?: Maybe<TaxedMoney>;
  status: MedicalDeliveryRequestStatus;
  temperature?: Maybe<Scalars['String']>;
  totalOrderDistance?: Maybe<Scalars['Float']>;
  weight?: Maybe<Weight>;
};

export type MedicalDeliveryRequestCountableConnection = {
  __typename?: 'MedicalDeliveryRequestCountableConnection';
  edges: Array<MedicalDeliveryRequestCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MedicalDeliveryRequestCountableEdge = {
  __typename?: 'MedicalDeliveryRequestCountableEdge';
  cursor: Scalars['String'];
  node: MedicalDeliveryRequest;
};

export type MedicalDeliveryRequestCreate = {
  __typename?: 'MedicalDeliveryRequestCreate';
  deliveryErrors: Array<DeliveryError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  medicalDeliveryRequest?: Maybe<MedicalDeliveryRequest>;
};

export enum MedicalDeliveryRequestDeliveryStatus {
  CarrierAllocated = 'CARRIER_ALLOCATED',
  DeliveryOrderCreated = 'DELIVERY_ORDER_CREATED',
  DeliveryTimedOut = 'DELIVERY_TIMED_OUT',
  DriverAcceptedOrder = 'DRIVER_ACCEPTED_ORDER',
  DriverIsAboutToArrive = 'DRIVER_IS_ABOUT_TO_ARRIVE',
  DriverPickedUpOrder = 'DRIVER_PICKED_UP_ORDER',
  Failed = 'FAILED',
  New = 'NEW',
  OrderIsCancelled = 'ORDER_IS_CANCELLED',
  OrderIsDelivered = 'ORDER_IS_DELIVERED',
  OrderIsResumed = 'ORDER_IS_RESUMED',
  OrderIsSuspended = 'ORDER_IS_SUSPENDED',
  PatientConfirmed = 'PATIENT_CONFIRMED',
  Pending = 'PENDING',
  PickedByConsumer = 'PICKED_BY_CONSUMER',
  Returned = 'RETURNED'
}

export enum MedicalDeliveryRequestDeliveryType {
  ExpressDelivery = 'EXPRESS_DELIVERY',
  NextDay = 'NEXT_DAY',
  SameDay = 'SAME_DAY'
}

export enum MedicalDeliveryRequestDropOffContactType {
  Person = 'PERSON',
  Pharmacy = 'PHARMACY'
}

export type MedicalDeliveryRequestInput = {
  branchId: Scalars['ID'];
  cost: Scalars['Decimal'];
  description?: InputMaybe<Scalars['String']>;
  dropOffAddressInput: AddressInput;
  dropOffContactName: Scalars['String'];
  dropOffPhoneNumber?: InputMaybe<Scalars['String']>;
  isSupportTicket?: InputMaybe<Scalars['Boolean']>;
  requiredDeliveryTime: Scalars['Int'];
  temperature: Scalars['String'];
  weight: Scalars['WeightScalar'];
};

export enum MedicalDeliveryRequestStatus {
  WorkflowFailure = 'WORKFLOW_FAILURE',
  WorkflowOk = 'WORKFLOW_OK'
}

export type MedicalDeliveryRequestStatusChanged = {
  __typename?: 'MedicalDeliveryRequestStatusChanged';
  order?: Maybe<MedicalDeliveryRequest>;
};

export type MedicalDeliveryRequestStatusUpdate = {
  __typename?: 'MedicalDeliveryRequestStatusUpdate';
  deliveryErrors: Array<DeliveryError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  medicalDeliveryRequest?: Maybe<MedicalDeliveryRequest>;
};

export type MedicalDeliveryRequestStatusUpdateInput = {
  carrierName?: InputMaybe<Scalars['String']>;
  deliveryPrice?: InputMaybe<Scalars['Float']>;
  deliveryStatus: OrderDeliveryStatusEnum;
  distanceGroup?: InputMaybe<Scalars['String']>;
  failReason?: InputMaybe<Scalars['String']>;
  totalOrderDistance?: InputMaybe<Scalars['Float']>;
};

export type MedicalDeliveryRequestsCreate = {
  __typename?: 'MedicalDeliveryRequestsCreate';
  deliveryErrors: Array<DeliveryError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  medicalDeliveryRequest?: Maybe<MedicalDeliveryRequest>;
  medicalDeliveryRequests?: Maybe<Array<MedicalDeliveryRequest>>;
};

export enum MedicalDeliverySortField {
  CreationDate = 'CREATION_DATE',
  ModifiedDate = 'MODIFIED_DATE',
  Number = 'NUMBER'
}

export type MedicalDeliverySortingInput = {
  direction: OrderDirection;
  field: MedicalDeliverySortField;
};

export type MedicalOrderDeliveryFilterInput = {
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created?: InputMaybe<DateRangeInput>;
  deliveryStatus?: InputMaybe<Array<InputMaybe<OrderDeliveryStatusEnum>>>;
  isSupportTicket?: InputMaybe<Scalars['Boolean']>;
  modified?: InputMaybe<DateRangeInput>;
  numbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Medication = Node & {
  __typename?: 'Medication';
  arabicInstructions: Scalars['String'];
  code: Scalars['String'];
  created: Scalars['DateTime'];
  dispenseCount: Scalars['Int'];
  display?: Maybe<Scalars['String']>;
  downloadAction: MedicationDownloadAction;
  downloadActionDate: Scalars['DateTime'];
  duration: Scalars['String'];
  genericDrugProperties?: Maybe<GenericDrugProperties>;
  id: Scalars['ID'];
  instructions: Scalars['String'];
  lastDispenseDate?: Maybe<Scalars['DateTime']>;
  modified: Scalars['DateTime'];
  orderLines: OrderLineCountableConnection;
  prescription: Prescription;
  quantity: Scalars['Float'];
  refills: Scalars['Int'];
  remainingDaysToNextRefill?: Maybe<Scalars['Int']>;
  roaArName?: Maybe<Scalars['String']>;
  roaEnName?: Maybe<Scalars['String']>;
  routOfAdmin: Scalars['String'];
  startDate: Scalars['String'];
  tradeDrugs?: Maybe<Array<Maybe<TradeDrug>>>;
  type: Scalars['String'];
  unitId?: Maybe<Scalars['Int']>;
};


export type MedicationOrderLinesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MedicationAuthorizationStatusInput = {
  rejectionReason?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  tradeCode: Scalars['String'];
};

export enum MedicationDownloadAction {
  Added = 'ADDED',
  Original = 'ORIGINAL',
  Removed = 'REMOVED'
}

export type MedicationFilterInput = {
  canRefill?: InputMaybe<Scalars['Boolean']>;
};

export type MedicationOrderLineInput = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  tradeCode: Scalars['String'];
};

export type MedicationTradeDrugs = {
  __typename?: 'MedicationTradeDrugs';
  code: Scalars['String'];
  display: Scalars['String'];
  tradeDrugs?: Maybe<Array<Maybe<TradeDrug>>>;
};

export type Message = Node & {
  __typename?: 'Message';
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  branch: Branch;
  content?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['Int']>;
  recipient?: Maybe<User>;
  seenDate?: Maybe<Scalars['DateTime']>;
  sender: User;
};

export type MessageAttachmentInput = {
  attachment: Scalars['ID'];
  sortOrder: Scalars['Int'];
};

export type MessageCountableConnection = {
  __typename?: 'MessageCountableConnection';
  edges: Array<MessageCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MessageCountableEdge = {
  __typename?: 'MessageCountableEdge';
  cursor: Scalars['String'];
  node: Message;
};

export type MessageInput = {
  attachments?: InputMaybe<Array<InputMaybe<MessageAttachmentInput>>>;
  branch?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['String']>;
};

export type MessageSent = {
  __typename?: 'MessageSent';
  message?: Maybe<Message>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type MoneyRange = {
  __typename?: 'MoneyRange';
  start?: Maybe<Money>;
  stop?: Maybe<Money>;
};

export type MoveProductInput = {
  productId: Scalars['ID'];
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  accountAddressCreate?: Maybe<AccountAddressCreate>;
  accountAddressDelete?: Maybe<AccountAddressDelete>;
  accountAddressUpdate?: Maybe<AccountAddressUpdate>;
  accountReSendEmailOtpCode?: Maybe<AccountReSendEmailOtpCode>;
  accountReSendOtpCode?: Maybe<AccountReSendOtpCode>;
  accountRegister?: Maybe<SsoAccountRegister>;
  accountSetDefaultAddress?: Maybe<AccountSetDefaultAddress>;
  accountUpdate?: Maybe<SsoAccountUpdate>;
  addDecision?: Maybe<Decision>;
  addOrderToSurvey?: Maybe<Survey>;
  addSubList?: Maybe<SubList>;
  addSubListItem?: Maybe<SubListItem>;
  addVendorToFavorite?: Maybe<AddVendorToFavorite>;
  approveVendor?: Maybe<ApproveVendor>;
  assignCodeSystemEditorAndReviewer?: Maybe<CodeSystemDefinition>;
  assignUserToBranch?: Maybe<AssignUserToBranch>;
  attributeAssign?: Maybe<AttributeAssign>;
  attributeBulkDelete?: Maybe<AttributeBulkDelete>;
  attributeCreate?: Maybe<AttributeCreate>;
  attributeDelete?: Maybe<AttributeDelete>;
  attributeReorderValues?: Maybe<AttributeReorderValues>;
  attributeTranslate?: Maybe<AttributeTranslate>;
  attributeUnassign?: Maybe<AttributeUnassign>;
  attributeUpdate?: Maybe<AttributeUpdate>;
  attributeValueBulkDelete?: Maybe<AttributeValueBulkDelete>;
  attributeValueCreate?: Maybe<AttributeValueCreate>;
  attributeValueDelete?: Maybe<AttributeValueDelete>;
  attributeValueTranslate?: Maybe<AttributeValueTranslate>;
  attributeValueUpdate?: Maybe<AttributeValueUpdate>;
  blockCreate?: Maybe<BlockCreate>;
  blockDelete?: Maybe<BlockDelete>;
  blockUpdate?: Maybe<BlockUpdate>;
  branchCreate?: Maybe<BranchCreate>;
  branchDelete?: Maybe<BranchDelete>;
  branchUpdate?: Maybe<BranchUpdate>;
  calculateShipmentOrderPriceForDeliveryShipment?: Maybe<DeliveryShipmentCalculateShipmentPriceResponseResult>;
  cancelBulkFile?: Maybe<BulkFile>;
  categoryBulkDelete?: Maybe<CategoryBulkDelete>;
  categoryCreate?: Maybe<CategoryCreate>;
  categoryDelete?: Maybe<CategoryDelete>;
  categoryTranslate?: Maybe<CategoryTranslate>;
  categoryUpdate?: Maybe<CategoryUpdate>;
  checkoutAddNote?: Maybe<CheckoutAddNote>;
  checkoutComplete?: Maybe<CheckoutComplete>;
  checkoutCreate?: Maybe<CheckoutCreate>;
  checkoutCustomerAttach?: Maybe<CheckoutCustomerAttach>;
  checkoutDelete?: Maybe<CheckoutDelete>;
  checkoutEmailUpdate?: Maybe<CheckoutEmailUpdate>;
  checkoutLineDelete?: Maybe<CheckoutLineDelete>;
  checkoutLinesAdd?: Maybe<CheckoutLinesAdd>;
  checkoutLinesUpdate?: Maybe<CheckoutLinesUpdate>;
  checkoutShippingAddressUpdate?: Maybe<CheckoutShippingAddressUpdate>;
  cityCreate?: Maybe<CityCreate>;
  cityDelete?: Maybe<CityDelete>;
  cityUpdate?: Maybe<CityUpdate>;
  cloneCodeSystem?: Maybe<CodeSystem>;
  collectionAddProducts?: Maybe<CollectionAddProducts>;
  collectionBulkDelete?: Maybe<CollectionBulkDelete>;
  collectionBulkPublish?: Maybe<CollectionBulkPublish>;
  collectionCreate?: Maybe<CollectionCreate>;
  collectionDelete?: Maybe<CollectionDelete>;
  collectionRemoveProducts?: Maybe<CollectionRemoveProducts>;
  collectionReorderProducts?: Maybe<CollectionReorderProducts>;
  collectionTranslate?: Maybe<CollectionTranslate>;
  collectionUpdate?: Maybe<CollectionUpdate>;
  confirmAccountEmail?: Maybe<ConfirmAccountEmail>;
  confirmAccountMobile?: Maybe<ConfirmAccountMobile>;
  confirmPasswordRestOtp?: Maybe<ConfirmPasswordRestOtp>;
  consumerPrescriptionDispense?: Maybe<ConsumerPrescriptionDispense>;
  consumerViewPreferenceSave?: Maybe<ConsumerViewPreferenceSave>;
  consumerWishlistDelete?: Maybe<ConsumerWishlistDelete>;
  convertVirtualOrderToRealOne?: Maybe<ConvertVirtualOrderToRealOne>;
  createConceptDefinition?: Maybe<CodeSystemConceptFieldDefinition>;
  createShipmentOrderForDeliveryShipment?: Maybe<DeliveryShipmentCreateShipmentResponseResult>;
  customerAcceptDraftOrder?: Maybe<CustomerAcceptDraftOrder>;
  customerMarkMessagesAsSeen?: Maybe<CustomerMarkMessagesAsSeen>;
  customerSendMessage?: Maybe<CustomerSendMessage>;
  deleteCodeSystemConcept?: Maybe<CodeSystemConcept>;
  deleteConceptDefinition?: Maybe<CodeSystemConceptFieldDefinition>;
  deleteDecision?: Maybe<Decision>;
  deleteSubList?: Maybe<SubList>;
  deleteSubListItem?: Maybe<SubListItem>;
  deliveryTimeSlotCreate?: Maybe<DeliveryTimeSlotCreate>;
  deliveryTimeSlotUpdateStatus?: Maybe<DeliveryTimeSlotUpdateStatus>;
  draftOrderCreate?: Maybe<DraftOrderCreate>;
  earlyRefillReasonCreate?: Maybe<EarlyRefillReasonCreate>;
  earlyRefillReasonDelete?: Maybe<EarlyRefillReasonDelete>;
  earlyRefillReasonUpdate?: Maybe<EarlyRefillReasonUpdate>;
  earlyRefillRequestCreate?: Maybe<EarlyRefillRequestCreate>;
  fetchTerminologyListData?: Maybe<SubList>;
  firePlanDecisions?: Maybe<Action>;
  fromGlobalId?: Maybe<FromGlobalId>;
  handleCheckoutPaymentRedirectionByPaymentCheckoutId?: Maybe<PaymentResponse>;
  handleHyperPayWebhookNotification?: Maybe<PaymentResponse>;
  handleNotification?: Maybe<DeliveryShipmentNotificationResponseResult>;
  handleOrderVendorAcceptTimeout?: Maybe<HandleOrderVendorAcceptTimeout>;
  handlePaymentRedirectionByPaymentId?: Maybe<PaymentResponse>;
  isPrescriptionEligibleForFreeDelivery?: Maybe<IsPrescriptionEligibleForFreeDelivery>;
  markNotificationsAsSeen?: Maybe<MarkNotificationsAsSeen>;
  marketplaceOrderStatusUpdate?: Maybe<MarketplaceOrderStatusUpdate>;
  medicalDeliveryRequestCreate?: Maybe<MedicalDeliveryRequestCreate>;
  medicalDeliveryRequestStatusUpdate?: Maybe<MedicalDeliveryRequestStatusUpdate>;
  medicalDeliveryRequestsCreate?: Maybe<MedicalDeliveryRequestsCreate>;
  notificationCreate?: Maybe<NotificationCreate>;
  orderAddNote?: Maybe<OrderAddNote>;
  orderCancel?: Maybe<OrderCancel>;
  orderDeliveryStatusUpdate?: Maybe<OrderDeliveryStatusUpdate>;
  orderGetErxHubApproval?: Maybe<OrderGetErxHubApproval>;
  orderRejectionReasonCreate?: Maybe<OrderRejectionReasonCreate>;
  orderRejectionReasonDelete?: Maybe<OrderRejectionReasonDelete>;
  orderRejectionReasonUpdate?: Maybe<OrderRejectionReasonUpdate>;
  orderStatusForceUpdate?: Maybe<OrderStatusForceUpdate>;
  orderStatusUpdate?: Maybe<OrderStatusUpdate>;
  passwordChange?: Maybe<PasswordChange>;
  patientCreate?: Maybe<PatientCreate>;
  patientInsuranceCardDelete?: Maybe<PatientInsuranceCardDelete>;
  patientInsuranceCardUpload?: Maybe<PatientInsuranceCardUpload>;
  patientMedicalHistoryCreate?: Maybe<PatientMedicalHistoryCreate>;
  patientMedicalHistoryDelete?: Maybe<PatientMedicalHistoryDelete>;
  patientMedicalHistoryUpdate?: Maybe<PatientMedicalHistoryUpdate>;
  patientSendOrderOtp?: Maybe<PatientSendOrderOtp>;
  permissionGroupCreate?: Maybe<PermissionGroupCreate>;
  permissionGroupDelete?: Maybe<PermissionGroupDelete>;
  permissionGroupUpdate?: Maybe<PermissionGroupUpdate>;
  planCreate?: Maybe<PlanCreate>;
  planUpdate?: Maybe<PlanUpdate>;
  pluginUpdate?: Maybe<PluginUpdate>;
  prescriptionOrderAssignVendors?: Maybe<PrescriptionOrderAssignVendors>;
  pricingRuleCreate?: Maybe<PricingRuleCreate>;
  pricingRuleDelete?: Maybe<PricingRuleDelete>;
  pricingRuleUpdate?: Maybe<PricingRuleUpdate>;
  processPaymentCheckout?: Maybe<PaymentCheckoutResponse>;
  processPaymentClearance?: Maybe<Array<Maybe<PaymentReceipt>>>;
  productBrandCreate?: Maybe<ProductBrandCreate>;
  productBrandDelete?: Maybe<ProductBrandDelete>;
  productBrandUpdate?: Maybe<ProductBrandUpdate>;
  productBulkDelete?: Maybe<ProductBulkDelete>;
  productBulkPublish?: Maybe<ProductBulkPublish>;
  productCreate?: Maybe<ProductCreate>;
  productImageBulkDelete?: Maybe<ProductImageBulkDelete>;
  productImageCreate?: Maybe<ProductImageCreate>;
  productImageDelete?: Maybe<ProductImageDelete>;
  productImageReorder?: Maybe<ProductImageReorder>;
  productImageUpdate?: Maybe<ProductImageUpdate>;
  productLike?: Maybe<ProductLike>;
  productTranslate?: Maybe<ProductTranslate>;
  productTypeBulkDelete?: Maybe<ProductTypeBulkDelete>;
  productTypeCreate?: Maybe<ProductTypeCreate>;
  productTypeDelete?: Maybe<ProductTypeDelete>;
  productTypeReorderAttributes?: Maybe<ProductTypeReorderAttributes>;
  productTypeTranslate?: Maybe<ProductTypeTranslate>;
  productTypeUpdate?: Maybe<ProductTypeUpdate>;
  productUnlike?: Maybe<ProductUnLike>;
  productUpdate?: Maybe<ProductUpdate>;
  productVariantBulkCreate?: Maybe<ProductVariantBulkCreate>;
  productVariantBulkDelete?: Maybe<ProductVariantBulkDelete>;
  productVariantCreate?: Maybe<ProductVariantCreate>;
  productVariantDelete?: Maybe<ProductVariantDelete>;
  productVariantImageAssign?: Maybe<ProductVariantImageAssign>;
  productVariantImageUnassign?: Maybe<ProductVariantImageUnassign>;
  productVariantReviewCreate?: Maybe<ProductVariantReviewCreate>;
  productVariantReviewDelete?: Maybe<ProductVariantReviewDelete>;
  productVariantReviewPublish?: Maybe<ProductVariantReviewPublish>;
  productVariantReviewUpdate?: Maybe<ProductVariantReviewUpdate>;
  productVariantTranslate?: Maybe<ProductVariantTranslate>;
  productVariantUpdate?: Maybe<ProductVariantUpdate>;
  publishCodeSystem?: Maybe<CodeSystem>;
  rankProductVariantReview?: Maybe<RankProductVariantReview>;
  rateVendor?: Maybe<RateVendor>;
  rejectVendor?: Maybe<RejectVendor>;
  removeVendorFromFavorite?: Maybe<RemoveVendorFromFavorite>;
  requestPasswordResetByMobile?: Maybe<RequestPasswordResetByMobile>;
  resubmitOrderGetErxHubApproval?: Maybe<ResubmitOrderGetErxHubApproval>;
  reviewCodeSystem?: Maybe<CodeSystem>;
  saleBulkDelete?: Maybe<SaleBulkDelete>;
  saleCataloguesAdd?: Maybe<SaleAddCatalogues>;
  saleCataloguesRemove?: Maybe<SaleRemoveCatalogues>;
  saleCreate?: Maybe<SaleCreate>;
  saleDelete?: Maybe<SaleDelete>;
  saleTranslate?: Maybe<SaleTranslate>;
  saleUpdate?: Maybe<SaleUpdate>;
  saveAllocateAndPickup?: Maybe<PickupFollowupInformation>;
  sendCodeSystemToReview?: Maybe<CodeSystem>;
  sendOrderSubscriptionEvent?: Maybe<SendOrderSubscriptionEvent>;
  sendPushNotification?: Maybe<SendPushNotification>;
  sendShipmentOrderForDeliveryShipmentNotificationReminder?: Maybe<DeliveryShipmentSendShipmentNotificationReminderResponseResult>;
  setOrderOutForDelivery?: Maybe<SetOrderToOutForDelivery>;
  setPassword?: Maybe<SetPassword>;
  setPharmacyCredentials?: Maybe<PharmacyCredentials>;
  setVendorWebHook?: Maybe<VendorWebhook>;
  shippingPriceBulkDelete?: Maybe<ShippingPriceBulkDelete>;
  shippingPriceCreate?: Maybe<ShippingPriceCreate>;
  shippingPriceDelete?: Maybe<ShippingPriceDelete>;
  shippingPriceTranslate?: Maybe<ShippingPriceTranslate>;
  shippingPriceUpdate?: Maybe<ShippingPriceUpdate>;
  shippingZoneBulkDelete?: Maybe<ShippingZoneBulkDelete>;
  shippingZoneCreate?: Maybe<ShippingZoneCreate>;
  shippingZoneDelete?: Maybe<ShippingZoneDelete>;
  shippingZoneUpdate?: Maybe<ShippingZoneUpdate>;
  siteSettingsUpdate?: Maybe<SiteSettingsUpdate>;
  submitSurveyAnswers?: Maybe<SurveyUserAnswer>;
  terminateVendorSubscription?: Maybe<TerminateVendorSubscription>;
  ticketPrescriptionDispense?: Maybe<TicketPrescriptionDispense>;
  toGlobalId?: Maybe<ToGlobalId>;
  tokenCreate?: Maybe<SsoCreateToken>;
  unAssignUserFromBranch?: Maybe<UnAssignUserFromBranch>;
  updateAllocateAndPickup?: Maybe<PickupFollowupInformation>;
  updateCodeSystemConcept?: Maybe<CodeSystemConcept>;
  updateConceptDefinition?: Maybe<CodeSystemConceptFieldDefinition>;
  updateDecision?: Maybe<Decision>;
  updatePayment?: Maybe<PaymentResponse>;
  updateShipmentOrderForDeliveryShipment?: Maybe<DeliveryShipmentGetShipmentResponseResult>;
  updateSubList?: Maybe<SubList>;
  updateSubListItem?: Maybe<SubListItem>;
  uploadAttachment?: Maybe<UploadAttachment>;
  uploadBulkFile?: Maybe<BulkFile>;
  uploadCodeSystemFile?: Maybe<CodeSystem>;
  uploadSubList?: Maybe<SubList>;
  userAvatarDelete?: Maybe<AccountAvatarDelete>;
  userAvatarUpdate?: Maybe<AccountAvatarUpdate>;
  userCreate?: Maybe<UserCreate>;
  userDelete?: Maybe<UserDelete>;
  userDeviceRegister?: Maybe<UserDeviceRegister>;
  userDeviceUnRegister?: Maybe<UserDeviceUnRegister>;
  userUpdate?: Maybe<UserUpdate>;
  validatePrescription?: Maybe<ValidatePrescription>;
  vendorCreate?: Maybe<VendorCreate>;
  vendorDelete?: Maybe<VendorDelete>;
  vendorImageCreate?: Maybe<VendorImageCreate>;
  vendorImageDelete?: Maybe<VendorImageDelete>;
  vendorImageReorder?: Maybe<VendorImageReorder>;
  vendorImageUpdate?: Maybe<VendorImageUpdate>;
  vendorInvoiceCreate?: Maybe<VendorInvoiceCreate>;
  vendorInvoiceUpdate?: Maybe<VendorInvoiceUpdate>;
  vendorMarkInvoiceAsPaid?: Maybe<VendorMarkInvoiceAsPaid>;
  vendorMarkMessagesAsSeen?: Maybe<VendorMarkMessagesAsSeen>;
  vendorOrderAccept?: Maybe<VendorOrderAccept>;
  vendorOrderCancel?: Maybe<VendorOrderCancel>;
  vendorOrderDeliveryStatusUpdate?: Maybe<VendorOrderDeliveryStatusUpdate>;
  vendorOrderReject?: Maybe<VendorOrderReject>;
  vendorProductVariantStocksCreate?: Maybe<VendorProductVariantStocksCreate>;
  vendorProductVariantStocksUpdate?: Maybe<VendorProductVariantStocksUpdate>;
  vendorRegister?: Maybe<VendorRegister>;
  vendorSendMessage?: Maybe<VendorSendMessage>;
  vendorUpdate?: Maybe<VendorUpdate>;
  vendorUpdateIsIntegrated?: Maybe<VendorUpdateIsIntegrated>;
  vendorUserCreate?: Maybe<VendorUserCreate>;
  vendorUserDelete?: Maybe<VendorUserDelete>;
  vendorUserUpdate?: Maybe<VendorUserUpdate>;
  walletTransactionCreate?: Maybe<WalletTransactionCreate>;
  wishlistAddVendorProductVariant?: Maybe<WishlistAddVendorProductVariantMutation>;
  wishlistRemoveVendorProductVariant?: Maybe<WishlistRemoveVendorProductVariantMutation>;
};


export type MutationAccountAddressCreateArgs = {
  input: AddressInput;
  type?: InputMaybe<AddressTypeEnum>;
};


export type MutationAccountAddressDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAccountAddressUpdateArgs = {
  id: Scalars['ID'];
  input: AddressInput;
};


export type MutationAccountReSendEmailOtpCodeArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type MutationAccountReSendOtpCodeArgs = {
  mobile?: InputMaybe<Scalars['String']>;
};


export type MutationAccountRegisterArgs = {
  input: AccountRegisterInput;
};


export type MutationAccountSetDefaultAddressArgs = {
  id: Scalars['ID'];
  type: AddressTypeEnum;
};


export type MutationAccountUpdateArgs = {
  input: AccountInput;
};


export type MutationAddDecisionArgs = {
  decision: DecisionInput;
};


export type MutationAddOrderToSurveyArgs = {
  isDelivery: Scalars['Boolean'];
  nationalId: Scalars['String'];
  orderId: Scalars['ID'];
};


export type MutationAddSubListArgs = {
  subList?: InputMaybe<SubListInput>;
};


export type MutationAddSubListItemArgs = {
  subListId: Scalars['ID'];
  subListItem: SubListItemInput;
};


export type MutationAddVendorToFavoriteArgs = {
  vendor?: Scalars['ID'];
};


export type MutationApproveVendorArgs = {
  id: Scalars['ID'];
};


export type MutationAssignCodeSystemEditorAndReviewerArgs = {
  code: CodeSystemCode;
  editorUser: Scalars['ID'];
  reviewerUser: Scalars['ID'];
};


export type MutationAssignUserToBranchArgs = {
  input?: InputMaybe<UserBranchInput>;
};


export type MutationAttributeAssignArgs = {
  operations: Array<InputMaybe<AttributeAssignInput>>;
  productTypeId: Scalars['ID'];
};


export type MutationAttributeBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationAttributeCreateArgs = {
  input: AttributeCreateInput;
};


export type MutationAttributeDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAttributeReorderValuesArgs = {
  attributeId: Scalars['ID'];
  moves: Array<InputMaybe<ReorderInput>>;
};


export type MutationAttributeTranslateArgs = {
  id: Scalars['ID'];
  input: NameTranslationInput;
  languageCode: LanguageCodeEnum;
};


export type MutationAttributeUnassignArgs = {
  attributeIds: Array<InputMaybe<Scalars['ID']>>;
  productTypeId: Scalars['ID'];
};


export type MutationAttributeUpdateArgs = {
  id: Scalars['ID'];
  input: AttributeUpdateInput;
};


export type MutationAttributeValueBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationAttributeValueCreateArgs = {
  attribute: Scalars['ID'];
  input: AttributeValueCreateInput;
};


export type MutationAttributeValueDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAttributeValueTranslateArgs = {
  id: Scalars['ID'];
  input: NameTranslationInput;
  languageCode: LanguageCodeEnum;
};


export type MutationAttributeValueUpdateArgs = {
  id: Scalars['ID'];
  input: AttributeValueCreateInput;
};


export type MutationBlockCreateArgs = {
  input: BlockInput;
};


export type MutationBlockDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationBlockUpdateArgs = {
  id: Scalars['ID'];
  input: BlockInput;
};


export type MutationBranchCreateArgs = {
  input: BranchInput;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type MutationBranchDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationBranchUpdateArgs = {
  id: Scalars['ID'];
  input: BranchInput;
};


export type MutationCalculateShipmentOrderPriceForDeliveryShipmentArgs = {
  calculateShipmentPriceRequest: DeliveryShipmentCalculateShipmentPriceRequest;
  deliveryDate?: InputMaybe<Scalars['String']>;
  deliveryInitiatorType?: InputMaybe<DeliveryInitiatorType>;
  deliveryTimeSlotEndTime?: InputMaybe<Scalars['String']>;
  deliveryTimeSlotStartTime?: InputMaybe<Scalars['String']>;
  deliveryTimeZone?: InputMaybe<Scalars['String']>;
  deliveryWorkflowId?: InputMaybe<Scalars['String']>;
  orderReferenceNumber?: InputMaybe<Scalars['String']>;
};


export type MutationCancelBulkFileArgs = {
  fileId: Scalars['ID'];
};


export type MutationCategoryBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationCategoryCreateArgs = {
  input: CategoryInput;
  parent?: InputMaybe<Scalars['ID']>;
};


export type MutationCategoryDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCategoryTranslateArgs = {
  id: Scalars['ID'];
  input: TranslationInput;
  languageCode: LanguageCodeEnum;
};


export type MutationCategoryUpdateArgs = {
  id: Scalars['ID'];
  input: CategoryInput;
};


export type MutationCheckoutAddNoteArgs = {
  checkoutId: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationCheckoutCompleteArgs = {
  checkoutId: Scalars['ID'];
  storeSource?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCheckoutCreateArgs = {
  input: CheckoutCreateInput;
};


export type MutationCheckoutCustomerAttachArgs = {
  checkoutId: Scalars['ID'];
};


export type MutationCheckoutDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCheckoutEmailUpdateArgs = {
  checkoutId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
};


export type MutationCheckoutLineDeleteArgs = {
  checkoutId: Scalars['ID'];
  lineId?: InputMaybe<Scalars['ID']>;
};


export type MutationCheckoutLinesAddArgs = {
  checkoutId: Scalars['ID'];
  lines: Array<InputMaybe<CheckoutLineInput>>;
};


export type MutationCheckoutLinesUpdateArgs = {
  checkoutId: Scalars['ID'];
  lines: Array<InputMaybe<CheckoutLineInput>>;
};


export type MutationCheckoutShippingAddressUpdateArgs = {
  checkoutId: Scalars['ID'];
  shippingAddress: AddressInput;
};


export type MutationCityCreateArgs = {
  input: CityInput;
};


export type MutationCityDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCityUpdateArgs = {
  id: Scalars['ID'];
  input: CityInput;
};


export type MutationCloneCodeSystemArgs = {
  codeSystemCode: CodeSystemCode;
};


export type MutationCollectionAddProductsArgs = {
  collectionId: Scalars['ID'];
  products: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationCollectionBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationCollectionBulkPublishArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  isPublished: Scalars['Boolean'];
};


export type MutationCollectionCreateArgs = {
  input: CollectionCreateInput;
};


export type MutationCollectionDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCollectionRemoveProductsArgs = {
  collectionId: Scalars['ID'];
  products: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationCollectionReorderProductsArgs = {
  collectionId: Scalars['ID'];
  moves: Array<InputMaybe<MoveProductInput>>;
};


export type MutationCollectionTranslateArgs = {
  id: Scalars['ID'];
  input: TranslationInput;
  languageCode: LanguageCodeEnum;
};


export type MutationCollectionUpdateArgs = {
  id: Scalars['ID'];
  input: CollectionInput;
};


export type MutationConfirmAccountEmailArgs = {
  email: Scalars['String'];
  sessionToken: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConfirmAccountMobileArgs = {
  mobile: Scalars['String'];
  sessionToken: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConfirmPasswordRestOtpArgs = {
  mobile: Scalars['String'];
  sessionToken: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConsumerPrescriptionDispenseArgs = {
  input: ConsumerPrescriptionDispenseInput;
};


export type MutationConsumerViewPreferenceSaveArgs = {
  input: ConsumerViewPreferenceInput;
};


export type MutationConvertVirtualOrderToRealOneArgs = {
  branches: Array<InputMaybe<Scalars['ID']>>;
  order?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateConceptDefinitionArgs = {
  codeSystemConceptFieldDefinition: CodeSystemConceptFieldDefinitionInput;
};


export type MutationCreateShipmentOrderForDeliveryShipmentArgs = {
  createShipmentRequest: DeliveryShipmentCreateShipmentRequest;
  deliveryDate?: InputMaybe<Scalars['String']>;
  deliveryInitiatorType?: InputMaybe<DeliveryInitiatorType>;
  deliveryTimeSlotEndTime?: InputMaybe<Scalars['String']>;
  deliveryTimeSlotStartTime?: InputMaybe<Scalars['String']>;
  deliveryTimeZone?: InputMaybe<Scalars['String']>;
};


export type MutationCustomerAcceptDraftOrderArgs = {
  id: Scalars['ID'];
  input: DraftOrderAcceptInput;
};


export type MutationCustomerMarkMessagesAsSeenArgs = {
  branchId: Scalars['String'];
};


export type MutationCustomerSendMessageArgs = {
  input: MessageInput;
};


export type MutationDeleteCodeSystemConceptArgs = {
  codeSystemConceptId?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteConceptDefinitionArgs = {
  codeSystemConceptFieldDefinitionId: Scalars['ID'];
};


export type MutationDeleteDecisionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSubListArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSubListItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeliveryTimeSlotCreateArgs = {
  input: DeliveryTimeSlotCreateInput;
};


export type MutationDeliveryTimeSlotUpdateStatusArgs = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
};


export type MutationDraftOrderCreateArgs = {
  input: DraftOrderCreateInput;
};


export type MutationEarlyRefillReasonCreateArgs = {
  input: EarlyRefillReasonInput;
};


export type MutationEarlyRefillReasonDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationEarlyRefillReasonUpdateArgs = {
  id: Scalars['ID'];
  input: EarlyRefillReasonInput;
};


export type MutationEarlyRefillRequestCreateArgs = {
  input: EarlyRefillRequestInput;
};


export type MutationFetchTerminologyListDataArgs = {
  codeSystemId: Scalars['ID'];
  searchParameters: Array<InputMaybe<SearchParameter>>;
  subListId: Scalars['ID'];
};


export type MutationFirePlanDecisionsArgs = {
  inputs: Array<InputMaybe<DecisionInputData>>;
  planCode: PlanCode;
};


export type MutationFromGlobalIdArgs = {
  id: Scalars['ID'];
};


export type MutationHandleCheckoutPaymentRedirectionByPaymentCheckoutIdArgs = {
  paymentCheckoutId: Scalars['String'];
};


export type MutationHandleHyperPayWebhookNotificationArgs = {
  body: Scalars['String'];
};


export type MutationHandleNotificationArgs = {
  notification: DeliveryShipmentNotificationRequest;
};


export type MutationHandleOrderVendorAcceptTimeoutArgs = {
  orders: Array<Scalars['ID']>;
};


export type MutationHandlePaymentRedirectionByPaymentIdArgs = {
  paymentId: Scalars['String'];
};


export type MutationIsPrescriptionEligibleForFreeDeliveryArgs = {
  id: Scalars['ID'];
  input: FreeDeliveryEligibilityCheckInput;
};


export type MutationMarketplaceOrderStatusUpdateArgs = {
  id: Scalars['ID'];
  status: MarketplaceProductOrderStatusEnum;
};


export type MutationMedicalDeliveryRequestCreateArgs = {
  input: MedicalDeliveryRequestInput;
};


export type MutationMedicalDeliveryRequestStatusUpdateArgs = {
  id: Scalars['ID'];
  input: MedicalDeliveryRequestStatusUpdateInput;
};


export type MutationMedicalDeliveryRequestsCreateArgs = {
  input: Array<MedicalDeliveryRequestInput>;
};


export type MutationNotificationCreateArgs = {
  input: NotificationInput;
};


export type MutationOrderAddNoteArgs = {
  input: OrderAddNoteInput;
  order: Scalars['ID'];
};


export type MutationOrderCancelArgs = {
  cancellationReason?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  nationalId?: InputMaybe<Scalars['String']>;
};


export type MutationOrderDeliveryStatusUpdateArgs = {
  id: Scalars['ID'];
  input: OrderDeliveryStatusUpdateInput;
};


export type MutationOrderGetErxHubApprovalArgs = {
  id: Scalars['ID'];
};


export type MutationOrderRejectionReasonCreateArgs = {
  input: OrderRejectionReasonInput;
};


export type MutationOrderRejectionReasonDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationOrderRejectionReasonUpdateArgs = {
  id: Scalars['ID'];
  input: OrderRejectionReasonInput;
};


export type MutationOrderStatusForceUpdateArgs = {
  id: Scalars['ID'];
  input: OrderStatusUpdateInput;
};


export type MutationOrderStatusUpdateArgs = {
  id: Scalars['ID'];
  input: OrderStatusUpdateInput;
};


export type MutationPasswordChangeArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationPatientCreateArgs = {
  input: PatientInput;
};


export type MutationPatientInsuranceCardDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationPatientInsuranceCardUploadArgs = {
  input: PatientInsuranceCardUploadInput;
};


export type MutationPatientMedicalHistoryCreateArgs = {
  input: PatientMedicalHistoryInput;
  patientId?: InputMaybe<Scalars['ID']>;
};


export type MutationPatientMedicalHistoryDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationPatientMedicalHistoryUpdateArgs = {
  id: Scalars['ID'];
  input: PatientMedicalHistoryInput;
};


export type MutationPatientSendOrderOtpArgs = {
  input: PatientSendOrderOtpInput;
};


export type MutationPermissionGroupCreateArgs = {
  input: PermissionGroupCreateInput;
};


export type MutationPermissionGroupDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationPermissionGroupUpdateArgs = {
  id: Scalars['ID'];
  input: PermissionGroupUpdateInput;
};


export type MutationPlanCreateArgs = {
  input: PlanInput;
};


export type MutationPlanUpdateArgs = {
  id: Scalars['ID'];
  input: PlanInput;
};


export type MutationPluginUpdateArgs = {
  id: Scalars['ID'];
  input: PluginUpdateInput;
};


export type MutationPrescriptionOrderAssignVendorsArgs = {
  input: PrescriptionOrderAssignVendorsInput;
};


export type MutationPricingRuleCreateArgs = {
  input: PricingRuleInput;
};


export type MutationPricingRuleDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationPricingRuleUpdateArgs = {
  id: Scalars['ID'];
  input: PricingRuleInput;
};


export type MutationProcessPaymentCheckoutArgs = {
  paymentCheckoutInput: PaymentCheckoutInput;
};


export type MutationProcessPaymentClearanceArgs = {
  input?: InputMaybe<PaymentClearanceInput>;
};


export type MutationProductBrandCreateArgs = {
  input: ProductBrandInput;
};


export type MutationProductBrandDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationProductBrandUpdateArgs = {
  id: Scalars['ID'];
  input: ProductBrandInput;
};


export type MutationProductBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationProductBulkPublishArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  isPublished: Scalars['Boolean'];
};


export type MutationProductCreateArgs = {
  input: ProductCreateInput;
};


export type MutationProductImageBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationProductImageCreateArgs = {
  input: ProductImageCreateInput;
};


export type MutationProductImageDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationProductImageReorderArgs = {
  imagesIds: Array<InputMaybe<Scalars['ID']>>;
  productId: Scalars['ID'];
};


export type MutationProductImageUpdateArgs = {
  id: Scalars['ID'];
  input: ProductImageUpdateInput;
};


export type MutationProductLikeArgs = {
  input: ProductLikeInput;
};


export type MutationProductTranslateArgs = {
  id: Scalars['ID'];
  input: TranslationInput;
  languageCode: LanguageCodeEnum;
};


export type MutationProductTypeBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationProductTypeCreateArgs = {
  input: ProductTypeInput;
};


export type MutationProductTypeDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationProductTypeReorderAttributesArgs = {
  moves: Array<InputMaybe<ReorderInput>>;
  productTypeId: Scalars['ID'];
  type: AttributeTypeEnum;
};


export type MutationProductTypeTranslateArgs = {
  id: Scalars['ID'];
  input: NameTranslationInput;
  languageCode: LanguageCodeEnum;
};


export type MutationProductTypeUpdateArgs = {
  id: Scalars['ID'];
  input: ProductTypeInput;
};


export type MutationProductUnlikeArgs = {
  input: ProductLikeInput;
};


export type MutationProductUpdateArgs = {
  id: Scalars['ID'];
  input: ProductInput;
};


export type MutationProductVariantBulkCreateArgs = {
  product: Scalars['ID'];
  variants: Array<InputMaybe<ProductVariantBulkCreateInput>>;
};


export type MutationProductVariantBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationProductVariantCreateArgs = {
  input: ProductVariantCreateInput;
};


export type MutationProductVariantDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationProductVariantImageAssignArgs = {
  imageId: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type MutationProductVariantImageUnassignArgs = {
  imageId: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type MutationProductVariantReviewCreateArgs = {
  input: ProductVariantReviewCreateInput;
};


export type MutationProductVariantReviewDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationProductVariantReviewPublishArgs = {
  id: Scalars['ID'];
  input: ProductVariantReviewPublishInput;
};


export type MutationProductVariantReviewUpdateArgs = {
  id: Scalars['ID'];
  input: ProductVariantReviewUpdateInput;
};


export type MutationProductVariantTranslateArgs = {
  id: Scalars['ID'];
  input: NameTranslationInput;
  languageCode: LanguageCodeEnum;
};


export type MutationProductVariantUpdateArgs = {
  id: Scalars['ID'];
  input: ProductVariantInput;
};


export type MutationPublishCodeSystemArgs = {
  codeSystemId: Scalars['ID'];
};


export type MutationRankProductVariantReviewArgs = {
  input: RankProductVariantReviewInput;
};


export type MutationRateVendorArgs = {
  input: VendorRatingInput;
};


export type MutationRejectVendorArgs = {
  input: VendorRejectionReasonInput;
};


export type MutationRemoveVendorFromFavoriteArgs = {
  vendor?: Scalars['ID'];
};


export type MutationRequestPasswordResetByMobileArgs = {
  app: AppTypes;
  mobile: Scalars['String'];
};


export type MutationResubmitOrderGetErxHubApprovalArgs = {
  id: Scalars['ID'];
  input: VendorOrderAcceptInput;
};


export type MutationReviewCodeSystemArgs = {
  codeSystemId: Scalars['ID'];
  processingStatus: ProcessingStatus;
};


export type MutationSaleBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationSaleCataloguesAddArgs = {
  id: Scalars['ID'];
  input: CatalogueInput;
};


export type MutationSaleCataloguesRemoveArgs = {
  id: Scalars['ID'];
  input: CatalogueInput;
};


export type MutationSaleCreateArgs = {
  input: SaleInput;
};


export type MutationSaleDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationSaleTranslateArgs = {
  id: Scalars['ID'];
  input: NameTranslationInput;
  languageCode: LanguageCodeEnum;
};


export type MutationSaleUpdateArgs = {
  id: Scalars['ID'];
  input: SaleInput;
};


export type MutationSaveAllocateAndPickupArgs = {
  allocateOrderId?: InputMaybe<Scalars['String']>;
  currentAuthorizationId?: InputMaybe<Scalars['String']>;
  orderReferenceNumber?: InputMaybe<Scalars['String']>;
  pickupFollowupWorkflowId?: InputMaybe<Scalars['String']>;
};


export type MutationSendCodeSystemToReviewArgs = {
  codeSystemId: Scalars['ID'];
};


export type MutationSendOrderSubscriptionEventArgs = {
  orderId: Scalars['ID'];
};


export type MutationSendPushNotificationArgs = {
  notification: PushNotificationInput;
  userId: Scalars['ID'];
};


export type MutationSendShipmentOrderForDeliveryShipmentNotificationReminderArgs = {
  allocateOrderId?: InputMaybe<Scalars['String']>;
  dispenseOrderId?: InputMaybe<Scalars['String']>;
};


export type MutationSetOrderOutForDeliveryArgs = {
  id: Scalars['ID'];
};


export type MutationSetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  username: Scalars['String'];
};


export type MutationSetPharmacyCredentialsArgs = {
  pharmacyCredentials?: InputMaybe<PharmacyCredentialsInput>;
};


export type MutationSetVendorWebHookArgs = {
  vendorWebhook: VendorWebhookInput;
};


export type MutationShippingPriceBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationShippingPriceCreateArgs = {
  input: ShippingPriceInput;
};


export type MutationShippingPriceDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationShippingPriceTranslateArgs = {
  id: Scalars['ID'];
  input: NameTranslationInput;
  languageCode: LanguageCodeEnum;
};


export type MutationShippingPriceUpdateArgs = {
  id: Scalars['ID'];
  input: ShippingPriceInput;
};


export type MutationShippingZoneBulkDeleteArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationShippingZoneCreateArgs = {
  input: ShippingZoneCreateInput;
};


export type MutationShippingZoneDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationShippingZoneUpdateArgs = {
  id: Scalars['ID'];
  input: ShippingZoneUpdateInput;
};


export type MutationSiteSettingsUpdateArgs = {
  input: SiteSettingsInput;
};


export type MutationSubmitSurveyAnswersArgs = {
  input: SurveyAnswerInput;
};


export type MutationTerminateVendorSubscriptionArgs = {
  vendor: Scalars['ID'];
};


export type MutationTicketPrescriptionDispenseArgs = {
  input: TicketPrescriptionDispenseInput;
};


export type MutationToGlobalIdArgs = {
  pk: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};


export type MutationTokenCreateArgs = {
  app: AppTypes;
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationUnAssignUserFromBranchArgs = {
  input?: InputMaybe<UserBranchInput>;
};


export type MutationUpdateAllocateAndPickupArgs = {
  orderId?: InputMaybe<Scalars['String']>;
  pickedup?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCodeSystemConceptArgs = {
  codeSystemConcept?: InputMaybe<CodeSystemConceptInput>;
};


export type MutationUpdateConceptDefinitionArgs = {
  codeSystemConceptFieldDefinition: CodeSystemConceptFieldDefinitionInput;
};


export type MutationUpdateDecisionArgs = {
  decision: DecisionInput;
};


export type MutationUpdatePaymentArgs = {
  paymentUpdateInput: PaymentUpdateInput;
};


export type MutationUpdateShipmentOrderForDeliveryShipmentArgs = {
  allocateOrderId?: InputMaybe<Scalars['String']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  createIfNotExist?: InputMaybe<Scalars['Boolean']>;
  deliveryFollowupWorkflowId?: InputMaybe<Scalars['String']>;
  dispenseOrderId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateSubListArgs = {
  subList?: InputMaybe<SubListInput>;
};


export type MutationUpdateSubListItemArgs = {
  subListItem: SubListItemInput;
};


export type MutationUploadAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationUploadBulkFileArgs = {
  code: BulkTemplateCode;
  entityID?: InputMaybe<Scalars['ID']>;
  fileName: Scalars['String'];
};


export type MutationUploadCodeSystemFileArgs = {
  codeSystemCode: CodeSystemCode;
  fileName: Scalars['String'];
};


export type MutationUploadSubListArgs = {
  fileName: Scalars['String'];
  subListId: Scalars['ID'];
};


export type MutationUserAvatarUpdateArgs = {
  image: Scalars['String'];
};


export type MutationUserCreateArgs = {
  input: AdminUserCreateInput;
};


export type MutationUserDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationUserDeviceRegisterArgs = {
  input: UserDeviceRegisterInput;
};


export type MutationUserDeviceUnRegisterArgs = {
  input: UserDeviceUnRegisterInput;
};


export type MutationUserUpdateArgs = {
  id: Scalars['ID'];
  input: UserUpdateInput;
};


export type MutationValidatePrescriptionArgs = {
  input: ValidatePrescriptionInput;
};


export type MutationVendorCreateArgs = {
  input: VendorAdminInput;
};


export type MutationVendorDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationVendorImageCreateArgs = {
  input: VendorImageCreateInput;
};


export type MutationVendorImageDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationVendorImageReorderArgs = {
  imagesIds: Array<InputMaybe<Scalars['ID']>>;
  vendorId: Scalars['ID'];
};


export type MutationVendorImageUpdateArgs = {
  id: Scalars['ID'];
  input: VendorImageUpdateInput;
};


export type MutationVendorInvoiceCreateArgs = {
  input: VendorInvoiceInput;
};


export type MutationVendorInvoiceUpdateArgs = {
  id: Scalars['ID'];
  input: VendorInvoiceUpdateInput;
};


export type MutationVendorMarkInvoiceAsPaidArgs = {
  id: Scalars['ID'];
};


export type MutationVendorMarkMessagesAsSeenArgs = {
  branchId: Scalars['String'];
  customerId: Scalars['String'];
};


export type MutationVendorOrderAcceptArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<VendorOrderAcceptInput>;
};


export type MutationVendorOrderCancelArgs = {
  cancellationReason: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationVendorOrderDeliveryStatusUpdateArgs = {
  id: Scalars['ID'];
  input: OrderDeliveryStatusUpdateInput;
};


export type MutationVendorOrderRejectArgs = {
  id: Scalars['ID'];
  rejectionReason: Scalars['String'];
};


export type MutationVendorProductVariantStocksCreateArgs = {
  input: VendorProductVariantStocksCreateInput;
};


export type MutationVendorProductVariantStocksUpdateArgs = {
  input: VendorProductVariantStocksUpdateInput;
};


export type MutationVendorRegisterArgs = {
  input: VendorInput;
};


export type MutationVendorSendMessageArgs = {
  input: VendorMessageInput;
};


export type MutationVendorUpdateArgs = {
  id: Scalars['ID'];
  input: VendorUpdateAdminInput;
};


export type MutationVendorUpdateIsIntegratedArgs = {
  id: Scalars['ID'];
  input: VendorUpdateIsIntegratedInput;
};


export type MutationVendorUserCreateArgs = {
  input: VendorUserCreateInput;
};


export type MutationVendorUserDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationVendorUserUpdateArgs = {
  id: Scalars['ID'];
  input: VendorUserUpdateInput;
};


export type MutationWalletTransactionCreateArgs = {
  id: Scalars['ID'];
  input: WalletTransactionInput;
};


export type MutationWishlistAddVendorProductVariantArgs = {
  vendorProductVariant: Scalars['ID'];
};


export type MutationWishlistRemoveVendorProductVariantArgs = {
  vendorProductVariant: Scalars['ID'];
};

export type NameTranslationInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type NeedActionCodeSystems = {
  __typename?: 'NeedActionCodeSystems';
  codeSystemEvents?: Maybe<Array<Maybe<CodeSystemEvent>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  body?: Maybe<Scalars['String']>;
  category?: Maybe<NotificationCategory>;
  created: Scalars['DateTime'];
  extraData?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  seenDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  type: NotificationType;
};

export enum NotificationCategory {
  AutoAssignFailed = 'AUTO_ASSIGN_FAILED',
  DataExport = 'DATA_EXPORT',
  DataExportFailure = 'DATA_EXPORT_FAILURE',
  InvoiceIssued = 'INVOICE_ISSUED',
  LicenseExpiry = 'LICENSE_EXPIRY',
  OrderRejected = 'ORDER_REJECTED',
  VendorRegistered = 'VENDOR_REGISTERED',
  WorkflowFailure = 'WORKFLOW_FAILURE'
}

export enum NotificationCategoryEnum {
  AutoAssignFailed = 'AUTO_ASSIGN_FAILED',
  DataExport = 'DATA_EXPORT',
  DataExportFailure = 'DATA_EXPORT_FAILURE',
  InvoiceIssued = 'INVOICE_ISSUED',
  LicenseExpiry = 'LICENSE_EXPIRY',
  OrderRejected = 'ORDER_REJECTED',
  VendorRegistered = 'VENDOR_REGISTERED',
  WorkflowFailure = 'WORKFLOW_FAILURE'
}

export type NotificationCountableConnection = {
  __typename?: 'NotificationCountableConnection';
  edges: Array<NotificationCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type NotificationCountableEdge = {
  __typename?: 'NotificationCountableEdge';
  cursor: Scalars['String'];
  node: Notification;
};

export type NotificationCreate = {
  __typename?: 'NotificationCreate';
  accountErrors: Array<NotificationError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  notification?: Maybe<Notification>;
};

export type NotificationCreated = {
  __typename?: 'NotificationCreated';
  notification?: Maybe<Notification>;
};

export type NotificationError = {
  __typename?: 'NotificationError';
  code: NotificationErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum NotificationErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  PasswordTooSimilar = 'PASSWORD_TOO_SIMILAR',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type NotificationFilterInput = {
  seen?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationInput = {
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<NotificationCategoryEnum>;
  data?: InputMaybe<Scalars['JSONString']>;
  key?: InputMaybe<Scalars['String']>;
  notifiedUsers?: InputMaybe<Array<Scalars['ID']>>;
  pushNotificationApp?: InputMaybe<AppTypes>;
  title?: InputMaybe<Scalars['String']>;
  type: NotificationTypeEnum;
  withPushNotification?: InputMaybe<Scalars['Boolean']>;
};

export enum NotificationType {
  Info = 'INFO',
  Warning = 'WARNING'
}

export enum NotificationTypeEnum {
  Info = 'INFO',
  Warning = 'WARNING'
}

export type ObjectWithBasicTranslatableFields = {
  name?: Maybe<Scalars['String']>;
};

export type ObjectWithExtendedTranslatableFields = {
  description?: Maybe<Scalars['String']>;
  descriptionJson?: Maybe<Scalars['JSONString']>;
  name?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
};

export type Observation = {
  __typename?: 'Observation';
  code?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueType?: Maybe<Scalars['String']>;
};

export enum OperationStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Successful = 'SUCCESSFUL'
}

export enum OperationType {
  Refund = 'REFUND'
}

export enum Operator {
  Contains = 'CONTAINS',
  Count = 'COUNT',
  EndWith = 'END_WITH',
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  In = 'IN',
  Include = 'INCLUDE',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  NotContains = 'NOT_CONTAINS',
  NotEqual = 'NOT_EQUAL',
  NotIn = 'NOT_IN',
  NotInclude = 'NOT_INCLUDE',
  StartWith = 'START_WITH'
}

export type Order = Node & {
  __typename?: 'Order';
  assignedShippingPrice?: Maybe<Money>;
  branch?: Maybe<Branch>;
  carrierName?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<Order>>>;
  codPaymentAmount: Money;
  created: Scalars['DateTime'];
  csTicketId?: Maybe<Scalars['String']>;
  customerNote: Scalars['String'];
  deliveryDate?: Maybe<Scalars['Date']>;
  deliveryStatus?: Maybe<Scalars['String']>;
  deliveryTimeSlot?: Maybe<DeliveryTimeSlot>;
  discount?: Maybe<Money>;
  discountName?: Maybe<Scalars['String']>;
  displayGrossPrices: Scalars['Boolean'];
  distanceGroup?: Maybe<Scalars['String']>;
  events?: Maybe<Array<Maybe<OrderEvent>>>;
  failReason?: Maybe<Scalars['String']>;
  helpDiskTicketId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCodPayment?: Maybe<Scalars['Boolean']>;
  isDeliveryRequested: Scalars['Boolean'];
  isFreeDelivery: Scalars['Boolean'];
  isPaid?: Maybe<Scalars['Boolean']>;
  isPickedUp?: Maybe<Scalars['Boolean']>;
  languageCode: Scalars['String'];
  lines: Array<Maybe<OrderLine>>;
  modified: Scalars['DateTime'];
  nationalId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  parent?: Maybe<Order>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  pickUpOtp?: Maybe<Scalars['String']>;
  pickupFollowupInformation?: Maybe<OrderPickupFollowupInformation>;
  prescription?: Maybe<Prescription>;
  rejectionReason?: Maybe<OrderRejectionReason>;
  serviceCommissions?: Maybe<Scalars['Float']>;
  shipmentInformation?: Maybe<DeliveryShipmentGetShipmentResponseResult>;
  shippingAddress?: Maybe<OrderAddress>;
  shippingPrice?: Maybe<TaxedMoney>;
  source: OrderSource;
  status: OrderStatus;
  subtotal?: Maybe<TaxedMoney>;
  token: Scalars['String'];
  total?: Maybe<TaxedMoney>;
  totalBalance: Money;
  totalOrderDistance?: Maybe<Scalars['Float']>;
  trackingClientId: Scalars['String'];
  translatedDiscountName?: Maybe<Scalars['String']>;
  type?: Maybe<OrderTypesEnum>;
  user?: Maybe<User>;
  userEmail?: Maybe<Scalars['String']>;
  weight?: Maybe<Weight>;
};


export type OrderPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type OrderPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OrderAddNote = {
  __typename?: 'OrderAddNote';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  event?: Maybe<OrderEvent>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderAddNoteInput = {
  message: Scalars['String'];
};

export type OrderAddress = Node & {
  __typename?: 'OrderAddress';
  area?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  coordinates?: Maybe<LocationType>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  streetAddress1?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<OrderAddressTranslation>>>;
  unitNumber?: Maybe<Scalars['String']>;
};

export type OrderAddressTranslation = Node & {
  __typename?: 'OrderAddressTranslation';
  area: Scalars['String'];
  buildingName: Scalars['String'];
  district: Scalars['String'];
  id: Scalars['ID'];
  language: LanguageDisplay;
  name?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  streetAddress1: Scalars['String'];
  streetAddress2: Scalars['String'];
};

export type OrderCancel = {
  __typename?: 'OrderCancel';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderCountableConnection = {
  __typename?: 'OrderCountableConnection';
  edges: Array<OrderCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OrderCountableEdge = {
  __typename?: 'OrderCountableEdge';
  cursor: Scalars['String'];
  node: Order;
};

export type OrderDashboardFilterInput = {
  created?: InputMaybe<DateRangeInput>;
};

export type OrderDeleted = {
  __typename?: 'OrderDeleted';
  event?: Maybe<OrderDeletedEvent>;
};

export type OrderDeletedEvent = {
  __typename?: 'OrderDeletedEvent';
  orderId?: Maybe<Scalars['ID']>;
};

export enum OrderDeliveryStatusEnum {
  CarrierAllocated = 'CARRIER_ALLOCATED',
  DeliveryOrderCreated = 'DELIVERY_ORDER_CREATED',
  DeliveryTimedOut = 'DELIVERY_TIMED_OUT',
  DriverAcceptedOrder = 'DRIVER_ACCEPTED_ORDER',
  DriverIsAboutToArrive = 'DRIVER_IS_ABOUT_TO_ARRIVE',
  DriverPickedUpOrder = 'DRIVER_PICKED_UP_ORDER',
  Failed = 'FAILED',
  New = 'NEW',
  OrderIsCancelled = 'ORDER_IS_CANCELLED',
  OrderIsDelivered = 'ORDER_IS_DELIVERED',
  OrderIsResumed = 'ORDER_IS_RESUMED',
  OrderIsSuspended = 'ORDER_IS_SUSPENDED',
  PatientConfirmed = 'PATIENT_CONFIRMED',
  Pending = 'PENDING',
  PickedByConsumer = 'PICKED_BY_CONSUMER',
  Returned = 'RETURNED'
}

export type OrderDeliveryStatusUpdate = {
  __typename?: 'OrderDeliveryStatusUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderDeliveryStatusUpdateInput = {
  carrierName?: InputMaybe<Scalars['String']>;
  customOrderRejectionReason?: InputMaybe<Scalars['String']>;
  deliveryPrice?: InputMaybe<Scalars['Float']>;
  deliveryStatus: OrderDeliveryStatusEnum;
  distanceGroup?: InputMaybe<Scalars['String']>;
  orderRejectionReason?: InputMaybe<Scalars['ID']>;
  pickUpOtp?: InputMaybe<Scalars['String']>;
  totalOrderDistance?: InputMaybe<Scalars['Float']>;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderDraftFilterInput = {
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created?: InputMaybe<DateRangeInput>;
};

export type OrderError = {
  __typename?: 'OrderError';
  branch?: Maybe<Scalars['ID']>;
  code: OrderErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  orderLine?: Maybe<Scalars['ID']>;
};

export enum OrderErrorCode {
  BillingAddressNotSet = 'BILLING_ADDRESS_NOT_SET',
  CannotAcceptOrder = 'CANNOT_ACCEPT_ORDER',
  CannotCancelFulfillment = 'CANNOT_CANCEL_FULFILLMENT',
  CannotCancelOrder = 'CANNOT_CANCEL_ORDER',
  CannotDelete = 'CANNOT_DELETE',
  CannotRefund = 'CANNOT_REFUND',
  CannotRejectOrder = 'CANNOT_REJECT_ORDER',
  CaptureInactivePayment = 'CAPTURE_INACTIVE_PAYMENT',
  ControlledDrug = 'CONTROLLED_DRUG',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  FulfillOrderLine = 'FULFILL_ORDER_LINE',
  GraphqlError = 'GRAPHQL_ERROR',
  Ingested = 'INGESTED',
  InsufficientStock = 'INSUFFICIENT_STOCK',
  Invalid = 'INVALID',
  NotEditable = 'NOT_EDITABLE',
  NotFound = 'NOT_FOUND',
  OrderNoShippingAddress = 'ORDER_NO_SHIPPING_ADDRESS',
  PaymentError = 'PAYMENT_ERROR',
  PaymentMissing = 'PAYMENT_MISSING',
  ProductNotPublished = 'PRODUCT_NOT_PUBLISHED',
  Required = 'REQUIRED',
  ShippingMethodNotApplicable = 'SHIPPING_METHOD_NOT_APPLICABLE',
  ShippingMethodRequired = 'SHIPPING_METHOD_REQUIRED',
  Unique = 'UNIQUE',
  VoidInactivePayment = 'VOID_INACTIVE_PAYMENT',
  ZeroQuantity = 'ZERO_QUANTITY'
}

export type OrderEvent = Node & {
  __typename?: 'OrderEvent';
  amount?: Maybe<Scalars['Float']>;
  composedId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailType?: Maybe<OrderEventsEmailsEnum>;
  fulfilledItems?: Maybe<Array<Maybe<FulfillmentLine>>>;
  id: Scalars['ID'];
  lines?: Maybe<Array<Maybe<OrderEventOrderLineObject>>>;
  message?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  oversoldItems?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentGateway?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  type?: Maybe<OrderEventsEnum>;
  user?: Maybe<User>;
};

export type OrderEventOrderLineObject = {
  __typename?: 'OrderEventOrderLineObject';
  itemName?: Maybe<Scalars['String']>;
  orderLine?: Maybe<OrderLine>;
  quantity?: Maybe<Scalars['Int']>;
};

export enum OrderEventsEmailsEnum {
  FulfillmentConfirmation = 'FULFILLMENT_CONFIRMATION',
  OrderConfirmation = 'ORDER_CONFIRMATION',
  PaymentConfirmation = 'PAYMENT_CONFIRMATION',
  ShippingConfirmation = 'SHIPPING_CONFIRMATION',
  TrackingUpdated = 'TRACKING_UPDATED'
}

export enum OrderEventsEnum {
  Allocated = 'ALLOCATED',
  Canceled = 'CANCELED',
  CardPaid = 'CARD_PAID',
  ConsumerCanceled = 'CONSUMER_CANCELED',
  ConsumerPicked = 'CONSUMER_PICKED',
  Delivered = 'DELIVERED',
  DeliveredTimedOut = 'DELIVERED_TIMED_OUT',
  DeliveryOrderCreated = 'DELIVERY_ORDER_CREATED',
  Dispensed = 'DISPENSED',
  DraftAddedProducts = 'DRAFT_ADDED_PRODUCTS',
  DraftCreated = 'DRAFT_CREATED',
  DraftCustomerAccepted = 'DRAFT_CUSTOMER_ACCEPTED',
  DraftRemovedProducts = 'DRAFT_REMOVED_PRODUCTS',
  DriverAcceptedOrder = 'DRIVER_ACCEPTED_ORDER',
  DriverDelivered = 'DRIVER_DELIVERED',
  DriverIsAboutToArrive = 'DRIVER_IS_ABOUT_TO_ARRIVE',
  DriverPicked = 'DRIVER_PICKED',
  DriverReturned = 'DRIVER_RETURNED',
  EmailSent = 'EMAIL_SENT',
  ErxHubAuthRequestFailed = 'ERX_HUB_AUTH_REQUEST_FAILED',
  ErxHubAuthSubmitTimedOut = 'ERX_HUB_AUTH_SUBMIT_TIMED_OUT',
  ErxHubClaimApproved = 'ERX_HUB_CLAIM_APPROVED',
  ErxHubClaimFailed = 'ERX_HUB_CLAIM_FAILED',
  ErxHubClaimRejected = 'ERX_HUB_CLAIM_REJECTED',
  ErxHubClaimSubmitTimedOut = 'ERX_HUB_CLAIM_SUBMIT_TIMED_OUT',
  ErxHubFullyApproved = 'ERX_HUB_FULLY_APPROVED',
  ErxHubPartiallyApproved = 'ERX_HUB_PARTIALLY_APPROVED',
  ErxHubRejected = 'ERX_HUB_REJECTED',
  ErxHubTimedOut = 'ERX_HUB_TIMED_OUT',
  Failed = 'FAILED',
  FailedToDispense = 'FAILED_TO_DISPENSE',
  FulfillmentCanceled = 'FULFILLMENT_CANCELED',
  FulfillmentFulfilledItems = 'FULFILLMENT_FULFILLED_ITEMS',
  FulfillmentRestockedItems = 'FULFILLMENT_RESTOCKED_ITEMS',
  NoteAdded = 'NOTE_ADDED',
  OrderCarrierAllocated = 'ORDER_CARRIER_ALLOCATED',
  OrderCreated = 'ORDER_CREATED',
  OrderDeliveryFailed = 'ORDER_DELIVERY_FAILED',
  OrderDeliveryIsCancelled = 'ORDER_DELIVERY_IS_CANCELLED',
  OrderDeliveryIsResumed = 'ORDER_DELIVERY_IS_RESUMED',
  OrderDeliveryIsSuspended = 'ORDER_DELIVERY_IS_SUSPENDED',
  OrderDeliveryTimedOut = 'ORDER_DELIVERY_TIMED_OUT',
  OrderFullyPaid = 'ORDER_FULLY_PAID',
  OrderMarkedAsPaid = 'ORDER_MARKED_AS_PAID',
  OrderPickedByCustomer = 'ORDER_PICKED_BY_CUSTOMER',
  Other = 'OTHER',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  OutForDeliveryTimedOut = 'OUT_FOR_DELIVERY_TIMED_OUT',
  OversoldItems = 'OVERSOLD_ITEMS',
  PatientConfirmed = 'PATIENT_CONFIRMED',
  PaymentCaptured = 'PAYMENT_CAPTURED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentOnArrive = 'PAYMENT_ON_ARRIVE',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentRefunded = 'PAYMENT_REFUNDED',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentTimedOut = 'PAYMENT_TIMED_OUT',
  PaymentVoided = 'PAYMENT_VOIDED',
  PendingDelivery = 'PENDING_DELIVERY',
  Placed = 'PLACED',
  PlacedFromDraft = 'PLACED_FROM_DRAFT',
  PrescriptionOrderCreated = 'PRESCRIPTION_ORDER_CREATED',
  PrescriptionOrderVendorAssigned = 'PRESCRIPTION_ORDER_VENDOR_ASSIGNED',
  PrescriptionRefillOrderCreated = 'PRESCRIPTION_REFILL_ORDER_CREATED',
  ReadyForCustomerPickup = 'READY_FOR_CUSTOMER_PICKUP',
  ReadyForDelivery = 'READY_FOR_DELIVERY',
  Returned = 'RETURNED',
  ReturnedTimedOut = 'RETURNED_TIMED_OUT',
  ReturnInProgress = 'RETURN_IN_PROGRESS',
  TrackingUpdated = 'TRACKING_UPDATED',
  UpdatedAddress = 'UPDATED_ADDRESS',
  VendorAccepted = 'VENDOR_ACCEPTED',
  VendorCanceled = 'VENDOR_CANCELED',
  VendorDelivered = 'VENDOR_DELIVERED',
  VendorFinishedProcessing = 'VENDOR_FINISHED_PROCESSING',
  VendorRejected = 'VENDOR_REJECTED',
  VendorResponseTimedOut = 'VENDOR_RESPONSE_TIMED_OUT',
  VendorReturned = 'VENDOR_RETURNED',
  WaitingErxHubAuthApproval = 'WAITING_ERX_HUB_AUTH_APPROVAL',
  WaitingErxHubClaimApproval = 'WAITING_ERX_HUB_CLAIM_APPROVAL',
  WaitingPatientConfirmation = 'WAITING_PATIENT_CONFIRMATION'
}

export type OrderFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  checkoutId?: InputMaybe<Scalars['String']>;
  childrenAndFailedOrders?: InputMaybe<Scalars['Boolean']>;
  clinician?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<DateRangeInput>;
  csTicketId?: InputMaybe<Scalars['String']>;
  deliveryDate?: InputMaybe<Scalars['Date']>;
  deliveryStatus?: InputMaybe<Array<InputMaybe<OrderDeliveryStatusEnum>>>;
  deliveryTimeSlot?: InputMaybe<Scalars['ID']>;
  hasHelpDiskTicket?: InputMaybe<Scalars['Boolean']>;
  helpDiskTicketId?: InputMaybe<Scalars['String']>;
  helpDiskTicketId_Icontains?: InputMaybe<Scalars['String']>;
  isDelivery?: InputMaybe<Scalars['Boolean']>;
  isParentFailed?: InputMaybe<Scalars['Boolean']>;
  modified?: InputMaybe<DateRangeInput>;
  nationalId?: InputMaybe<Scalars['String']>;
  numbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orderGlobalSearch?: InputMaybe<Scalars['String']>;
  parentOrders?: InputMaybe<Scalars['Boolean']>;
  prescription?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<IntRangeInput>;
  referenceNumber?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Array<InputMaybe<OrderSourcesEnum>>>;
  status?: InputMaybe<Array<InputMaybe<OrderStatusEnum>>>;
};

export type OrderGetErxHubApproval = {
  __typename?: 'OrderGetErxHubApproval';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderLine = Node & {
  __typename?: 'OrderLine';
  authStatus: OrderLineAuthStatus;
  children?: Maybe<Array<Maybe<OrderLine>>>;
  id: Scalars['ID'];
  isExpired?: Maybe<Scalars['Boolean']>;
  medication?: Maybe<Medication>;
  productName: Scalars['String'];
  productSku: Scalars['String'];
  quantity: Scalars['Int'];
  quantityFulfilled: Scalars['Int'];
  rejectionReason?: Maybe<Scalars['String']>;
  taxRate: Scalars['Float'];
  thumbnail?: Maybe<Image>;
  tradeDrug?: Maybe<TradeDrug>;
  translatedProductName: Scalars['String'];
  translatedVariantName: Scalars['String'];
  unitPrice?: Maybe<TaxedMoney>;
  variant?: Maybe<ProductVariant>;
  variantCode: Scalars['String'];
  variantName: Scalars['String'];
};

export enum OrderLineAuthStatus {
  Approved = 'APPROVED',
  New = 'NEW',
  PartialApproved = 'PARTIAL_APPROVED',
  Rejected = 'REJECTED'
}

export type OrderLineCountableConnection = {
  __typename?: 'OrderLineCountableConnection';
  edges: Array<OrderLineCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OrderLineCountableEdge = {
  __typename?: 'OrderLineCountableEdge';
  cursor: Scalars['String'];
  node: OrderLine;
};

export type OrderPickupFollowupInformation = {
  __typename?: 'OrderPickupFollowupInformation';
  currentAuthorizationId?: Maybe<Scalars['String']>;
  pickedup?: Maybe<Scalars['Boolean']>;
  pickupFollowupWorkflowId?: Maybe<Scalars['String']>;
};

export type OrderRejectionReason = Node & {
  __typename?: 'OrderRejectionReason';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  orders: OrderCountableConnection;
  reason: Scalars['String'];
  type: OrderRejectionReasonType;
};


export type OrderRejectionReasonOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OrderRejectionReasonCountableConnection = {
  __typename?: 'OrderRejectionReasonCountableConnection';
  edges: Array<OrderRejectionReasonCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OrderRejectionReasonCountableEdge = {
  __typename?: 'OrderRejectionReasonCountableEdge';
  cursor: Scalars['String'];
  node: OrderRejectionReason;
};

export type OrderRejectionReasonCreate = {
  __typename?: 'OrderRejectionReasonCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderRejectionReason?: Maybe<OrderRejectionReason>;
  orderRejectionReasonErrors: Array<OrderRejectionReasonError>;
};

export type OrderRejectionReasonDelete = {
  __typename?: 'OrderRejectionReasonDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderRejectionReason?: Maybe<OrderRejectionReason>;
  orderRejectionReasonErrors: Array<OrderRejectionReasonError>;
};

export type OrderRejectionReasonError = {
  __typename?: 'OrderRejectionReasonError';
  code: OrderRejectionReasonErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum OrderRejectionReasonErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type OrderRejectionReasonInput = {
  reason: Scalars['String'];
};

export enum OrderRejectionReasonType {
  Custom = 'CUSTOM',
  System = 'SYSTEM'
}

export type OrderRejectionReasonUpdate = {
  __typename?: 'OrderRejectionReasonUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderRejectionReason?: Maybe<OrderRejectionReason>;
  orderRejectionReasonErrors: Array<OrderRejectionReasonError>;
};

export enum OrderSortField {
  CreationDate = 'CREATION_DATE',
  Customer = 'CUSTOMER',
  FulfillmentStatus = 'FULFILLMENT_STATUS',
  ModifiedDate = 'MODIFIED_DATE',
  Number = 'NUMBER',
  StatusAndCreated = 'STATUS_AND_CREATED',
  Total = 'TOTAL'
}

export type OrderSortingInput = {
  direction: OrderDirection;
  field: OrderSortField;
};

export enum OrderSource {
  Chat = 'CHAT',
  Checkout = 'CHECKOUT',
  CsTicket = 'CS_TICKET',
  PrescriptionDispense = 'PRESCRIPTION_DISPENSE',
  PrescriptionRefill = 'PRESCRIPTION_REFILL'
}

export enum OrderSourcesEnum {
  Chat = 'CHAT',
  Checkout = 'CHECKOUT',
  CsTicket = 'CS_TICKET',
  PrescriptionDispense = 'PRESCRIPTION_DISPENSE',
  PrescriptionRefill = 'PRESCRIPTION_REFILL'
}

export enum OrderStatus {
  AcceptedByProvider = 'ACCEPTED_BY_PROVIDER',
  Allocated = 'ALLOCATED',
  CanceledByConsumer = 'CANCELED_BY_CONSUMER',
  CanceledByProvider = 'CANCELED_BY_PROVIDER',
  ConsumedTimedOut = 'CONSUMED_TIMED_OUT',
  Delivered = 'DELIVERED',
  DeliveredTimedOut = 'DELIVERED_TIMED_OUT',
  Dispensed = 'DISPENSED',
  Draft = 'DRAFT',
  ErxHubAuthRequestFailed = 'ERX_HUB_AUTH_REQUEST_FAILED',
  ErxHubAuthSubmitTimedOut = 'ERX_HUB_AUTH_SUBMIT_TIMED_OUT',
  ErxHubClaimApproved = 'ERX_HUB_CLAIM_APPROVED',
  ErxHubClaimFailed = 'ERX_HUB_CLAIM_FAILED',
  ErxHubClaimRejected = 'ERX_HUB_CLAIM_REJECTED',
  ErxHubClaimSubmitTimedOut = 'ERX_HUB_CLAIM_SUBMIT_TIMED_OUT',
  ErxHubFullyApproved = 'ERX_HUB_FULLY_APPROVED',
  ErxHubPartiallyApproved = 'ERX_HUB_PARTIALLY_APPROVED',
  ErxHubRejected = 'ERX_HUB_REJECTED',
  ErxHubTimedOut = 'ERX_HUB_TIMED_OUT',
  Failed = 'FAILED',
  FailedToDispense = 'FAILED_TO_DISPENSE',
  Fulfilled = 'FULFILLED',
  InProgress = 'IN_PROGRESS',
  NewRequest = 'NEW_REQUEST',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  OutForDeliveryTimedOut = 'OUT_FOR_DELIVERY_TIMED_OUT',
  PartiallyFulfilled = 'PARTIALLY_FULFILLED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentOnArrive = 'PAYMENT_ON_ARRIVE',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentTimedOut = 'PAYMENT_TIMED_OUT',
  ReadyForCustomerPickup = 'READY_FOR_CUSTOMER_PICKUP',
  ReadyForDelivery = 'READY_FOR_DELIVERY',
  RejectedByProvider = 'REJECTED_BY_PROVIDER',
  Returned = 'RETURNED',
  ReturnedTimedOut = 'RETURNED_TIMED_OUT',
  ReturnInProgress = 'RETURN_IN_PROGRESS',
  Unfulfilled = 'UNFULFILLED',
  VendorResponseTimedOut = 'VENDOR_RESPONSE_TIMED_OUT',
  WaitingErxHubApproval = 'WAITING_ERX_HUB_APPROVAL',
  WaitingErxHubClaimApproval = 'WAITING_ERX_HUB_CLAIM_APPROVAL',
  WaitingPatientConfirmation = 'WAITING_PATIENT_CONFIRMATION',
  WaitingProviderSelection = 'WAITING_PROVIDER_SELECTION'
}

export type OrderStatusChange = {
  __typename?: 'OrderStatusChange';
  order?: Maybe<Order>;
};

export enum OrderStatusEnum {
  AcceptedByProvider = 'ACCEPTED_BY_PROVIDER',
  Allocated = 'ALLOCATED',
  CanceledByConsumer = 'CANCELED_BY_CONSUMER',
  CanceledByProvider = 'CANCELED_BY_PROVIDER',
  ConsumedTimedOut = 'CONSUMED_TIMED_OUT',
  Delivered = 'DELIVERED',
  DeliveredTimedOut = 'DELIVERED_TIMED_OUT',
  Dispensed = 'DISPENSED',
  Draft = 'DRAFT',
  ErxHubAuthRequestFailed = 'ERX_HUB_AUTH_REQUEST_FAILED',
  ErxHubAuthSubmitTimedOut = 'ERX_HUB_AUTH_SUBMIT_TIMED_OUT',
  ErxHubClaimApproved = 'ERX_HUB_CLAIM_APPROVED',
  ErxHubClaimFailed = 'ERX_HUB_CLAIM_FAILED',
  ErxHubClaimRejected = 'ERX_HUB_CLAIM_REJECTED',
  ErxHubClaimSubmitTimedOut = 'ERX_HUB_CLAIM_SUBMIT_TIMED_OUT',
  ErxHubFullyApproved = 'ERX_HUB_FULLY_APPROVED',
  ErxHubPartiallyApproved = 'ERX_HUB_PARTIALLY_APPROVED',
  ErxHubRejected = 'ERX_HUB_REJECTED',
  ErxHubTimedOut = 'ERX_HUB_TIMED_OUT',
  Failed = 'FAILED',
  FailedToDispense = 'FAILED_TO_DISPENSE',
  Fulfilled = 'FULFILLED',
  InProgress = 'IN_PROGRESS',
  NewRequest = 'NEW_REQUEST',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  OutForDeliveryTimedOut = 'OUT_FOR_DELIVERY_TIMED_OUT',
  PartiallyFulfilled = 'PARTIALLY_FULFILLED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentOnArrive = 'PAYMENT_ON_ARRIVE',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentTimedOut = 'PAYMENT_TIMED_OUT',
  ReadyForCustomerPickup = 'READY_FOR_CUSTOMER_PICKUP',
  ReadyForDelivery = 'READY_FOR_DELIVERY',
  RejectedByProvider = 'REJECTED_BY_PROVIDER',
  Returned = 'RETURNED',
  ReturnedTimedOut = 'RETURNED_TIMED_OUT',
  ReturnInProgress = 'RETURN_IN_PROGRESS',
  Unfulfilled = 'UNFULFILLED',
  VendorResponseTimedOut = 'VENDOR_RESPONSE_TIMED_OUT',
  WaitingErxHubApproval = 'WAITING_ERX_HUB_APPROVAL',
  WaitingErxHubClaimApproval = 'WAITING_ERX_HUB_CLAIM_APPROVAL',
  WaitingPatientConfirmation = 'WAITING_PATIENT_CONFIRMATION',
  WaitingProviderSelection = 'WAITING_PROVIDER_SELECTION'
}

export type OrderStatusForceUpdate = {
  __typename?: 'OrderStatusForceUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderStatusUpdate = {
  __typename?: 'OrderStatusUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderStatusUpdateInput = {
  failReason?: InputMaybe<Scalars['String']>;
  helpDiskTicketId?: InputMaybe<Scalars['String']>;
  medicationsStatuses?: InputMaybe<Array<InputMaybe<MedicationAuthorizationStatusInput>>>;
  status: OrderStatusEnum;
};

export enum OrderTypesEnum {
  HomeDelivery = 'HOME_DELIVERY',
  PickupFromPharmacy = 'PICKUP_FROM_PHARMACY'
}

export type P_SubscriptionEntityChanged = H_Node & {
  __typename?: 'P_SubscriptionEntityChanged';
  changes?: Maybe<Array<Maybe<P_SubscriptionEntityFieldChanges>>>;
  creationDate?: Maybe<Scalars['Instant']>;
  graphqlType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type P_SubscriptionEntityFieldChanges = {
  __typename?: 'P_SubscriptionEntityFieldChanges';
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type P_SubscriptionKafkaTopic = {
  __typename?: 'P_SubscriptionKafkaTopic';
  graphqlType?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PasswordChange = {
  __typename?: 'PasswordChange';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type Patient = Node & {
  __typename?: 'Patient';
  bloodGroup?: Maybe<Scalars['String']>;
  contactNumber: Scalars['String'];
  created: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  eHealthId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyPhoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idType?: Maybe<Scalars['String']>;
  insuranceCards?: Maybe<Array<Maybe<PatientInsuranceCardDetails>>>;
  lastName?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<PatientMaritalStatus>;
  medicalHistory?: Maybe<PatientMedicalHistory>;
  memberId?: Maybe<Scalars['String']>;
  modified: Scalars['DateTime'];
  nationalIdNumber: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  preferredLanguage?: Maybe<Scalars['String']>;
  residencyType?: Maybe<PatientResidencyType>;
  secondName?: Maybe<Scalars['String']>;
  thirdName?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};


export type PatientPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<PaymentOperationLogSortingInput>;
};


export type PatientPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PatientCountableConnection = {
  __typename?: 'PatientCountableConnection';
  edges: Array<PatientCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PatientCountableEdge = {
  __typename?: 'PatientCountableEdge';
  cursor: Scalars['String'];
  node: Patient;
};

export type PatientCreate = {
  __typename?: 'PatientCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patient?: Maybe<Patient>;
  patientErrors: Array<PatientError>;
};

export type PatientDisability = Node & {
  __typename?: 'PatientDisability';
  code: Scalars['String'];
  id: Scalars['ID'];
};

export type PatientError = {
  __typename?: 'PatientError';
  code: PatientErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum PatientErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type PatientFilterInput = {
  bloodGroup?: InputMaybe<Scalars['String']>;
  bloodGroup_Icontains?: InputMaybe<Scalars['String']>;
  contactNumber?: InputMaybe<Scalars['String']>;
  contactNumber_Icontains?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  eHealthId?: InputMaybe<Scalars['String']>;
  eHealthId_Icontains?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  emergencyPhoneNumber?: InputMaybe<Scalars['String']>;
  emergencyPhoneNumber_Icontains?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  gender_Icontains?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  idType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_Icontains?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  maritalStatus?: InputMaybe<Array<InputMaybe<MaritalStatusesEnum>>>;
  memberId?: InputMaybe<Scalars['String']>;
  memberId_Icontains?: InputMaybe<Scalars['String']>;
  nationalIdNumber?: InputMaybe<Scalars['String']>;
  nationalIdNumber_Icontains?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  nationality_Icontains?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<Scalars['String']>;
  preferredLanguage_Icontains?: InputMaybe<Scalars['String']>;
  residencyType?: InputMaybe<Array<InputMaybe<ResidencyTypesEnum>>>;
  secondName?: InputMaybe<Scalars['String']>;
  secondName_Icontains?: InputMaybe<Scalars['String']>;
  thirdName?: InputMaybe<Scalars['String']>;
  thirdName_Icontains?: InputMaybe<Scalars['String']>;
};

export enum PatientHistoryErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type PatientInput = {
  bloodGroup?: InputMaybe<Scalars['String']>;
  contactNumber: Scalars['String'];
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  eHealthId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyPhoneNumber?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  idType?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  maritalStatus?: InputMaybe<MaritalStatusesEnum>;
  memberId?: InputMaybe<Scalars['String']>;
  nationalIdNumber: Scalars['String'];
  nationality?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<Scalars['String']>;
  residencyType?: InputMaybe<ResidencyTypesEnum>;
  secondName?: InputMaybe<Scalars['String']>;
  thirdName?: InputMaybe<Scalars['String']>;
};

export type PatientInsuranceCardDelete = {
  __typename?: 'PatientInsuranceCardDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patientErrors: Array<PatientError>;
  patientInsuranceCardDetails?: Maybe<PatientInsuranceCardDetails>;
};

export type PatientInsuranceCardDetails = Node & {
  __typename?: 'PatientInsuranceCardDetails';
  cardBackSideImage?: Maybe<Scalars['String']>;
  cardFrontSideImage?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  created: Scalars['DateTime'];
  expiryDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  memberId: Scalars['String'];
  modified: Scalars['DateTime'];
  networkName?: Maybe<Scalars['String']>;
  patient: Patient;
};

export type PatientInsuranceCardUpload = {
  __typename?: 'PatientInsuranceCardUpload';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patientErrors: Array<PatientError>;
  patientInsuranceCardDetails?: Maybe<PatientInsuranceCardDetails>;
};

export type PatientInsuranceCardUploadInput = {
  cardBackSideImage?: InputMaybe<Scalars['String']>;
  cardFrontSideImage?: InputMaybe<Scalars['String']>;
  companyName: Scalars['String'];
  expiryDate?: InputMaybe<Scalars['Date']>;
  memberId: Scalars['String'];
  networkName?: InputMaybe<Scalars['String']>;
};

export enum PatientMaritalStatus {
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export type PatientMedicalHistory = Node & {
  __typename?: 'PatientMedicalHistory';
  allergyType?: Maybe<PatientMedicalHistoryAllergyType>;
  brotherBleedingDisorder: Scalars['Boolean'];
  brotherBloodClots: Scalars['Boolean'];
  brotherCancer: Scalars['Boolean'];
  brotherDiabetes: Scalars['Boolean'];
  brotherHeartDisease: Scalars['Boolean'];
  brotherHighBloodPressure: Scalars['Boolean'];
  brotherHighCholesterol: Scalars['Boolean'];
  created: Scalars['DateTime'];
  dailyQuantity: Scalars['Int'];
  disabilities?: Maybe<Array<Maybe<PatientDisability>>>;
  drinkAlcohol: PatientMedicalHistoryDrinkAlcohol;
  drinkType: Scalars['String'];
  drugUse: PatientMedicalHistoryDrugUse;
  everHospitalized: Scalars['Boolean'];
  everOperatedOn: Scalars['Boolean'];
  everPregnant: Scalars['Boolean'];
  everReceivedCare: Scalars['Boolean'];
  fatherBleedingDisorder: Scalars['Boolean'];
  fatherBloodClots: Scalars['Boolean'];
  fatherCancer: Scalars['Boolean'];
  fatherDiabetes: Scalars['Boolean'];
  fatherHeartDisease: Scalars['Boolean'];
  fatherHighBloodPressure: Scalars['Boolean'];
  fatherHighCholesterol: Scalars['Boolean'];
  gfatherBleedingDisorder: Scalars['Boolean'];
  gfatherBloodClots: Scalars['Boolean'];
  gfatherCancer: Scalars['Boolean'];
  gfatherDiabetes: Scalars['Boolean'];
  gfatherHeartDisease: Scalars['Boolean'];
  gfatherHighBloodPressure: Scalars['Boolean'];
  gfatherHighCholesterol: Scalars['Boolean'];
  gmotherBleedingDisorder: Scalars['Boolean'];
  gmotherBloodClots: Scalars['Boolean'];
  gmotherCancer: Scalars['Boolean'];
  gmotherDiabetes: Scalars['Boolean'];
  gmotherHeartDisease: Scalars['Boolean'];
  gmotherHighBloodPressure: Scalars['Boolean'];
  gmotherHighCholesterol: Scalars['Boolean'];
  hasAllergies: Scalars['Boolean'];
  hasAnemia: Scalars['Boolean'];
  hasAnyMedicalProblem: Scalars['Boolean'];
  hasArrhythmia: Scalars['Boolean'];
  hasAsthma: Scalars['Boolean'];
  hasBirthDefects: Scalars['Boolean'];
  hasCancer: Scalars['Boolean'];
  hasClottingDisorder: Scalars['Boolean'];
  hasDiabetes: Scalars['Boolean'];
  hasEpilepsy: Scalars['Boolean'];
  hasHeartDisease: Scalars['Boolean'];
  hasHepatitisB: Scalars['Boolean'];
  hasHighBloodPressure: Scalars['Boolean'];
  hasHighCholesterol: Scalars['Boolean'];
  hasKidneyDisease: Scalars['Boolean'];
  hasLiverDisease: Scalars['Boolean'];
  hasOperationComplications: Scalars['Boolean'];
  hasOtherDisease: Scalars['Boolean'];
  hasStroke: Scalars['Boolean'];
  hasThyroidDisease: Scalars['Boolean'];
  hasTia: Scalars['Boolean'];
  id: Scalars['ID'];
  medicationDose: Scalars['Int'];
  medicationFreq: Scalars['Int'];
  modified: Scalars['DateTime'];
  motherBleedingDisorder: Scalars['Boolean'];
  motherBloodClots: Scalars['Boolean'];
  motherCancer: Scalars['Boolean'];
  motherDiabetes: Scalars['Boolean'];
  motherHeartDisease: Scalars['Boolean'];
  motherHighBloodPressure: Scalars['Boolean'];
  motherHighCholesterol: Scalars['Boolean'];
  noYearsSmoked: Scalars['Int'];
  operationYear: Scalars['Int'];
  packsNo?: Maybe<PatientMedicalHistoryPacksNo>;
  patient?: Maybe<Patient>;
  pregnancyCount: Scalars['Int'];
  pregnancyOutcome?: Maybe<PatientMedicalHistoryPregnancyOutcome>;
  reactionType?: Maybe<PatientMedicalHistoryReactionType>;
  sisterBleedingDisorder: Scalars['Boolean'];
  sisterBloodClots: Scalars['Boolean'];
  sisterCancer: Scalars['Boolean'];
  sisterDiabetes: Scalars['Boolean'];
  sisterHeartDisease: Scalars['Boolean'];
  sisterHighBloodPressure: Scalars['Boolean'];
  sisterHighCholesterol: Scalars['Boolean'];
  smokingFreq?: Maybe<PatientMedicalHistorySmokingFreq>;
  takeNonTraditionalMedications: Scalars['Boolean'];
  takePrescriptionMedicines: Scalars['Boolean'];
  wasCareContinuous: Scalars['Boolean'];
};

export enum PatientMedicalHistoryAllergyType {
  Drug = 'DRUG',
  Food = 'FOOD',
  Insect = 'INSECT',
  Latex = 'LATEX',
  Mold = 'MOLD',
  Pet = 'PET',
  Pollen = 'POLLEN'
}

export type PatientMedicalHistoryCreate = {
  __typename?: 'PatientMedicalHistoryCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patientHistoryErrors: Array<PatientMedicalHistoryError>;
  patientMedicalHistory?: Maybe<PatientMedicalHistory>;
};

export type PatientMedicalHistoryDelete = {
  __typename?: 'PatientMedicalHistoryDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patientHistoryErrors: Array<PatientMedicalHistoryError>;
  patientMedicalHistory?: Maybe<PatientMedicalHistory>;
};

export enum PatientMedicalHistoryDrinkAlcohol {
  CurrentEveryday = 'CURRENT_EVERYDAY',
  CurrentSomeday = 'CURRENT_SOMEDAY',
  Former = 'FORMER',
  Never = 'NEVER',
  Unkown = 'UNKOWN'
}

export enum PatientMedicalHistoryDrugUse {
  CurrentEveryday = 'CURRENT_EVERYDAY',
  CurrentSomeday = 'CURRENT_SOMEDAY',
  Former = 'FORMER',
  Never = 'NEVER',
  Unkown = 'UNKOWN'
}

export type PatientMedicalHistoryError = {
  __typename?: 'PatientMedicalHistoryError';
  code: PatientHistoryErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type PatientMedicalHistoryInput = {
  allergyType?: InputMaybe<Scalars['String']>;
  brotherBleedingDisorder?: InputMaybe<Scalars['Boolean']>;
  brotherBloodClots?: InputMaybe<Scalars['Boolean']>;
  brotherCancer?: InputMaybe<Scalars['Boolean']>;
  brotherDiabetes?: InputMaybe<Scalars['Boolean']>;
  brotherHeartDisease?: InputMaybe<Scalars['Boolean']>;
  brotherHighBloodPressure?: InputMaybe<Scalars['Boolean']>;
  brotherHighCholesterol?: InputMaybe<Scalars['Boolean']>;
  dailyQuantity?: InputMaybe<Scalars['Int']>;
  drinkAlcohol?: InputMaybe<Scalars['String']>;
  drinkType?: InputMaybe<Scalars['String']>;
  drugUse?: InputMaybe<Scalars['String']>;
  everHospitalized?: InputMaybe<Scalars['Boolean']>;
  everOperatedOn?: InputMaybe<Scalars['Boolean']>;
  everPregnant?: InputMaybe<Scalars['Boolean']>;
  everReceivedCare?: InputMaybe<Scalars['Boolean']>;
  fatherBleedingDisorder?: InputMaybe<Scalars['Boolean']>;
  fatherBloodClots?: InputMaybe<Scalars['Boolean']>;
  fatherCancer?: InputMaybe<Scalars['Boolean']>;
  fatherDiabetes?: InputMaybe<Scalars['Boolean']>;
  fatherHeartDisease?: InputMaybe<Scalars['Boolean']>;
  fatherHighBloodPressure?: InputMaybe<Scalars['Boolean']>;
  fatherHighCholesterol?: InputMaybe<Scalars['Boolean']>;
  gfatherBleedingDisorder?: InputMaybe<Scalars['Boolean']>;
  gfatherBloodClots?: InputMaybe<Scalars['Boolean']>;
  gfatherCancer?: InputMaybe<Scalars['Boolean']>;
  gfatherDiabetes?: InputMaybe<Scalars['Boolean']>;
  gfatherHeartDisease?: InputMaybe<Scalars['Boolean']>;
  gfatherHighBloodPressure?: InputMaybe<Scalars['Boolean']>;
  gfatherHighCholesterol?: InputMaybe<Scalars['Boolean']>;
  gmotherBleedingDisorder?: InputMaybe<Scalars['Boolean']>;
  gmotherBloodClots?: InputMaybe<Scalars['Boolean']>;
  gmotherCancer?: InputMaybe<Scalars['Boolean']>;
  gmotherDiabetes?: InputMaybe<Scalars['Boolean']>;
  gmotherHeartDisease?: InputMaybe<Scalars['Boolean']>;
  gmotherHighBloodPressure?: InputMaybe<Scalars['Boolean']>;
  gmotherHighCholesterol?: InputMaybe<Scalars['Boolean']>;
  hasAllergies?: InputMaybe<Scalars['Boolean']>;
  hasAnemia?: InputMaybe<Scalars['Boolean']>;
  hasAnyMedicalProblem?: InputMaybe<Scalars['Boolean']>;
  hasArrhythmia?: InputMaybe<Scalars['Boolean']>;
  hasAsthma?: InputMaybe<Scalars['Boolean']>;
  hasBirthDefects?: InputMaybe<Scalars['Boolean']>;
  hasCancer?: InputMaybe<Scalars['Boolean']>;
  hasClottingDisorder?: InputMaybe<Scalars['Boolean']>;
  hasDiabetes?: InputMaybe<Scalars['Boolean']>;
  hasEpilepsy?: InputMaybe<Scalars['Boolean']>;
  hasHeartDisease?: InputMaybe<Scalars['Boolean']>;
  hasHepatitisB?: InputMaybe<Scalars['Boolean']>;
  hasHighBloodPressure?: InputMaybe<Scalars['Boolean']>;
  hasHighCholesterol?: InputMaybe<Scalars['Boolean']>;
  hasKidneyDisease?: InputMaybe<Scalars['Boolean']>;
  hasLiverDisease?: InputMaybe<Scalars['Boolean']>;
  hasOperationComplications?: InputMaybe<Scalars['Boolean']>;
  hasOtherDisease?: InputMaybe<Scalars['Boolean']>;
  hasStroke?: InputMaybe<Scalars['Boolean']>;
  hasThyroidDisease?: InputMaybe<Scalars['Boolean']>;
  hasTia?: InputMaybe<Scalars['Boolean']>;
  medicationDose?: InputMaybe<Scalars['Int']>;
  medicationFreq?: InputMaybe<Scalars['Int']>;
  motherBleedingDisorder?: InputMaybe<Scalars['Boolean']>;
  motherBloodClots?: InputMaybe<Scalars['Boolean']>;
  motherCancer?: InputMaybe<Scalars['Boolean']>;
  motherDiabetes?: InputMaybe<Scalars['Boolean']>;
  motherHeartDisease?: InputMaybe<Scalars['Boolean']>;
  motherHighBloodPressure?: InputMaybe<Scalars['Boolean']>;
  motherHighCholesterol?: InputMaybe<Scalars['Boolean']>;
  noYearsSmoked?: InputMaybe<Scalars['Int']>;
  operationYear?: InputMaybe<Scalars['Int']>;
  packsNo?: InputMaybe<Scalars['String']>;
  pregnancyCount?: InputMaybe<Scalars['Int']>;
  reactionType?: InputMaybe<Scalars['String']>;
  sisterBleedingDisorder?: InputMaybe<Scalars['Boolean']>;
  sisterBloodClots?: InputMaybe<Scalars['Boolean']>;
  sisterCancer?: InputMaybe<Scalars['Boolean']>;
  sisterDiabetes?: InputMaybe<Scalars['Boolean']>;
  sisterHeartDisease?: InputMaybe<Scalars['Boolean']>;
  sisterHighBloodPressure?: InputMaybe<Scalars['Boolean']>;
  sisterHighCholesterol?: InputMaybe<Scalars['Boolean']>;
  smokingFreq?: InputMaybe<Scalars['String']>;
  takeNonTraditionalMedications?: InputMaybe<Scalars['Boolean']>;
  takePrescriptionMedicines?: InputMaybe<Scalars['Boolean']>;
  wasCareContinuous?: InputMaybe<Scalars['Boolean']>;
};

export enum PatientMedicalHistoryPacksNo {
  HalfPack = 'HALF_PACK',
  LessHalfPack = 'LESS_HALF_PACK',
  OneAndHalfPack = 'ONE_AND_HALF_PACK',
  OnePack = 'ONE_PACK',
  ThreePacks = 'THREE_PACKS',
  TwoPacks = 'TWO_PACKS'
}

export enum PatientMedicalHistoryPregnancyOutcome {
  Abortions = 'ABORTIONS',
  FullTerm = 'FULL_TERM',
  Miscarriages = 'MISCARRIAGES',
  Preterm = 'PRETERM'
}

export enum PatientMedicalHistoryReactionType {
  Anaphylaxis = 'ANAPHYLAXIS',
  Itching = 'ITCHING',
  Rash = 'RASH'
}

export enum PatientMedicalHistorySmokingFreq {
  CurrentEveryday = 'CURRENT_EVERYDAY',
  CurrentSomeday = 'CURRENT_SOMEDAY',
  Former = 'FORMER',
  Never = 'NEVER',
  Unkown = 'UNKOWN'
}

export type PatientMedicalHistoryUpdate = {
  __typename?: 'PatientMedicalHistoryUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patientHistoryErrors: Array<PatientMedicalHistoryError>;
  patientMedicalHistory?: Maybe<PatientMedicalHistory>;
};

export enum PatientResidencyType {
  Residence = 'RESIDENCE',
  Visitor = 'VISITOR'
}

export type PatientSendOrderOtp = {
  __typename?: 'PatientSendOrderOTP';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  ok?: Maybe<Scalars['Boolean']>;
  patientErrors: Array<PatientError>;
};

export type PatientSendOrderOtpInput = {
  branchId: Scalars['ID'];
  orderId: Scalars['ID'];
  orderOtp: Scalars['String'];
  patientId: Scalars['ID'];
  url: Scalars['String'];
};

export enum PaymentBrand {
  Mada = 'MADA',
  Master = 'MASTER',
  Visa = 'VISA'
}

export type PaymentCheckoutInput = {
  amountSourceId: Scalars['String'];
  amountSourceType: AmountSourceType;
};

export type PaymentCheckoutResponse = {
  __typename?: 'PaymentCheckoutResponse';
  buildNumber?: Maybe<Scalars['String']>;
  checkoutPaymentFormUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  merchantTransactionId?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['H_Long']>;
  patientId?: Maybe<Scalars['H_Long']>;
  paymentStatus?: Maybe<OperationStatus>;
  paymentType?: Maybe<Scalars['String']>;
  result?: Maybe<PaymentResponseResult>;
  shopperResultUrl?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['H_Long']>;
  vendorId?: Maybe<Scalars['H_Long']>;
};

export type PaymentClearanceFromMultipleSourcesInput = {
  amountSourceId: Scalars['String'];
  amountSourceType: AmountSourceType;
};

export type PaymentClearanceInput = {
  amountSourceId: Scalars['String'];
  amountSourceType: AmountSourceType;
  deductedPaymentReceiptIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  patientId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export enum PaymentErrorCodes {
  InvalidOrExpiredToken = 'INVALID_OR_EXPIRED_TOKEN',
  PaymentInvalidSecurityCodeError = 'PAYMENT_INVALID_SECURITY_CODE_ERROR',
  PaymentOwnershipError = 'PAYMENT_OWNERSHIP_ERROR',
  PaymentPermissionDenied = 'PAYMENT_PERMISSION_DENIED',
  PaymentProcessError = 'PAYMENT_PROCESS_ERROR',
  PaymentUnknown = 'PAYMENT_UNKNOWN',
  PaymentUnknownType = 'PAYMENT_UNKNOWN_TYPE'
}

export type PaymentGraphqlError = {
  __typename?: 'PaymentGraphqlError';
  code?: Maybe<PaymentErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum PaymentMethod {
  Cash = 'CASH',
  Cc = 'CC',
  PaymentCard = 'PAYMENT_CARD'
}

export enum PaymentOperation {
  Confirm = 'CONFIRM',
  Refund = 'REFUND',
  Reverse = 'REVERSE'
}

export type PaymentOperationLog = {
  __typename?: 'PaymentOperationLog';
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Instant']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  operationStatus?: Maybe<OperationStatus>;
  operationType?: Maybe<OperationType>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentId?: Maybe<Scalars['String']>;
  refundId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type PaymentOperationLogCountableConnection = {
  __typename?: 'PaymentOperationLogCountableConnection';
  edges: Array<PaymentOperationLogCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaymentOperationLogCountableEdge = {
  __typename?: 'PaymentOperationLogCountableEdge';
  cursor: Scalars['String'];
  node: PaymentOperationLog;
};

export type PaymentOperationLogFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  operationStatuses?: InputMaybe<Array<InputMaybe<OperationStatus>>>;
  operationTypes?: InputMaybe<Array<InputMaybe<OperationType>>>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  paymentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  refundIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export enum PaymentOperationLogSortField {
  Created = 'CREATED'
}

export type PaymentOperationLogSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: H_OrderDirection;
  field: PaymentOperationLogSortField;
};

export type PaymentParameter = {
  __typename?: 'PaymentParameter';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PaymentReceipt = {
  __typename?: 'PaymentReceipt';
  amountRefundedByHyperpay?: Maybe<Scalars['Float']>;
  benefitedFrom?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Instant']>;
  id: Scalars['ID'];
  lastModifiedBy?: Maybe<Scalars['String']>;
  lastModifiedDate?: Maybe<Scalars['Instant']>;
  lastPaymentAmount?: Maybe<Scalars['Float']>;
  lastPaymentId?: Maybe<Scalars['String']>;
  lastPaymentType?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['H_Long']>;
  patient?: Maybe<Patient>;
  paymentBrand?: Maybe<PaymentBrand>;
  paymentId?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentType?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
  vendor?: Maybe<Vendor>;
};

export type PaymentReceiptCountableConnection = {
  __typename?: 'PaymentReceiptCountableConnection';
  edges: Array<PaymentReceiptCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaymentReceiptCountableEdge = {
  __typename?: 'PaymentReceiptCountableEdge';
  cursor: Scalars['String'];
  node: PaymentReceipt;
};

export type PaymentReceiptFilterInput = {
  benefitedFrom?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  paymentBrands?: InputMaybe<Array<InputMaybe<PaymentBrand>>>;
  paymentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  paymentMethods?: InputMaybe<Array<InputMaybe<PaymentMethod>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type PaymentReceiptInput = {
  paymentMethod: PaymentMethod;
};

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  amount?: Maybe<Scalars['Float']>;
  buildNumber?: Maybe<Scalars['String']>;
  card?: Maybe<PaymentResponseCard>;
  currency?: Maybe<Scalars['String']>;
  customParameters?: Maybe<Scalars['DictType']>;
  descriptor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  merchantTransactionId?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['H_Long']>;
  patientId?: Maybe<Scalars['H_Long']>;
  paymentBrand?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentReceiptId?: Maybe<Scalars['H_Long']>;
  paymentType?: Maybe<Scalars['String']>;
  recurringType?: Maybe<Scalars['String']>;
  redirect?: Maybe<PaymentResponseRedirect>;
  referencedId?: Maybe<Scalars['String']>;
  registrationId?: Maybe<Scalars['String']>;
  result?: Maybe<PaymentResponseResult>;
  resultDetails?: Maybe<PaymentResponseResultDetails>;
  risk?: Maybe<PaymentResponseRisk>;
  shopperResultUrl?: Maybe<Scalars['String']>;
  standingInstruction?: Maybe<Scalars['DictType']>;
  timestamp?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['H_Long']>;
  vendorId?: Maybe<Scalars['H_Long']>;
};

export type PaymentResponseCard = {
  __typename?: 'PaymentResponseCard';
  bin?: Maybe<Scalars['String']>;
  binCountry?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  expiryMonth?: Maybe<Scalars['String']>;
  expiryYear?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  issuer?: Maybe<CardIssuer>;
  last4Digits?: Maybe<Scalars['String']>;
  maxPanLength?: Maybe<Scalars['String']>;
  regulatedFlag?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type PaymentResponseRedirect = {
  __typename?: 'PaymentResponseRedirect';
  parameters?: Maybe<Array<Maybe<PaymentParameter>>>;
  url?: Maybe<Scalars['String']>;
};

export type PaymentResponseResult = {
  __typename?: 'PaymentResponseResult';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PaymentResponseResultDetails = {
  __typename?: 'PaymentResponseResultDetails';
  clearingInstituteName?: Maybe<Scalars['String']>;
};

export type PaymentResponseRisk = {
  __typename?: 'PaymentResponseRisk';
  score?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentUnderProcessing = 'PAYMENT_UNDER_PROCESSING',
  Refunded = 'REFUNDED',
  RefundedFailed = 'REFUNDED_FAILED',
  RefundedUnderProcessing = 'REFUNDED_UNDER_PROCESSING',
  UnPaid = 'UN_PAID'
}

export enum PaymentType {
  Cd = 'CD',
  Cp = 'CP',
  Db = 'DB',
  Pa = 'PA',
  Rf = 'RF',
  Rv = 'RV'
}

export type PaymentUpdateInput = {
  /**
   * the amount value is only effective for the case of partial (not full) refund,
   * and the amount value should be within the available value for refund
   */
  amount?: InputMaybe<Scalars['Float']>;
  amountSourceId: Scalars['String'];
  amountSourceType: AmountSourceType;
  paymentOperation: PaymentOperation;
};

export type Permission = {
  __typename?: 'Permission';
  code: PermissionEnum;
  name: Scalars['String'];
};

export enum PermissionEnum {
  AssignPrescriptionOrderToVendors = 'ASSIGN_PRESCRIPTION_ORDER_TO_VENDORS',
  ConvertVirtualOrderToRealOne = 'CONVERT_VIRTUAL_ORDER_TO_REAL_ONE',
  ManageBlocks = 'MANAGE_BLOCKS',
  ManageBranches = 'MANAGE_BRANCHES',
  ManageChat = 'MANAGE_CHAT',
  ManageCheckouts = 'MANAGE_CHECKOUTS',
  ManageCities = 'MANAGE_CITIES',
  ManageCodeSystemEditorReviewers = 'MANAGE_CODE_SYSTEM_EDITOR_REVIEWERS',
  ManageCodeSystemLists = 'MANAGE_CODE_SYSTEM_LISTS',
  ManageCodeSystemReviewers = 'MANAGE_CODE_SYSTEM_REVIEWERS',
  ManageDeliveryTimeSlots = 'MANAGE_DELIVERY_TIME_SLOTS',
  ManageDiscounts = 'MANAGE_DISCOUNTS',
  ManageEarlyRefillReasons = 'MANAGE_EARLY_REFILL_REASONS',
  ManageInvoices = 'MANAGE_INVOICES',
  ManageMedicalDeliveryRequests = 'MANAGE_MEDICAL_DELIVERY_REQUESTS',
  ManageOrders = 'MANAGE_ORDERS',
  ManagePatients = 'MANAGE_PATIENTS',
  ManagePatientMedicalHistory = 'MANAGE_PATIENT_MEDICAL_HISTORY',
  ManagePayments = 'MANAGE_PAYMENTS',
  ManagePharmacyCredentials = 'MANAGE_PHARMACY_CREDENTIALS',
  ManagePlugins = 'MANAGE_PLUGINS',
  ManagePrescriptions = 'MANAGE_PRESCRIPTIONS',
  ManagePricingRules = 'MANAGE_PRICING_RULES',
  ManageProducts = 'MANAGE_PRODUCTS',
  ManageProductStocks = 'MANAGE_PRODUCT_STOCKS',
  ManageRejectionReasons = 'MANAGE_REJECTION_REASONS',
  ManageRuleEngineRules = 'MANAGE_RULE_ENGINE_RULES',
  ManageSettings = 'MANAGE_SETTINGS',
  ManageShipping = 'MANAGE_SHIPPING',
  ManageStaff = 'MANAGE_STAFF',
  ManageSubscriptions = 'MANAGE_SUBSCRIPTIONS',
  ManageTranslations = 'MANAGE_TRANSLATIONS',
  ManageUsers = 'MANAGE_USERS',
  ManageVendors = 'MANAGE_VENDORS',
  ManageWallets = 'MANAGE_WALLETS',
  PublishCodeSystem = 'PUBLISH_CODE_SYSTEM',
  UploadCodeSystemFile = 'UPLOAD_CODE_SYSTEM_FILE',
  ViewCustomers = 'VIEW_CUSTOMERS',
  WorkflowManageOrders = 'WORKFLOW_MANAGE_ORDERS'
}

export type PermissionGroupCreate = {
  __typename?: 'PermissionGroupCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  group?: Maybe<Group>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export type PermissionGroupCreateInput = {
  addPermissions?: InputMaybe<Array<PermissionEnum>>;
  addUsers?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
};

export type PermissionGroupDelete = {
  __typename?: 'PermissionGroupDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  group?: Maybe<Group>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export type PermissionGroupError = {
  __typename?: 'PermissionGroupError';
  code: PermissionGroupErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<PermissionEnum>>;
  users?: Maybe<Array<Scalars['ID']>>;
};

export enum PermissionGroupErrorCode {
  AssignNonStaffMember = 'ASSIGN_NON_STAFF_MEMBER',
  CannotRemoveFromLastGroup = 'CANNOT_REMOVE_FROM_LAST_GROUP',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  LeftNotManageablePermission = 'LEFT_NOT_MANAGEABLE_PERMISSION',
  OutOfScopePermission = 'OUT_OF_SCOPE_PERMISSION',
  OutOfScopeUser = 'OUT_OF_SCOPE_USER',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type PermissionGroupFilterInput = {
  search?: InputMaybe<Scalars['String']>;
};

export enum PermissionGroupSortField {
  Name = 'NAME'
}

export type PermissionGroupSortingInput = {
  direction: OrderDirection;
  field: PermissionGroupSortField;
};

export type PermissionGroupUpdate = {
  __typename?: 'PermissionGroupUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  group?: Maybe<Group>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export type PermissionGroupUpdateInput = {
  addPermissions?: InputMaybe<Array<PermissionEnum>>;
  addUsers?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  removePermissions?: InputMaybe<Array<PermissionEnum>>;
  removeUsers?: InputMaybe<Array<Scalars['ID']>>;
};

export type PharmacyCredentials = {
  __typename?: 'PharmacyCredentials';
  branch?: Maybe<Branch>;
  errors?: Maybe<Array<Maybe<IntegrationGraphqlError>>>;
  userName?: Maybe<Scalars['String']>;
};

export type PharmacyCredentialsInput = {
  branch: Scalars['ID'];
  password: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type PickupFollowupInformation = {
  __typename?: 'PickupFollowupInformation';
  allocateOrderId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Instant']>;
  currentAuthorizationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderReferenceNumber?: Maybe<Scalars['String']>;
  pickedup?: Maybe<Scalars['Boolean']>;
  pickupFollowupWorkflowId?: Maybe<Scalars['String']>;
};

export type Plan = Node & {
  __typename?: 'Plan';
  created: Scalars['DateTime'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fixedCost?: Maybe<Money>;
  fixedCostAmount: Scalars['Float'];
  fixedOrderCost?: Maybe<Money>;
  fixedOrderCostAmount: Scalars['Float'];
  fixedOrderPercentage: Scalars['Float'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isEditable?: Maybe<Scalars['Boolean']>;
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  period: PlanPeriod;
  subscriptions: VendorSubscriptionCountableConnection;
  validFrom: Scalars['DateTime'];
  validTill?: Maybe<Scalars['DateTime']>;
};


export type PlanSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export enum PlanCode {
  FreeDelivery = 'FREE_DELIVERY'
}

export type PlanCountableConnection = {
  __typename?: 'PlanCountableConnection';
  edges: Array<PlanCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PlanCountableEdge = {
  __typename?: 'PlanCountableEdge';
  cursor: Scalars['String'];
  node: Plan;
};

export type PlanCreate = {
  __typename?: 'PlanCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  plan?: Maybe<Plan>;
  subscriptionErrors: Array<SubscriptionError>;
};

export type PlanFilterInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
};

export type PlanInput = {
  description?: InputMaybe<Scalars['String']>;
  fixedCostAmount?: InputMaybe<Scalars['Decimal']>;
  fixedOrderCostAmount?: InputMaybe<Scalars['Decimal']>;
  fixedOrderPercentage?: InputMaybe<Scalars['Decimal']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Period>;
  validFrom?: InputMaybe<Scalars['DateTime']>;
  validTill?: InputMaybe<Scalars['DateTime']>;
};

export type PlanOrder = {
  direction: OrderDirection;
  field?: InputMaybe<PlanOrderField>;
};

export enum PlanOrderField {
  Name = 'NAME'
}

export enum PlanPeriod {
  HalfYear = 'HALF_YEAR',
  Monthly = 'MONTHLY',
  ThreeMonths = 'THREE_MONTHS',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type PlanUpdate = {
  __typename?: 'PlanUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  plan?: Maybe<Plan>;
  subscriptionErrors: Array<SubscriptionError>;
};

export type Plugin = Node & {
  __typename?: 'Plugin';
  active: Scalars['Boolean'];
  configuration?: Maybe<Array<Maybe<ConfigurationItem>>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PluginCountableConnection = {
  __typename?: 'PluginCountableConnection';
  edges: Array<PluginCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PluginCountableEdge = {
  __typename?: 'PluginCountableEdge';
  cursor: Scalars['String'];
  node: Plugin;
};

export type PluginError = {
  __typename?: 'PluginError';
  code: PluginErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum PluginErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  PluginMisconfigured = 'PLUGIN_MISCONFIGURED',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type PluginFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};

export enum PluginSortField {
  IsActive = 'IS_ACTIVE',
  Name = 'NAME'
}

export type PluginSortingInput = {
  direction: OrderDirection;
  field: PluginSortField;
};

export type PluginUpdate = {
  __typename?: 'PluginUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  plugin?: Maybe<Plugin>;
  pluginsErrors: Array<PluginError>;
};

export type PluginUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  configuration?: InputMaybe<Array<InputMaybe<ConfigurationItemInput>>>;
};

export type Prescription = Node & {
  __typename?: 'Prescription';
  activeOrders?: Maybe<Array<Maybe<Order>>>;
  clinician?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  diagnosesHash?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Array<Maybe<Diagnose>>>;
  dispenseCount: Scalars['Int'];
  dispenseMethod?: Maybe<PrescriptionDispenseMethodEnum>;
  dispenseStatus: PrescriptionDispenseStatus;
  earlyRefillRequests?: Maybe<EarlyRefillRequestCountableConnection>;
  id: Scalars['ID'];
  isDispensed: Scalars['Boolean'];
  isFreeDelivery?: Maybe<Scalars['Boolean']>;
  lastDispenseDate?: Maybe<Scalars['DateTime']>;
  medicalAlerts?: Maybe<Array<Maybe<Scalars['String']>>>;
  medications?: Maybe<Array<Maybe<Medication>>>;
  medicationsHash?: Maybe<Scalars['String']>;
  modified: Scalars['DateTime'];
  orders?: Maybe<OrderCountableConnection>;
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['Int']>;
  patientIdentifier?: Maybe<Scalars['String']>;
  prescriptionId: Scalars['String'];
  providerLicense?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  remainingDaysToNextRefill?: Maybe<Scalars['Int']>;
};


export type PrescriptionDiagnosisArgs = {
  filter?: InputMaybe<DiagnoseFilterInput>;
};


export type PrescriptionEarlyRefillRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EarlyRefillRequestFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PrescriptionMedicationsArgs = {
  filter?: InputMaybe<MedicationFilterInput>;
};


export type PrescriptionOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<OrderSortingInput>;
};

export type PrescriptionCountableConnection = {
  __typename?: 'PrescriptionCountableConnection';
  edges: Array<PrescriptionCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PrescriptionCountableEdge = {
  __typename?: 'PrescriptionCountableEdge';
  cursor: Scalars['String'];
  node: Prescription;
};

export enum PrescriptionDispenseMethodEnum {
  Dispense = 'DISPENSE',
  Ingested = 'INGESTED',
  Refill = 'REFILL'
}

export enum PrescriptionDispenseStatus {
  Delivered = 'DELIVERED',
  Dispensed = 'DISPENSED',
  ErxHubAuthRequestFailed = 'ERX_HUB_AUTH_REQUEST_FAILED',
  ErxHubClaimApproved = 'ERX_HUB_CLAIM_APPROVED',
  ErxHubClaimFailed = 'ERX_HUB_CLAIM_FAILED',
  ErxHubClaimRejected = 'ERX_HUB_CLAIM_REJECTED',
  ErxHubFullyApproved = 'ERX_HUB_FULLY_APPROVED',
  ErxHubPartiallyApproved = 'ERX_HUB_PARTIALLY_APPROVED',
  ErxHubRejected = 'ERX_HUB_REJECTED',
  ErxHubTimedOut = 'ERX_HUB_TIMED_OUT',
  InProgress = 'IN_PROGRESS',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentTimedOut = 'PAYMENT_TIMED_OUT',
  Pending = 'PENDING',
  ReadyForCustomerPickup = 'READY_FOR_CUSTOMER_PICKUP',
  WaitingErxHubApproval = 'WAITING_ERX_HUB_APPROVAL',
  WaitingErxHubClaimApproval = 'WAITING_ERX_HUB_CLAIM_APPROVAL',
  WaitingProviderSelection = 'WAITING_PROVIDER_SELECTION'
}

export enum PrescriptionDispenseStatusEnum {
  Delivered = 'DELIVERED',
  Dispensed = 'DISPENSED',
  ErxHubAuthRequestFailed = 'ERX_HUB_AUTH_REQUEST_FAILED',
  ErxHubClaimApproved = 'ERX_HUB_CLAIM_APPROVED',
  ErxHubClaimFailed = 'ERX_HUB_CLAIM_FAILED',
  ErxHubClaimRejected = 'ERX_HUB_CLAIM_REJECTED',
  ErxHubFullyApproved = 'ERX_HUB_FULLY_APPROVED',
  ErxHubPartiallyApproved = 'ERX_HUB_PARTIALLY_APPROVED',
  ErxHubRejected = 'ERX_HUB_REJECTED',
  ErxHubTimedOut = 'ERX_HUB_TIMED_OUT',
  InProgress = 'IN_PROGRESS',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentTimedOut = 'PAYMENT_TIMED_OUT',
  Pending = 'PENDING',
  ReadyForCustomerPickup = 'READY_FOR_CUSTOMER_PICKUP',
  WaitingErxHubApproval = 'WAITING_ERX_HUB_APPROVAL',
  WaitingErxHubClaimApproval = 'WAITING_ERX_HUB_CLAIM_APPROVAL',
  WaitingProviderSelection = 'WAITING_PROVIDER_SELECTION'
}

export type PrescriptionError = {
  __typename?: 'PrescriptionError';
  code: PrescriptionErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum PrescriptionErrorCode {
  ControlledDrug = 'CONTROLLED_DRUG',
  GraphqlError = 'GRAPHQL_ERROR',
  Ingested = 'INGESTED',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type PrescriptionFilterInput = {
  canRefill?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<DateRangeInput>;
  medication?: InputMaybe<Scalars['String']>;
  orderType?: InputMaybe<OrderTypesEnum>;
  orders?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  ordersNumbers?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  referenceNumber?: InputMaybe<Scalars['String']>;
  referenceNumber_Icontains?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<InputMaybe<PrescriptionDispenseStatusEnum>>>;
};

export type PrescriptionOrderAssignVendors = {
  __typename?: 'PrescriptionOrderAssignVendors';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderErrors: Array<OrderError>;
  orders?: Maybe<Array<Maybe<Order>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionOrderAssignVendorsInput = {
  branches: Array<InputMaybe<Scalars['ID']>>;
  order: Scalars['ID'];
};

export enum PrescriptionSortField {
  Clinician = 'CLINICIAN',
  Created = 'CREATED',
  PatientIdentifier = 'PATIENT_IDENTIFIER',
  ProviderLicense = 'PROVIDER_LICENSE',
  ReferenceNumber = 'REFERENCE_NUMBER'
}

export type PrescriptionSortingInput = {
  direction: OrderDirection;
  field: PrescriptionSortField;
};

export type PrescriptionStatusChanged = {
  __typename?: 'PrescriptionStatusChanged';
  prescription?: Maybe<Prescription>;
};

export enum PriceRangeEnum {
  Average = 'AVERAGE',
  Cheap = 'CHEAP',
  Expensive = 'EXPENSIVE',
  VeryCheap = 'VERY_CHEAP',
  VeryExpensive = 'VERY_EXPENSIVE'
}

export type PricingRule = Node & {
  __typename?: 'PricingRule';
  id: Scalars['ID'];
  price: Scalars['Float'];
  weightFrom: Scalars['Float'];
  weightTo: Scalars['Float'];
};

export type PricingRuleCreate = {
  __typename?: 'PricingRuleCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  pricingRule?: Maybe<PricingRule>;
  pricingRuleErrors: Array<ShippingError>;
};

export type PricingRuleDelete = {
  __typename?: 'PricingRuleDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  pricingRule?: Maybe<PricingRule>;
  pricingRuleErrors: Array<ShippingError>;
};

export type PricingRuleInput = {
  price: Scalars['Float'];
  weightFrom?: InputMaybe<Scalars['Float']>;
  weightTo?: InputMaybe<Scalars['Float']>;
};

export type PricingRuleUpdate = {
  __typename?: 'PricingRuleUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  pricingRule?: Maybe<PricingRule>;
  pricingRuleErrors: Array<ShippingError>;
};

export enum Priority {
  High = 'HIGH',
  Low = 'LOW'
}

export enum ProcessingStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
  UnderReview = 'UNDER_REVIEW'
}

export type Product = Node & ObjectWithExtendedTranslatableFields & {
  __typename?: 'Product';
  attributes: Array<SelectedAttribute>;
  benefits?: Maybe<Scalars['String']>;
  brand?: Maybe<ProductBrand>;
  category?: Maybe<Category>;
  chargeTaxes: Scalars['Boolean'];
  collections?: Maybe<Array<Maybe<Collection>>>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJson?: Maybe<Scalars['JSONString']>;
  hasAddons?: Maybe<Scalars['Boolean']>;
  howToUse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageById?: Maybe<ProductImage>;
  images?: Maybe<Array<Maybe<ProductImage>>>;
  isAddon?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isLiked?: Maybe<Scalars['Boolean']>;
  isPublished: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  productType: ProductType;
  publicationDate?: Maybe<Scalars['Date']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  taxType?: Maybe<TaxType>;
  thumbnail?: Maybe<Image>;
  totalLikes: Scalars['Int'];
  translation?: Maybe<ProductTranslation>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<Array<Maybe<ProductVariant>>>;
  weight?: Maybe<Weight>;
};


export type ProductImageByIdArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type ProductIsAvailableArgs = {
  branchId?: InputMaybe<Scalars['ID']>;
};


export type ProductTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type ProductAttributeError = {
  __typename?: 'ProductAttributeError';
  attributes?: Maybe<Array<Scalars['ID']>>;
  code: ProductErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ProductBrand = Node & {
  __typename?: 'ProductBrand';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  products: ProductCountableConnection;
};


export type ProductBrandProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ProductBrandCountableConnection = {
  __typename?: 'ProductBrandCountableConnection';
  edges: Array<ProductBrandCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductBrandCountableEdge = {
  __typename?: 'ProductBrandCountableEdge';
  cursor: Scalars['String'];
  node: ProductBrand;
};

export type ProductBrandCreate = {
  __typename?: 'ProductBrandCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productBrand?: Maybe<ProductBrand>;
  productErrors: Array<ProductError>;
};

export type ProductBrandDelete = {
  __typename?: 'ProductBrandDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productBrand?: Maybe<ProductBrand>;
  productErrors: Array<ProductError>;
};

export type ProductBrandFilterInput = {
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
};

export type ProductBrandInput = {
  name?: InputMaybe<Scalars['String']>;
};

export enum ProductBrandSortField {
  Created = 'CREATED',
  Name = 'NAME'
}

export type ProductBrandSortingInput = {
  direction: OrderDirection;
  field: ProductBrandSortField;
};

export type ProductBrandUpdate = {
  __typename?: 'ProductBrandUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productBrand?: Maybe<ProductBrand>;
  productErrors: Array<ProductError>;
};

export type ProductBulkDelete = {
  __typename?: 'ProductBulkDelete';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type ProductBulkPublish = {
  __typename?: 'ProductBulkPublish';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type ProductCountableConnection = {
  __typename?: 'ProductCountableConnection';
  edges: Array<ProductCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductCountableEdge = {
  __typename?: 'ProductCountableEdge';
  cursor: Scalars['String'];
  node: Product;
};

export type ProductCreate = {
  __typename?: 'ProductCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  product?: Maybe<Product>;
  productErrors: Array<ProductError>;
};

export type ProductCreateInput = {
  attributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>;
  benefits?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<Scalars['ID']>;
  chargeTaxes?: InputMaybe<Scalars['Boolean']>;
  collections?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  descriptionJson?: InputMaybe<Scalars['JSONString']>;
  hasAddons?: InputMaybe<Scalars['Boolean']>;
  howToUse?: InputMaybe<Scalars['String']>;
  isAddon?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  productType: Scalars['ID'];
  publicationDate?: InputMaybe<Scalars['Date']>;
  seo?: InputMaybe<SeoInput>;
  sku?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  taxCode?: InputMaybe<Scalars['String']>;
  trackInventory?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['WeightScalar']>;
};

export type ProductError = {
  __typename?: 'ProductError';
  code: ProductErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum ProductErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  AttributeAlreadyAssigned = 'ATTRIBUTE_ALREADY_ASSIGNED',
  AttributeCannotBeAssigned = 'ATTRIBUTE_CANNOT_BE_ASSIGNED',
  AttributeVariantsDisabled = 'ATTRIBUTE_VARIANTS_DISABLED',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  NotProductsImage = 'NOT_PRODUCTS_IMAGE',
  NoPurchaseHistory = 'NO_PURCHASE_HISTORY',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type ProductFilterInput = {
  brands?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  collections?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hasAddons?: InputMaybe<Scalars['Boolean']>;
  hasCategory?: InputMaybe<Scalars['Boolean']>;
  isAddon?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  productType?: InputMaybe<Scalars['ID']>;
  productTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
  stockAvailability?: InputMaybe<StockAvailability>;
  stocks?: InputMaybe<ProductStockFilterInput>;
};

export type ProductImage = Node & {
  __typename?: 'ProductImage';
  alt: Scalars['String'];
  id: Scalars['ID'];
  sortOrder?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type ProductImageBulkDelete = {
  __typename?: 'ProductImageBulkDelete';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type ProductImageCreate = {
  __typename?: 'ProductImageCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<ProductImage>;
  product?: Maybe<Product>;
  productErrors: Array<ProductError>;
};

export type ProductImageCreateInput = {
  alt?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  product: Scalars['ID'];
};

export type ProductImageDelete = {
  __typename?: 'ProductImageDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<ProductImage>;
  product?: Maybe<Product>;
  productErrors: Array<ProductError>;
};

export type ProductImageReorder = {
  __typename?: 'ProductImageReorder';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  images?: Maybe<Array<Maybe<ProductImage>>>;
  product?: Maybe<Product>;
  productErrors: Array<ProductError>;
};

export type ProductImageUpdate = {
  __typename?: 'ProductImageUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<ProductImage>;
  product?: Maybe<Product>;
  productErrors: Array<ProductError>;
};

export type ProductImageUpdateInput = {
  alt?: InputMaybe<Scalars['String']>;
};

export type ProductInput = {
  attributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>;
  benefits?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<Scalars['ID']>;
  chargeTaxes?: InputMaybe<Scalars['Boolean']>;
  collections?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  descriptionJson?: InputMaybe<Scalars['JSONString']>;
  hasAddons?: InputMaybe<Scalars['Boolean']>;
  howToUse?: InputMaybe<Scalars['String']>;
  isAddon?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  publicationDate?: InputMaybe<Scalars['Date']>;
  seo?: InputMaybe<SeoInput>;
  sku?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  taxCode?: InputMaybe<Scalars['String']>;
  trackInventory?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['WeightScalar']>;
};

export type ProductLike = {
  __typename?: 'ProductLike';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productLike?: Maybe<ProductLikeType>;
};

export type ProductLikeInput = {
  product: Scalars['ID'];
};

export type ProductLikeType = Node & {
  __typename?: 'ProductLikeType';
  customerId: Scalars['Int'];
  id: Scalars['ID'];
  product: Product;
};

export type ProductOrder = {
  direction: OrderDirection;
  field?: InputMaybe<ProductOrderField>;
};

export enum ProductOrderField {
  Created = 'CREATED',
  Date = 'DATE',
  Name = 'NAME',
  Published = 'PUBLISHED',
  Type = 'TYPE'
}

export type ProductStockFilterInput = {
  branchIds?: InputMaybe<Array<Scalars['ID']>>;
  quantity?: InputMaybe<IntRangeInput>;
};

export type ProductTranslatableContent = Node & {
  __typename?: 'ProductTranslatableContent';
  description: Scalars['String'];
  descriptionJson: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  product?: Maybe<Product>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  translation?: Maybe<ProductTranslation>;
};


export type ProductTranslatableContentTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type ProductTranslate = {
  __typename?: 'ProductTranslate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  product?: Maybe<Product>;
  translationErrors: Array<TranslationError>;
};

export type ProductTranslation = Node & {
  __typename?: 'ProductTranslation';
  description: Scalars['String'];
  descriptionJson: Scalars['String'];
  id: Scalars['ID'];
  language: LanguageDisplay;
  name: Scalars['String'];
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
};

export type ProductType = Node & ObjectWithBasicTranslatableFields & {
  __typename?: 'ProductType';
  availableAttributes?: Maybe<AttributeCountableConnection>;
  hasVariants: Scalars['Boolean'];
  id: Scalars['ID'];
  isShippingRequired: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  productAttributes?: Maybe<Array<Maybe<Attribute>>>;
  products?: Maybe<ProductCountableConnection>;
  slug: Scalars['String'];
  taxRate?: Maybe<TaxRateType>;
  taxType?: Maybe<TaxType>;
  translation?: Maybe<ProductTypeTranslation>;
  variantAttributes?: Maybe<Array<Maybe<Attribute>>>;
  weight?: Maybe<Weight>;
};


export type ProductTypeAvailableAttributesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AttributeFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ProductTypeProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ProductTypeTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type ProductTypeBulkDelete = {
  __typename?: 'ProductTypeBulkDelete';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export enum ProductTypeConfigurable {
  Configurable = 'CONFIGURABLE',
  Simple = 'SIMPLE'
}

export type ProductTypeCountableConnection = {
  __typename?: 'ProductTypeCountableConnection';
  edges: Array<ProductTypeCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductTypeCountableEdge = {
  __typename?: 'ProductTypeCountableEdge';
  cursor: Scalars['String'];
  node: ProductType;
};

export type ProductTypeCreate = {
  __typename?: 'ProductTypeCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productType?: Maybe<ProductType>;
};

export type ProductTypeDelete = {
  __typename?: 'ProductTypeDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productType?: Maybe<ProductType>;
};

export type ProductTypeFilterInput = {
  configurable?: InputMaybe<ProductTypeConfigurable>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
};

export type ProductTypeInput = {
  hasVariants?: InputMaybe<Scalars['Boolean']>;
  isShippingRequired?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  productAttributes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  slug?: InputMaybe<Scalars['String']>;
  taxCode?: InputMaybe<Scalars['String']>;
  variantAttributes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  weight?: InputMaybe<Scalars['WeightScalar']>;
};

export type ProductTypeReorderAttributes = {
  __typename?: 'ProductTypeReorderAttributes';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productType?: Maybe<ProductType>;
};

export enum ProductTypeSortField {
  Name = 'NAME',
  ShippingRequired = 'SHIPPING_REQUIRED'
}

export type ProductTypeSortingInput = {
  direction: OrderDirection;
  field: ProductTypeSortField;
};

export type ProductTypeTranslate = {
  __typename?: 'ProductTypeTranslate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productType?: Maybe<ProductType>;
  translationErrors: Array<TranslationError>;
};

export type ProductTypeTranslation = Node & {
  __typename?: 'ProductTypeTranslation';
  id: Scalars['ID'];
  language: LanguageDisplay;
  name: Scalars['String'];
};

export type ProductTypeUpdate = {
  __typename?: 'ProductTypeUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productType?: Maybe<ProductType>;
};

export type ProductUnLike = {
  __typename?: 'ProductUnLike';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productLike?: Maybe<ProductLikeType>;
};

export type ProductUpdate = {
  __typename?: 'ProductUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  product?: Maybe<Product>;
  productErrors: Array<ProductError>;
};

export type ProductVariant = Node & ObjectWithBasicTranslatableFields & {
  __typename?: 'ProductVariant';
  attributes: Array<SelectedAttribute>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<ProductImage>>>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  quantityAvailable: Scalars['Int'];
  quantityOrdered?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
  revenue?: Maybe<TaxedMoney>;
  sku: Scalars['String'];
  stocks?: Maybe<Array<Maybe<Stock>>>;
  trackInventory: Scalars['Boolean'];
  translation?: Maybe<ProductVariantTranslation>;
  vendorProductVariant?: Maybe<VendorProductVariant>;
  weight?: Maybe<Weight>;
};


export type ProductVariantQuantityAvailableArgs = {
  branchId?: InputMaybe<Scalars['ID']>;
};


export type ProductVariantRevenueArgs = {
  period?: InputMaybe<ReportingPeriod>;
};


export type ProductVariantStocksArgs = {
  branchId?: InputMaybe<Scalars['ID']>;
};


export type ProductVariantTranslationArgs = {
  languageCode: LanguageCodeEnum;
};


export type ProductVariantVendorProductVariantArgs = {
  branchId?: InputMaybe<Scalars['ID']>;
};

export type ProductVariantBulkCreate = {
  __typename?: 'ProductVariantBulkCreate';
  bulkProductErrors: Array<BulkProductError>;
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productVariants: Array<ProductVariant>;
};

export type ProductVariantBulkCreateInput = {
  attributes: Array<InputMaybe<AttributeValueInput>>;
  sku: Scalars['String'];
  trackInventory?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['WeightScalar']>;
};

export type ProductVariantBulkDelete = {
  __typename?: 'ProductVariantBulkDelete';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
};

export type ProductVariantCountableConnection = {
  __typename?: 'ProductVariantCountableConnection';
  edges: Array<ProductVariantCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductVariantCountableEdge = {
  __typename?: 'ProductVariantCountableEdge';
  cursor: Scalars['String'];
  node: ProductVariant;
};

export type ProductVariantCreate = {
  __typename?: 'ProductVariantCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productVariant?: Maybe<ProductVariant>;
};

export type ProductVariantCreateInput = {
  attributes: Array<InputMaybe<AttributeValueInput>>;
  product: Scalars['ID'];
  sku?: InputMaybe<Scalars['String']>;
  trackInventory?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['WeightScalar']>;
};

export type ProductVariantDelete = {
  __typename?: 'ProductVariantDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productVariant?: Maybe<ProductVariant>;
};

export type ProductVariantFilterInput = {
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hasAvailableStocks?: InputMaybe<Scalars['Boolean']>;
  isPublishedProduct?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nameAr?: InputMaybe<Scalars['String']>;
  onSale?: InputMaybe<Scalars['Boolean']>;
  priceFrom?: InputMaybe<Scalars['Float']>;
  priceTo?: InputMaybe<Scalars['Float']>;
  product?: InputMaybe<Scalars['ID']>;
  productBrands?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ProductVariantImageAssign = {
  __typename?: 'ProductVariantImageAssign';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<ProductImage>;
  productErrors: Array<ProductError>;
  productVariant?: Maybe<ProductVariant>;
};

export type ProductVariantImageUnassign = {
  __typename?: 'ProductVariantImageUnassign';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<ProductImage>;
  productErrors: Array<ProductError>;
  productVariant?: Maybe<ProductVariant>;
};

export type ProductVariantInput = {
  attributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>;
  sku?: InputMaybe<Scalars['String']>;
  trackInventory?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['WeightScalar']>;
};

export type ProductVariantReview = Node & {
  __typename?: 'ProductVariantReview';
  content?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  helpfulCount: Scalars['Int'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  productVariant: ProductVariant;
  published: Scalars['Boolean'];
  rank?: Maybe<Scalars['Int']>;
  rating: Scalars['Int'];
};

export type ProductVariantReviewCountableConnection = {
  __typename?: 'ProductVariantReviewCountableConnection';
  edges: Array<ProductVariantReviewCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductVariantReviewCountableEdge = {
  __typename?: 'ProductVariantReviewCountableEdge';
  cursor: Scalars['String'];
  node: ProductVariantReview;
};

export type ProductVariantReviewCreate = {
  __typename?: 'ProductVariantReviewCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productVariantReview?: Maybe<ProductVariantReview>;
};

export type ProductVariantReviewCreateInput = {
  content?: InputMaybe<Scalars['String']>;
  productVariant: Scalars['ID'];
  rating: Scalars['Int'];
};

export type ProductVariantReviewDelete = {
  __typename?: 'ProductVariantReviewDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productVariantReview?: Maybe<ProductVariantReview>;
};

export type ProductVariantReviewFilterInput = {
  productVariant?: InputMaybe<Scalars['ID']>;
};

export type ProductVariantReviewPublish = {
  __typename?: 'ProductVariantReviewPublish';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productVariantReview?: Maybe<ProductVariantReview>;
};

export type ProductVariantReviewPublishInput = {
  published: Scalars['Boolean'];
};

export type ProductVariantReviewRank = Node & {
  __typename?: 'ProductVariantReviewRank';
  id: Scalars['ID'];
  isHelpful: Scalars['Boolean'];
  productVariantReview: ProductVariantReview;
};

export type ProductVariantReviewSortingInput = {
  direction: OrderDirection;
  field: VariantReviewSortField;
};

export type ProductVariantReviewUpdate = {
  __typename?: 'ProductVariantReviewUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productVariantReview?: Maybe<ProductVariantReview>;
};

export type ProductVariantReviewUpdateInput = {
  content?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<Scalars['Int']>;
};

export type ProductVariantTranslatableContent = Node & {
  __typename?: 'ProductVariantTranslatableContent';
  id: Scalars['ID'];
  name: Scalars['String'];
  productVariant?: Maybe<ProductVariant>;
  translation?: Maybe<ProductVariantTranslation>;
};


export type ProductVariantTranslatableContentTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type ProductVariantTranslate = {
  __typename?: 'ProductVariantTranslate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productVariant?: Maybe<ProductVariant>;
  translationErrors: Array<TranslationError>;
};

export type ProductVariantTranslation = Node & {
  __typename?: 'ProductVariantTranslation';
  id: Scalars['ID'];
  language: LanguageDisplay;
  name: Scalars['String'];
};

export type ProductVariantUpdate = {
  __typename?: 'ProductVariantUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productVariant?: Maybe<ProductVariant>;
};

export type PushNotificationInput = {
  app: AppTypes;
  body?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['JSONString']>;
  key?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<Address>;
  attribute?: Maybe<Attribute>;
  attributes?: Maybe<AttributeCountableConnection>;
  auditLogsForPaymentModule?: Maybe<AuditLogCountableConnection>;
  block?: Maybe<Block>;
  blocks?: Maybe<BlockCountableConnection>;
  branch?: Maybe<Branch>;
  branches?: Maybe<BranchCountableConnection>;
  bulkFiles?: Maybe<BulkFileConnection>;
  bulkTemplates?: Maybe<Array<Maybe<BulkTemplate>>>;
  categories?: Maybe<CategoryCountableConnection>;
  category?: Maybe<Category>;
  checkout?: Maybe<Checkout>;
  checkoutLine?: Maybe<CheckoutLine>;
  checkoutLines?: Maybe<CheckoutLineCountableConnection>;
  checkouts?: Maybe<CheckoutCountableConnection>;
  cities?: Maybe<CityCountableConnection>;
  city?: Maybe<City>;
  codeSystemConceptAutoCompleteSearch?: Maybe<Array<Maybe<Scalars['String']>>>;
  collection?: Maybe<Collection>;
  collections?: Maybe<CollectionCountableConnection>;
  consumerViewPreference?: Maybe<ConsumerViewPreference>;
  containsControlledDrugs?: Maybe<Scalars['Boolean']>;
  countries?: Maybe<Array<Maybe<CountryDisplay>>>;
  customer?: Maybe<User>;
  customers?: Maybe<UserCountableConnection>;
  customersDashboard?: Maybe<Array<Maybe<Aggregation>>>;
  customersWallets?: Maybe<WalletCountableConnection>;
  deliveryTimeSlot?: Maybe<DeliveryTimeSlot>;
  deliveryTimeSlots?: Maybe<DeliveryTimeSlotCountableConnection>;
  division?: Maybe<Division>;
  divisions?: Maybe<DivisionConnection>;
  downloadBulkFile?: Maybe<Scalars['String']>;
  downloadCodeSystemFile?: Maybe<Scalars['String']>;
  downloadSubList?: Maybe<Scalars['String']>;
  draftOrders?: Maybe<OrderCountableConnection>;
  earlyRefillReasons?: Maybe<Array<Maybe<EarlyRefillReason>>>;
  earlyRefillRequests?: Maybe<EarlyRefillRequestCountableConnection>;
  getActiveDispensedPrescriptions?: Maybe<Array<Maybe<ActiveDispensedPrescription>>>;
  getAllListFields?: Maybe<Array<Maybe<Field>>>;
  getAllowedDeliveryTypes?: Maybe<Array<Maybe<GenericDrugDeliveryEligibility>>>;
  getClinician?: Maybe<Clinician>;
  getCodeSystem?: Maybe<CodeSystem>;
  getCodeSystemConcept?: Maybe<CodeSystemConcept>;
  getCodeSystemConcepts?: Maybe<CodeSystemConceptConnection>;
  getCodeSystemConceptsByCode?: Maybe<CodeSystemConceptConnection>;
  getCodeSystemDefinition?: Maybe<CodeSystemDefinition>;
  getCodeSystemDefinitionById?: Maybe<CodeSystemDefinition>;
  getCodeSystemDefinitionsSummery?: Maybe<Array<Maybe<CodeSystemSummery>>>;
  getCodeSystems?: Maybe<CodeSystemConnection>;
  getDecision?: Maybe<Decision>;
  getDecisions?: Maybe<DecisionConnection>;
  getDeliveryNotificationsForDeliveryShipment?: Maybe<Array<Maybe<DeliveryNotification>>>;
  getPatientPrescription?: Maybe<WasfatyPrescription>;
  getPayment?: Maybe<PaymentResponse>;
  getPharmacyCredentials?: Maybe<PharmacyCredentials>;
  getPlans?: Maybe<Array<Maybe<DecisionPlan>>>;
  getRejectionReasons?: Maybe<Array<Maybe<RejectionReason>>>;
  getShipmentOrderForDeliveryShipment?: Maybe<DeliveryShipmentGetShipmentResponseResult>;
  getSubList?: Maybe<SubList>;
  getSubListItems?: Maybe<SubListItemConnection>;
  getSubLists?: Maybe<SubListConnection>;
  getTradeDrugQuantity?: Maybe<Array<Maybe<MedicationTradeDrugs>>>;
  getTradeDrugs?: Maybe<Array<Maybe<TradeDrug>>>;
  getTransaction?: Maybe<TransactionReport>;
  me?: Maybe<User>;
  medicalDeliveryRequest?: Maybe<MedicalDeliveryRequest>;
  medicalDeliveryRequests?: Maybe<MedicalDeliveryRequestCountableConnection>;
  medlistUsers?: Maybe<UserCountableConnection>;
  order?: Maybe<Order>;
  orderByToken?: Maybe<Order>;
  orderRejectionReasons?: Maybe<OrderRejectionReasonCountableConnection>;
  orders?: Maybe<OrderCountableConnection>;
  ordersDashboard?: Maybe<Array<Maybe<Aggregation>>>;
  ordersSocialDashboard?: Maybe<Array<Maybe<Scalars['DictType']>>>;
  ordersTotal?: Maybe<TaxedMoney>;
  patient?: Maybe<Patient>;
  patients?: Maybe<PatientCountableConnection>;
  paymentReceipts: PaymentReceiptCountableConnection;
  permissionGroup?: Maybe<Group>;
  permissionGroups?: Maybe<GroupCountableConnection>;
  permissions: Array<Maybe<Permission>>;
  plan?: Maybe<Plan>;
  planPeriods?: Maybe<Array<Maybe<Scalars['String']>>>;
  plans?: Maybe<PlanCountableConnection>;
  plugin?: Maybe<Plugin>;
  plugins?: Maybe<PluginCountableConnection>;
  prescription?: Maybe<Prescription>;
  prescriptions?: Maybe<PrescriptionCountableConnection>;
  product?: Maybe<Product>;
  productBrand?: Maybe<ProductBrand>;
  productBrands?: Maybe<ProductBrandCountableConnection>;
  productType?: Maybe<ProductType>;
  productTypes?: Maybe<ProductTypeCountableConnection>;
  productVariant?: Maybe<ProductVariant>;
  productVariantReviews?: Maybe<ProductVariantReviewCountableConnection>;
  productVariants?: Maybe<ProductVariantCountableConnection>;
  products?: Maybe<ProductCountableConnection>;
  sale?: Maybe<Sale>;
  sales?: Maybe<SaleCountableConnection>;
  shippingZone?: Maybe<ShippingZone>;
  shippingZones?: Maybe<ShippingZoneCountableConnection>;
  siteSettings: SiteSettings;
  stock?: Maybe<Stock>;
  stocks?: Maybe<StockCountableConnection>;
  storeProduct?: Maybe<VendorProductVariant>;
  storeProducts?: Maybe<VendorProductVariantCountableConnection>;
  survey?: Maybe<Survey>;
  taxTypes?: Maybe<Array<Maybe<TaxType>>>;
  testForDeliveryShipment?: Maybe<Scalars['String']>;
  translation?: Maybe<TranslatableItem>;
  translations?: Maybe<TranslatableItemConnection>;
  user?: Maybe<User>;
  users?: Maybe<UserCountableConnection>;
  vendor?: Maybe<Vendor>;
  vendorCategories?: Maybe<CategoryCountableConnection>;
  vendorInvoice?: Maybe<VendorInvoice>;
  vendorInvoices?: Maybe<VendorInvoiceCountableConnection>;
  vendorSubscription?: Maybe<VendorSubscription>;
  vendorSubscriptions?: Maybe<VendorSubscriptionCountableConnection>;
  vendorWebHook?: Maybe<VendorWebhook>;
  vendors?: Maybe<VendorCountableConnection>;
  wallet?: Maybe<Wallet>;
};


export type QueryAddressArgs = {
  id: Scalars['ID'];
};


export type QueryAttributeArgs = {
  id: Scalars['ID'];
};


export type QueryAttributesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AttributeFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<AttributeSortingInput>;
};


export type QueryAuditLogsForPaymentModuleArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AuditLogFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryBlockArgs = {
  id: Scalars['ID'];
};


export type QueryBlocksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BlockFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<BlockOrder>;
};


export type QueryBranchArgs = {
  id: Scalars['ID'];
};


export type QueryBranchesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BranchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<BranchOrder>;
  userLocation?: InputMaybe<LocationInput>;
};


export type QueryBulkFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters: BulkFilesFilterInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<BulkFilesSortingInput>;
};


export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  level?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CategorySortingInput>;
};


export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryCheckoutArgs = {
  token: Scalars['UUID'];
};


export type QueryCheckoutLineArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCheckoutLinesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCheckoutsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CityFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CityOrder>;
};


export type QueryCityArgs = {
  id: Scalars['ID'];
};


export type QueryCodeSystemConceptAutoCompleteSearchArgs = {
  codeSystemCode?: InputMaybe<CodeSystemCode>;
  codeSystemId?: InputMaybe<Scalars['ID']>;
  keyword: Scalars['String'];
  propertyCode: Scalars['String'];
};


export type QueryCollectionArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CollectionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CollectionSortingInput>;
};


export type QueryConsumerViewPreferenceArgs = {
  view: Scalars['String'];
};


export type QueryContainsControlledDrugsArgs = {
  genericDrugs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCustomerArgs = {
  nationalId: Scalars['String'];
};


export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  branch?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CustomerFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  hasChatWith?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UserSortingInput>;
};


export type QueryCustomersDashboardArgs = {
  filter?: InputMaybe<CustomerDashboardFilterInput>;
};


export type QueryCustomersWalletsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<WalletFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<WalletOrder>;
};


export type QueryDeliveryTimeSlotArgs = {
  id: Scalars['ID'];
};


export type QueryDeliveryTimeSlotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<DeliveryTimeSlotFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<DeliverySortingInput>;
};


export type QueryDivisionArgs = {
  id: Scalars['ID'];
};


export type QueryDivisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryDownloadBulkFileArgs = {
  fileId: Scalars['ID'];
};


export type QueryDownloadCodeSystemFileArgs = {
  code: CodeSystemCode;
  codeSystemId?: InputMaybe<Scalars['ID']>;
};


export type QueryDownloadSubListArgs = {
  subListId: Scalars['ID'];
};


export type QueryDraftOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrderDraftFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<OrderSortingInput>;
};


export type QueryEarlyRefillRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EarlyRefillRequestFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryGetActiveDispensedPrescriptionsArgs = {
  patientIdentifier: Scalars['String'];
};


export type QueryGetAllowedDeliveryTypesArgs = {
  genericDrugs: Array<InputMaybe<Scalars['String']>>;
};


export type QueryGetClinicianArgs = {
  nationalId: Scalars['String'];
};


export type QueryGetCodeSystemArgs = {
  id: Scalars['ID'];
};


export type QueryGetCodeSystemConceptArgs = {
  id: Scalars['ID'];
};


export type QueryGetCodeSystemConceptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  codeSystemId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchParameters?: InputMaybe<Array<InputMaybe<SearchParameter>>>;
  showOnlyVisibleProperties?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetCodeSystemConceptsByCodeArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  codeSystemCode?: InputMaybe<CodeSystemCode>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchParameters?: InputMaybe<Array<InputMaybe<SearchParameter>>>;
  showOnlyVisibleProperties?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetCodeSystemDefinitionArgs = {
  code: CodeSystemCode;
};


export type QueryGetCodeSystemDefinitionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetCodeSystemDefinitionsSummeryArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryGetCodeSystemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<CodeSystemCode>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDecisionArgs = {
  id: Scalars['ID'];
};


export type QueryGetDecisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  decisionName?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isScript?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  plan?: InputMaybe<Scalars['ID']>;
  sortBy?: InputMaybe<DecisionSortingInput>;
};


export type QueryGetDeliveryNotificationsForDeliveryShipmentArgs = {
  allocateOrderId?: InputMaybe<Scalars['String']>;
  dispenseOrderId?: InputMaybe<Scalars['String']>;
};


export type QueryGetPatientPrescriptionArgs = {
  erxReference?: InputMaybe<Scalars['String']>;
  patientIdentifier: Scalars['String'];
};


export type QueryGetPaymentArgs = {
  paymentId: Scalars['String'];
};


export type QueryGetPharmacyCredentialsArgs = {
  branch: Scalars['ID'];
};


export type QueryGetPlansArgs = {
  planCode?: InputMaybe<PlanCode>;
};


export type QueryGetShipmentOrderForDeliveryShipmentArgs = {
  allocateOrderId?: InputMaybe<Scalars['String']>;
  dispenseOrderId?: InputMaybe<Scalars['String']>;
};


export type QueryGetSubListArgs = {
  id: Scalars['ID'];
};


export type QueryGetSubListItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  display?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<SubListItemSortingInput>;
  subListId: Scalars['ID'];
};


export type QueryGetSubListsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  display?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<SubListSortingInput>;
};


export type QueryGetTradeDrugQuantityArgs = {
  erxReference: Scalars['String'];
  patientIdentifier: Scalars['String'];
};


export type QueryGetTradeDrugsArgs = {
  tradeCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGetTransactionArgs = {
  input: PaymentCheckoutInput;
};


export type QueryMedicalDeliveryRequestArgs = {
  id: Scalars['ID'];
};


export type QueryMedicalDeliveryRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<MedicalOrderDeliveryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MedicalDeliverySortingInput>;
};


export type QueryMedlistUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UserSortingInput>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderByTokenArgs = {
  token: Scalars['UUID'];
};


export type QueryOrderRejectionReasonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<OrderSortingInput>;
};


export type QueryOrdersDashboardArgs = {
  filter?: InputMaybe<OrderDashboardFilterInput>;
};


export type QueryOrdersSocialDashboardArgs = {
  filter?: InputMaybe<OrderDashboardFilterInput>;
};


export type QueryOrdersTotalArgs = {
  period?: InputMaybe<ReportingPeriod>;
};


export type QueryPatientArgs = {
  id?: InputMaybe<Scalars['ID']>;
  nationalId?: InputMaybe<Scalars['String']>;
};


export type QueryPatientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PatientFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPermissionGroupArgs = {
  id: Scalars['ID'];
};


export type QueryPermissionGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PermissionGroupFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<PermissionGroupSortingInput>;
};


export type QueryPlanArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPlansArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PlanFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<PlanOrder>;
};


export type QueryPluginArgs = {
  id: Scalars['ID'];
};


export type QueryPluginsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PluginFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<PluginSortingInput>;
};


export type QueryPrescriptionArgs = {
  id?: InputMaybe<Scalars['ID']>;
  patientIdentifier?: InputMaybe<Scalars['String']>;
  referenceNumber?: InputMaybe<Scalars['String']>;
};


export type QueryPrescriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PrescriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<PrescriptionSortingInput>;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryProductBrandArgs = {
  id: Scalars['ID'];
};


export type QueryProductBrandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductBrandFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProductBrandSortingInput>;
};


export type QueryProductTypeArgs = {
  id: Scalars['ID'];
};


export type QueryProductTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductTypeFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProductTypeSortingInput>;
};


export type QueryProductVariantArgs = {
  id: Scalars['ID'];
};


export type QueryProductVariantReviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductVariantReviewFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProductVariantReviewSortingInput>;
};


export type QueryProductVariantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductVariantFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<VariantSortingInput>;
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ProductOrder>;
};


export type QuerySaleArgs = {
  id: Scalars['ID'];
};


export type QuerySalesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SaleFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SaleSortingInput>;
};


export type QueryShippingZoneArgs = {
  id: Scalars['ID'];
};


export type QueryShippingZonesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryStockArgs = {
  id: Scalars['ID'];
};


export type QueryStocksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<StockFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryStoreProductArgs = {
  id: Scalars['ID'];
};


export type QueryStoreProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<VendorProductFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<VendorProductSortingInput>;
};


export type QuerySurveyArgs = {
  id: Scalars['ID'];
};


export type QueryTranslationArgs = {
  id: Scalars['ID'];
  kind: TranslatableKinds;
};


export type QueryTranslationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind: TranslatableKinds;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<UserFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UserSortingInput>;
};


export type QueryVendorArgs = {
  id: Scalars['ID'];
};


export type QueryVendorCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CategorySortingInput>;
};


export type QueryVendorInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryVendorInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<VendorInvoiceFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<VendorInvoiceOrder>;
};


export type QueryVendorSubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryVendorSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryVendorWebHookArgs = {
  vendor: Scalars['ID'];
};


export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<VendorFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<VendorOrder>;
  userLocation?: InputMaybe<LocationInput>;
};

export type Question = {
  __typename?: 'Question';
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  id?: Maybe<Scalars['ID']>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  question?: Maybe<Scalars['String']>;
  questionType?: Maybe<QuestionType>;
  required?: Maybe<Scalars['Boolean']>;
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  booleanAnswer?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  multipleChoiceAnswer?: Maybe<Array<Maybe<Scalars['String']>>>;
  question?: Maybe<Question>;
  singleChoiceAnswer?: Maybe<Scalars['String']>;
  stringAnswer?: Maybe<Scalars['String']>;
};

export type QuestionAnswerEdge = {
  __typename?: 'QuestionAnswerEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<QuestionAnswer>;
};

export type QuestionAnswerInput = {
  booleanAnswer?: InputMaybe<Scalars['Boolean']>;
  multipleChoiceAnswer?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  question: Scalars['ID'];
  singleChoiceAnswer?: InputMaybe<Scalars['String']>;
  stringAnswer?: InputMaybe<Scalars['String']>;
};

export enum QuestionType {
  Boolean = 'BOOLEAN',
  MultiChoice = 'MULTI_CHOICE',
  SingleChoice = 'SINGLE_CHOICE',
  String = 'STRING'
}

export type RankProductVariantReview = {
  __typename?: 'RankProductVariantReview';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  productErrors: Array<ProductError>;
  productVariantReviewRank?: Maybe<ProductVariantReviewRank>;
};

export type RankProductVariantReviewInput = {
  isHelpful: Scalars['Boolean'];
  productVariantReview: Scalars['ID'];
};

export type RateVendor = {
  __typename?: 'RateVendor';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
  vendorRating?: Maybe<VendorRating>;
};

export type RefillError = {
  __typename?: 'RefillError';
  code: RefillErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum RefillErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type RejectVendor = {
  __typename?: 'RejectVendor';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  rejectErrors: Array<VendorError>;
  vendorRejectionReason?: Maybe<VendorRejectionReason>;
};

export type RejectionReason = {
  __typename?: 'RejectionReason';
  code: Scalars['String'];
  display: Scalars['String'];
  id: Scalars['ID'];
};

export type RemoveVendorFromFavorite = {
  __typename?: 'RemoveVendorFromFavorite';
  customerFavoriteVendor?: Maybe<CustomerFavoriteVendor>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
};

export type ReorderInput = {
  id: Scalars['ID'];
  sortOrder?: InputMaybe<Scalars['Int']>;
};

export enum ReportingPeriod {
  Last_7Days = 'LAST_7_DAYS',
  ThisMonth = 'THIS_MONTH',
  Today = 'TODAY'
}

export type RequestPasswordResetByMobile = {
  __typename?: 'RequestPasswordResetByMobile';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars['String']>;
};

export type RequestedGenericDrugQuantity = {
  code?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export enum ResidencyTypesEnum {
  Residence = 'RESIDENCE',
  Visitor = 'VISITOR'
}

export type ResubmitOrderGetErxHubApproval = {
  __typename?: 'ResubmitOrderGetErxHubApproval';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type RoundInput = {
  maxNumberOfPharmacies: Scalars['Int'];
  pharmaciesTypes: Array<VendorBranchTypeEnum>;
  radius: Scalars['Decimal'];
};

export type SsoAccountRegister = {
  __typename?: 'SSOAccountRegister';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  requiresConfirmation?: Maybe<Scalars['Boolean']>;
  sessionToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type SsoAccountUpdate = {
  __typename?: 'SSOAccountUpdate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type SsoCreateToken = {
  __typename?: 'SSOCreateToken';
  accountErrors: Array<AccountError>;
  branches?: Maybe<Array<Maybe<Branch>>>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Sale = Node & ObjectWithBasicTranslatableFields & {
  __typename?: 'Sale';
  categories?: Maybe<CategoryCountableConnection>;
  collections?: Maybe<CollectionCountableConnection>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductCountableConnection>;
  startDate: Scalars['DateTime'];
  translation?: Maybe<SaleTranslation>;
  type: SaleType;
  value: Scalars['Float'];
  vendor?: Maybe<Vendor>;
};


export type SaleCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type SaleCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type SaleProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type SaleTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type SaleAddCatalogues = {
  __typename?: 'SaleAddCatalogues';
  discountErrors: Array<DiscountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sale?: Maybe<Sale>;
};

export type SaleBulkDelete = {
  __typename?: 'SaleBulkDelete';
  count: Scalars['Int'];
  discountErrors: Array<DiscountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type SaleCountableConnection = {
  __typename?: 'SaleCountableConnection';
  edges: Array<SaleCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SaleCountableEdge = {
  __typename?: 'SaleCountableEdge';
  cursor: Scalars['String'];
  node: Sale;
};

export type SaleCreate = {
  __typename?: 'SaleCreate';
  discountErrors: Array<DiscountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sale?: Maybe<Sale>;
};

export type SaleDelete = {
  __typename?: 'SaleDelete';
  discountErrors: Array<DiscountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sale?: Maybe<Sale>;
};

export type SaleFilterInput = {
  saleType?: InputMaybe<DiscountValueTypeEnum>;
  search?: InputMaybe<Scalars['String']>;
  started?: InputMaybe<DateTimeRangeInput>;
  status?: InputMaybe<Array<InputMaybe<DiscountStatusEnum>>>;
};

export type SaleInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  collections?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<DiscountValueTypeEnum>;
  value?: InputMaybe<Scalars['Decimal']>;
};

export type SaleRemoveCatalogues = {
  __typename?: 'SaleRemoveCatalogues';
  discountErrors: Array<DiscountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sale?: Maybe<Sale>;
};

export enum SaleSortField {
  EndDate = 'END_DATE',
  Name = 'NAME',
  StartDate = 'START_DATE',
  Type = 'TYPE',
  Value = 'VALUE'
}

export type SaleSortingInput = {
  direction: OrderDirection;
  field: SaleSortField;
};

export type SaleTranslatableContent = Node & {
  __typename?: 'SaleTranslatableContent';
  id: Scalars['ID'];
  name: Scalars['String'];
  sale?: Maybe<Sale>;
  translation?: Maybe<SaleTranslation>;
};


export type SaleTranslatableContentTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type SaleTranslate = {
  __typename?: 'SaleTranslate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sale?: Maybe<Sale>;
  translationErrors: Array<TranslationError>;
};

export type SaleTranslation = Node & {
  __typename?: 'SaleTranslation';
  id: Scalars['ID'];
  language: LanguageDisplay;
  name?: Maybe<Scalars['String']>;
};

export enum SaleType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE'
}

export type SaleUpdate = {
  __typename?: 'SaleUpdate';
  discountErrors: Array<DiscountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sale?: Maybe<Sale>;
};

export type SearchParameter = {
  code?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Type>;
  valueBoolean?: InputMaybe<Scalars['Boolean']>;
  valueDate?: InputMaybe<Scalars['Date']>;
  valueFloat?: InputMaybe<Scalars['Float']>;
  valueInteger?: InputMaybe<Scalars['Int']>;
  valueString?: InputMaybe<Scalars['String']>;
};

export type SelectedAttribute = {
  __typename?: 'SelectedAttribute';
  attribute: Attribute;
  values: Array<Maybe<AttributeValue>>;
};

export type SendOrderSubscriptionEvent = {
  __typename?: 'SendOrderSubscriptionEvent';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderErrors: Array<OrderError>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SendPushNotification = {
  __typename?: 'SendPushNotification';
  accountErrors: Array<NotificationError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  response?: Maybe<Scalars['DictType']>;
};

export type SeoInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SetOrderToOutForDelivery = {
  __typename?: 'SetOrderToOutForDelivery';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type SetPassword = {
  __typename?: 'SetPassword';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ShippingError = {
  __typename?: 'ShippingError';
  branches?: Maybe<Array<Scalars['ID']>>;
  code: ShippingErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum ShippingErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  MaxLessThanMin = 'MAX_LESS_THAN_MIN',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type ShippingMethod = Node & {
  __typename?: 'ShippingMethod';
  id: Scalars['ID'];
  maximumOrderPrice?: Maybe<Money>;
  maximumOrderWeight?: Maybe<Weight>;
  minimumOrderPrice?: Maybe<Money>;
  minimumOrderWeight?: Maybe<Weight>;
  name: Scalars['String'];
  price?: Maybe<Money>;
  translation?: Maybe<ShippingMethodTranslation>;
  type?: Maybe<ShippingMethodTypeEnum>;
};


export type ShippingMethodTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type ShippingMethodTranslatableContent = Node & {
  __typename?: 'ShippingMethodTranslatableContent';
  id: Scalars['ID'];
  name: Scalars['String'];
  shippingMethod?: Maybe<ShippingMethod>;
  translation?: Maybe<ShippingMethodTranslation>;
};


export type ShippingMethodTranslatableContentTranslationArgs = {
  languageCode: LanguageCodeEnum;
};

export type ShippingMethodTranslation = Node & {
  __typename?: 'ShippingMethodTranslation';
  id: Scalars['ID'];
  language: LanguageDisplay;
  name?: Maybe<Scalars['String']>;
};

export enum ShippingMethodTypeEnum {
  Price = 'PRICE',
  Weight = 'WEIGHT'
}

export type ShippingPriceBulkDelete = {
  __typename?: 'ShippingPriceBulkDelete';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  shippingErrors: Array<ShippingError>;
};

export type ShippingPriceCreate = {
  __typename?: 'ShippingPriceCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  shippingErrors: Array<ShippingError>;
  shippingMethod?: Maybe<ShippingMethod>;
  shippingZone?: Maybe<ShippingZone>;
};

export type ShippingPriceDelete = {
  __typename?: 'ShippingPriceDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  shippingErrors: Array<ShippingError>;
  shippingMethod?: Maybe<ShippingMethod>;
  shippingZone?: Maybe<ShippingZone>;
};

export type ShippingPriceInput = {
  maximumOrderPrice?: InputMaybe<Scalars['Decimal']>;
  maximumOrderWeight?: InputMaybe<Scalars['WeightScalar']>;
  minimumOrderPrice?: InputMaybe<Scalars['Decimal']>;
  minimumOrderWeight?: InputMaybe<Scalars['WeightScalar']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  shippingZone?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<ShippingMethodTypeEnum>;
};

export type ShippingPriceTranslate = {
  __typename?: 'ShippingPriceTranslate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  shippingMethod?: Maybe<ShippingMethod>;
  translationErrors: Array<TranslationError>;
};

export type ShippingPriceUpdate = {
  __typename?: 'ShippingPriceUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  shippingErrors: Array<ShippingError>;
  shippingMethod?: Maybe<ShippingMethod>;
  shippingZone?: Maybe<ShippingZone>;
};

export type ShippingZone = Node & {
  __typename?: 'ShippingZone';
  countries?: Maybe<Array<Maybe<CountryDisplay>>>;
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  priceRange?: Maybe<MoneyRange>;
  shippingMethods?: Maybe<Array<Maybe<ShippingMethod>>>;
};

export type ShippingZoneBulkDelete = {
  __typename?: 'ShippingZoneBulkDelete';
  count: Scalars['Int'];
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  shippingErrors: Array<ShippingError>;
};

export type ShippingZoneCountableConnection = {
  __typename?: 'ShippingZoneCountableConnection';
  edges: Array<ShippingZoneCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ShippingZoneCountableEdge = {
  __typename?: 'ShippingZoneCountableEdge';
  cursor: Scalars['String'];
  node: ShippingZone;
};

export type ShippingZoneCreate = {
  __typename?: 'ShippingZoneCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  shippingErrors: Array<ShippingError>;
  shippingZone?: Maybe<ShippingZone>;
};

export type ShippingZoneCreateInput = {
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  default?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ShippingZoneDelete = {
  __typename?: 'ShippingZoneDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  shippingErrors: Array<ShippingError>;
  shippingZone?: Maybe<ShippingZone>;
};

export type ShippingZoneUpdate = {
  __typename?: 'ShippingZoneUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  shippingErrors: Array<ShippingError>;
  shippingZone?: Maybe<ShippingZone>;
};

export type ShippingZoneUpdateInput = {
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  default?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type SiteSettings = H_Node & Node & {
  __typename?: 'SiteSettings';
  chargeTaxesOnShipping: Scalars['Boolean'];
  countries: Array<CountryDisplay>;
  currencies: Array<Maybe<Scalars['String']>>;
  defaultCurrency: Scalars['String'];
  defaultWeightUnit?: Maybe<WeightUnitsEnum>;
  deliveryMinutesTimeGapBeforeEachReminder?: Maybe<Scalars['Int']>;
  deliveryNumberOfReminders?: Maybe<Scalars['Int']>;
  displayGrossPrices: Scalars['Boolean'];
  enablePickup?: Maybe<Scalars['Boolean']>;
  homepageCollection?: Maybe<Collection>;
  id: Scalars['ID'];
  includeTaxesInPrices: Scalars['Boolean'];
  languages: Array<Maybe<LanguageDisplay>>;
  marketPlaceEnabled?: Maybe<Scalars['Boolean']>;
  marketplaceConsumedTimeoutAlert?: Maybe<Scalars['Int']>;
  marketplaceConsumedTimeoutSeconds?: Maybe<Scalars['Int']>;
  marketplaceDeliveredTimeoutAlert?: Maybe<Scalars['Int']>;
  marketplaceDeliveredTimeoutSeconds?: Maybe<Scalars['Int']>;
  marketplaceOrderPaymentTimeoutSeconds?: Maybe<Scalars['Int']>;
  marketplaceOutForDeliveryTimeoutAlert?: Maybe<Scalars['Int']>;
  marketplaceOutForDeliveryTimeoutSeconds?: Maybe<Scalars['Int']>;
  marketplaceReturnedTimeoutAlert?: Maybe<Scalars['Int']>;
  marketplaceReturnedTimeoutSeconds?: Maybe<Scalars['Int']>;
  marketplaceVendorResponseTimeoutAlert?: Maybe<Scalars['Int']>;
  marketplaceVendorResponseTimeoutSeconds?: Maybe<Scalars['Int']>;
  multiplePharmaciesDelivery?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orderAcceptanceTimeout?: Maybe<Scalars['Int']>;
  orderBaseDeliveryFee?: Maybe<Scalars['Float']>;
  orderDeliveryTimeout?: Maybe<Scalars['Int']>;
  orderDispensingTimeout?: Maybe<Scalars['Int']>;
  orderErxHubApprovalResubmitTimeout?: Maybe<Scalars['Int']>;
  orderErxHubApprovalTimeout?: Maybe<Scalars['Int']>;
  orderPaymentTimeout?: Maybe<Scalars['Int']>;
  paymentKafkaTopics?: Maybe<Array<Maybe<P_SubscriptionKafkaTopic>>>;
  phonePrefixes: Array<Maybe<Scalars['String']>>;
  scheduleDeliveryEnabled?: Maybe<Scalars['Boolean']>;
  timeZone?: Maybe<Scalars['Float']>;
  trackInventoryByDefault?: Maybe<Scalars['Boolean']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};


export type SiteSettingsCountriesArgs = {
  languageCode?: InputMaybe<LanguageCodeEnum>;
};

export type SiteSettingsError = {
  __typename?: 'SiteSettingsError';
  code: SiteSettingsErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum SiteSettingsErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  CannotFetchTaxRates = 'CANNOT_FETCH_TAX_RATES',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type SiteSettingsInput = {
  chargeTaxesOnShipping?: InputMaybe<Scalars['Boolean']>;
  defaultWeightUnit?: InputMaybe<WeightUnitsEnum>;
  deliveryMinutesTimeGapBeforeEachReminder?: InputMaybe<Scalars['Int']>;
  deliveryNumberOfReminders?: InputMaybe<Scalars['Int']>;
  displayGrossPrices?: InputMaybe<Scalars['Boolean']>;
  enablePickup?: InputMaybe<Scalars['Boolean']>;
  includeTaxesInPrices?: InputMaybe<Scalars['Boolean']>;
  marketPlaceEnabled?: InputMaybe<Scalars['Boolean']>;
  marketplaceConsumedTimeoutAlert?: InputMaybe<Scalars['Int']>;
  marketplaceConsumedTimeoutSeconds?: InputMaybe<Scalars['Int']>;
  marketplaceDeliveredTimeoutAlert?: InputMaybe<Scalars['Int']>;
  marketplaceDeliveredTimeoutSeconds?: InputMaybe<Scalars['Int']>;
  marketplaceOrderPaymentTimeoutSeconds?: InputMaybe<Scalars['Int']>;
  marketplaceOutForDeliveryTimeoutAlert?: InputMaybe<Scalars['Int']>;
  marketplaceOutForDeliveryTimeoutSeconds?: InputMaybe<Scalars['Int']>;
  marketplaceReturnedTimeoutAlert?: InputMaybe<Scalars['Int']>;
  marketplaceReturnedTimeoutSeconds?: InputMaybe<Scalars['Int']>;
  marketplaceVendorResponseTimeoutAlert?: InputMaybe<Scalars['Int']>;
  marketplaceVendorResponseTimeoutSeconds?: InputMaybe<Scalars['Int']>;
  multiplePharmaciesDelivery?: InputMaybe<Scalars['Boolean']>;
  orderAcceptanceTimeout?: InputMaybe<Scalars['Int']>;
  orderBaseDeliveryFee?: InputMaybe<Scalars['Decimal']>;
  orderDeliveryTimeout?: InputMaybe<Scalars['Int']>;
  orderDispensingTimeout?: InputMaybe<Scalars['Int']>;
  orderErxHubApprovalResubmitTimeout?: InputMaybe<Scalars['Int']>;
  orderErxHubApprovalTimeout?: InputMaybe<Scalars['Int']>;
  orderPaymentTimeout?: InputMaybe<Scalars['Int']>;
  scheduleDeliveryEnabled?: InputMaybe<Scalars['Boolean']>;
  trackInventoryByDefault?: InputMaybe<Scalars['Boolean']>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type SiteSettingsUpdate = {
  __typename?: 'SiteSettingsUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  siteSettings?: Maybe<SiteSettings>;
  siteSettingsErrors: Array<SiteSettingsError>;
};

export type Stock = Node & {
  __typename?: 'Stock';
  branch?: Maybe<Branch>;
  id: Scalars['ID'];
  productVariant: ProductVariant;
  quantity: Scalars['Int'];
  quantityAllocated: Scalars['Int'];
};

export enum StockAvailability {
  InStock = 'IN_STOCK',
  OutOfStock = 'OUT_OF_STOCK'
}

export type StockCountableConnection = {
  __typename?: 'StockCountableConnection';
  edges: Array<StockCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StockCountableEdge = {
  __typename?: 'StockCountableEdge';
  cursor: Scalars['String'];
  node: Stock;
};

export type StockError = {
  __typename?: 'StockError';
  code: StockErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum StockErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type StockFilterInput = {
  quantity?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
};

export type StockInput = {
  branch: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Int']>;
};

export type SubList = {
  __typename?: 'SubList';
  display?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  field?: Maybe<Field>;
  id?: Maybe<Scalars['ID']>;
};

export type SubListConnection = {
  __typename?: 'SubListConnection';
  edges?: Maybe<Array<Maybe<SubListEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubListEdge = {
  __typename?: 'SubListEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SubList>;
};

export type SubListInput = {
  display?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<FieldInput>;
  id?: InputMaybe<Scalars['ID']>;
};

export type SubListItem = {
  __typename?: 'SubListItem';
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  id?: Maybe<Scalars['ID']>;
};

export type SubListItemConnection = {
  __typename?: 'SubListItemConnection';
  edges?: Maybe<Array<Maybe<SubListItemEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubListItemEdge = {
  __typename?: 'SubListItemEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SubListItem>;
};

export type SubListItemInput = {
  code?: InputMaybe<Scalars['String']>;
  display?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export enum SubListItemSortField {
  Code = 'CODE',
  Display = 'DISPLAY'
}

export type SubListItemSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: SubListItemSortField;
};

export enum SubListSortField {
  Display = 'DISPLAY',
  Field = 'FIELD'
}

export type SubListSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: SubListSortField;
};

export type Subscription = {
  __typename?: 'Subscription';
  medicalDeliveryRequestStatusChanged?: Maybe<MedicalDeliveryRequestStatusChanged>;
  messageSent?: Maybe<MessageSent>;
  needActionCodeSystems?: Maybe<NeedActionCodeSystems>;
  notificationCreated?: Maybe<NotificationCreated>;
  orderDeleted?: Maybe<OrderDeleted>;
  orderStatusChanged?: Maybe<OrderStatusChange>;
  prescriptionStatusChanged?: Maybe<PrescriptionStatusChanged>;
};


export type SubscriptionNeedActionCodeSystemsArgs = {
  token: Scalars['String'];
};

export type SubscriptionError = {
  __typename?: 'SubscriptionError';
  code: SubscriptionErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum SubscriptionErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type SubscriptionFilterInput = {
  validFrom?: InputMaybe<Scalars['DateTime']>;
  validTill?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionInput = {
  fixedCostAmount?: InputMaybe<Scalars['Decimal']>;
  fixedOrderCostAmount?: InputMaybe<Scalars['Decimal']>;
  fixedOrderPercentage?: InputMaybe<Scalars['Decimal']>;
  plan?: InputMaybe<Scalars['ID']>;
  vendor?: InputMaybe<Scalars['ID']>;
};

export type Survey = {
  __typename?: 'Survey';
  description?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Question>>;
  status?: Maybe<SurveyStatus>;
};

export type SurveyAnswerInput = {
  answers?: InputMaybe<Array<InputMaybe<QuestionAnswerInput>>>;
  orderId: Scalars['ID'];
  survey: Scalars['ID'];
};

export type SurveyEdge = {
  __typename?: 'SurveyEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Survey>;
};

export enum SurveyErrorCodes {
  SurveyDuplicate = 'SURVEY_DUPLICATE',
  SurveyInvalidDateFormat = 'SURVEY_INVALID_DATE_FORMAT',
  SurveyMakerUnknownType = 'SURVEY_MAKER_UNKNOWN_TYPE',
  SurveyNoneNegativePageSize = 'SURVEY_NONE_NEGATIVE_PAGE_SIZE',
  SurveyNotFound = 'SURVEY_NOT_FOUND',
  SurveyPermissionDenied = 'SURVEY_PERMISSION_DENIED',
  SurveyUnknown = 'SURVEY_UNKNOWN',
  SurveyValidation = 'SURVEY_VALIDATION'
}

export type SurveyGraphqlError = {
  __typename?: 'SurveyGraphqlError';
  code?: Maybe<SurveyErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum SurveyStatus {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type SurveyUserAnswer = {
  __typename?: 'SurveyUserAnswer';
  createdDate?: Maybe<Scalars['Date']>;
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  id: Scalars['ID'];
  questionAnswers?: Maybe<Array<Maybe<QuestionAnswer>>>;
};

export enum TaxRateType {
  Accommodation = 'ACCOMMODATION',
  AdmissionToCulturalEvents = 'ADMISSION_TO_CULTURAL_EVENTS',
  AdmissionToEntertainmentEvents = 'ADMISSION_TO_ENTERTAINMENT_EVENTS',
  AdmissionToSportingEvents = 'ADMISSION_TO_SPORTING_EVENTS',
  Advertising = 'ADVERTISING',
  AgriculturalSupplies = 'AGRICULTURAL_SUPPLIES',
  BabyFoodstuffs = 'BABY_FOODSTUFFS',
  Bikes = 'BIKES',
  Books = 'BOOKS',
  ChildrensClothing = 'CHILDRENS_CLOTHING',
  DomesticFuel = 'DOMESTIC_FUEL',
  DomesticServices = 'DOMESTIC_SERVICES',
  EBooks = 'E_BOOKS',
  Foodstuffs = 'FOODSTUFFS',
  Hotels = 'HOTELS',
  Medical = 'MEDICAL',
  Newspapers = 'NEWSPAPERS',
  PassengerTransport = 'PASSENGER_TRANSPORT',
  Pharmaceuticals = 'PHARMACEUTICALS',
  PropertyRenovations = 'PROPERTY_RENOVATIONS',
  Restaurants = 'RESTAURANTS',
  SocialHousing = 'SOCIAL_HOUSING',
  Standard = 'STANDARD',
  Water = 'WATER',
  Wine = 'WINE'
}

export type TaxType = {
  __typename?: 'TaxType';
  description?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
};

export type TaxedMoney = {
  __typename?: 'TaxedMoney';
  currency: Scalars['String'];
  gross: Money;
  net: Money;
  tax: Money;
};

export type TerminateVendorSubscription = {
  __typename?: 'TerminateVendorSubscription';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export enum TerminologyErrorCodes {
  InvalidOrExpiredToken = 'INVALID_OR_EXPIRED_TOKEN',
  TerminologyClinicianNotFound = 'TERMINOLOGY_CLINICIAN_NOT_FOUND',
  TerminologyConceptDefinitionListNotFound = 'TERMINOLOGY_CONCEPT_DEFINITION_LIST_NOT_FOUND',
  TerminologyDuplicateColumn = 'TERMINOLOGY_DUPLICATE_COLUMN',
  TerminologyDuplicateProperty = 'TERMINOLOGY_DUPLICATE_PROPERTY',
  TerminologyDuplicateRowData = 'TERMINOLOGY_DUPLICATE_ROW_DATA',
  TerminologyDuplicateVersion = 'TERMINOLOGY_DUPLICATE_VERSION',
  TerminologyEmptyFile = 'TERMINOLOGY_EMPTY_FILE',
  TerminologyFileServiceNotReachable = 'TERMINOLOGY_FILE_SERVICE_NOT_REACHABLE',
  TerminologyInvalidDateFormat = 'TERMINOLOGY_INVALID_DATE_FORMAT',
  TerminologyInvalidFileExtension = 'TERMINOLOGY_INVALID_FILE_EXTENSION',
  TerminologyInvalidProcessingStatus = 'TERMINOLOGY_INVALID_PROCESSING_STATUS',
  TerminologyInvalidToken = 'TERMINOLOGY_INVALID_TOKEN',
  TerminologyListDefinitionNotFound = 'TERMINOLOGY_LIST_Definition_NOT_FOUND',
  TerminologyListItemNotFound = 'TERMINOLOGY_LIST_Item_NOT_FOUND',
  TerminologyListNotFound = 'TERMINOLOGY_LIST_NOT_FOUND',
  TerminologyMandatoryConceptPropertyException = 'TERMINOLOGY_MANDATORY_CONCEPT_PROPERTY_EXCEPTION',
  TerminologyNoneDraftCodeSystem = 'TERMINOLOGY_NONE_DRAFT_CODE_SYSTEM',
  TerminologyNoneEditableConceptDefinition = 'TERMINOLOGY_NONE_EDITABLE_CONCEPT_DEFINITION',
  TerminologyNoneEditableList = 'TERMINOLOGY_NONE_EDITABLE_LIST',
  TerminologyNoneNegativePageSize = 'TERMINOLOGY_NONE_NEGATIVE_PAGE_SIZE',
  TerminologyNoneUnderReviewCodeSystem = 'TERMINOLOGY_NONE_UNDER_REVIEW_CODE_SYSTEM',
  TerminologyNotApprovedCodeSystem = 'TERMINOLOGY_NOT_APPROVED_CODE_SYSTEM',
  TerminologyNoActiveCodeSystem = 'TERMINOLOGY_NO_ACTIVE_CODE_SYSTEM',
  TerminologyOldVersion = 'TERMINOLOGY_OLD_VERSION',
  TerminologyPermissionDenied = 'TERMINOLOGY_PERMISSION_DENIED',
  TerminologyUnknown = 'TERMINOLOGY_UNKNOWN',
  TerminologyUnknownType = 'TERMINOLOGY_UNKNOWN_TYPE'
}

export type TerminologyGraphqlError = {
  __typename?: 'TerminologyGraphqlError';
  code?: Maybe<TerminologyErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type TicketPrescriptionDispense = {
  __typename?: 'TicketPrescriptionDispense';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type TicketPrescriptionDispenseInput = {
  branchHealthLicenseNumber?: InputMaybe<Scalars['String']>;
  csFileName?: InputMaybe<Scalars['String']>;
  csTicketId: Scalars['String'];
  csUserId?: InputMaybe<Scalars['ID']>;
  deliveryDate?: InputMaybe<Scalars['Date']>;
  deliveryTimeSlot?: InputMaybe<Scalars['ID']>;
  isDeliveryRequested: Scalars['Boolean'];
  nationalId: Scalars['String'];
  referenceNumber: Scalars['String'];
  shippingAddress: AddressInput;
};

export type TimeRange = {
  __typename?: 'TimeRange';
  closeTime?: Maybe<Scalars['Time']>;
  openTime?: Maybe<Scalars['Time']>;
};

export type TimeRangeInput = {
  closeTime: Scalars['Time'];
  openTime: Scalars['Time'];
};

export type ToGlobalId = {
  __typename?: 'ToGlobalId';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  id?: Maybe<Scalars['ID']>;
};

export type TradeDrug = {
  __typename?: 'TradeDrug';
  code: Scalars['String'];
  dictionaryURL?: Maybe<Scalars['String']>;
  display: Scalars['String'];
  genericDisplay: Scalars['String'];
  granularUnit?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isHazardous?: Maybe<Scalars['Boolean']>;
  isHighAlert?: Maybe<Scalars['Boolean']>;
  isLASA?: Maybe<Scalars['Boolean']>;
  largeIconURL?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  smallIconURL?: Maybe<Scalars['String']>;
  unitID?: Maybe<Scalars['Int']>;
};

export type TransactionReport = {
  __typename?: 'TransactionReport';
  buildNumber?: Maybe<Scalars['String']>;
  lastPayment?: Maybe<PaymentResponse>;
  lastPaymentPending?: Maybe<Scalars['Boolean']>;
  lastPaymentSucceeded?: Maybe<Scalars['Boolean']>;
  ndc?: Maybe<Scalars['String']>;
  payments?: Maybe<Array<Maybe<PaymentResponse>>>;
  result?: Maybe<PaymentResponseResult>;
  timestamp?: Maybe<Scalars['String']>;
  timestampInstant?: Maybe<Scalars['Instant']>;
};

export type TranslatableItem = AttributeTranslatableContent | AttributeValueTranslatableContent | CategoryTranslatableContent | CollectionTranslatableContent | ProductTranslatableContent | ProductVariantTranslatableContent | SaleTranslatableContent | ShippingMethodTranslatableContent;

export type TranslatableItemConnection = {
  __typename?: 'TranslatableItemConnection';
  edges: Array<TranslatableItemEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TranslatableItemEdge = {
  __typename?: 'TranslatableItemEdge';
  cursor: Scalars['String'];
  node: TranslatableItem;
};

export enum TranslatableKinds {
  Attribute = 'ATTRIBUTE',
  AttributeValue = 'ATTRIBUTE_VALUE',
  Category = 'CATEGORY',
  Collection = 'COLLECTION',
  MenuItem = 'MENU_ITEM',
  Page = 'PAGE',
  Product = 'PRODUCT',
  Sale = 'SALE',
  ShippingMethod = 'SHIPPING_METHOD',
  Variant = 'VARIANT'
}

export type TranslationError = {
  __typename?: 'TranslationError';
  code: TranslationErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum TranslationErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED'
}

export type TranslationInput = {
  description?: InputMaybe<Scalars['String']>;
  descriptionJson?: InputMaybe<Scalars['JSONString']>;
  name?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
};

export enum Type {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  String = 'STRING'
}

export type UnAssignUserFromBranch = {
  __typename?: 'UnAssignUserFromBranch';
  branchErrors: Array<BranchError>;
  branchUser?: Maybe<BranchUser>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type UploadAttachment = {
  __typename?: 'UploadAttachment';
  attachment?: Maybe<Attachment>;
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type User = Node & {
  __typename?: 'User';
  addresses?: Maybe<Array<Maybe<Address>>>;
  appRole?: Maybe<AppRoleTypes>;
  appType?: Maybe<AppTypes>;
  avatar?: Maybe<Scalars['String']>;
  branches?: Maybe<Array<Maybe<Branch>>>;
  checkout?: Maybe<Checkout>;
  dateJoined: Scalars['DateTime'];
  defaultBillingAddress?: Maybe<Address>;
  defaultShippingAddress?: Maybe<Address>;
  editableGroups?: Maybe<Array<Maybe<Group>>>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  events?: Maybe<Array<Maybe<CustomerEvent>>>;
  firstName?: Maybe<Scalars['String']>;
  healthLicenseEndDate?: Maybe<Scalars['Date']>;
  healthLicenseNumber?: Maybe<Scalars['String']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isStaff?: Maybe<Scalars['Boolean']>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  isVendorAdmin?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<Message>;
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated this field will be removed in future versions.  */
  meetingPlatformId?: Maybe<Scalars['String']>;
  messages?: Maybe<MessageCountableConnection>;
  metabaseIFrameUrl?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  mobileVerified: Scalars['Boolean'];
  nationalId?: Maybe<Scalars['String']>;
  needActionCodeSystems?: Maybe<NeedActionCodeSystems>;
  note?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationCountableConnection>;
  orders?: Maybe<OrderCountableConnection>;
  patient?: Maybe<Patient>;
  paymentReceipts: PaymentReceiptCountableConnection;
  permissionGroups?: Maybe<Array<Maybe<Group>>>;
  prescriptions?: Maybe<PrescriptionCountableConnection>;
  totalOrdersAmount?: Maybe<TaxedMoney>;
  unReadMessagesCount?: Maybe<Scalars['Int']>;
  unReadMessagesCountWithBranch?: Maybe<Scalars['Int']>;
  unReadNotificationsCount?: Maybe<Scalars['Int']>;
  unReadNotificationsCountByType?: Maybe<Scalars['DictType']>;
  userPermissions?: Maybe<Array<Maybe<UserPermission>>>;
  vendor?: Maybe<Vendor>;
  wallet?: Maybe<Wallet>;
  wishlist?: Maybe<Wishlist>;
};


export type UserMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  branchId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<NotificationFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UserOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<OrderSortingInput>;
};


export type UserPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UserPrescriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PrescriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<PrescriptionSortingInput>;
};


export type UserUnReadMessagesCountWithBranchArgs = {
  branchId: Scalars['ID'];
};

export type UserBranchInput = {
  branch?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type UserCountableConnection = {
  __typename?: 'UserCountableConnection';
  edges: Array<UserCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserCountableEdge = {
  __typename?: 'UserCountableEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UserCreate = {
  __typename?: 'UserCreate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserDelete = {
  __typename?: 'UserDelete';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserDevice = Node & {
  __typename?: 'UserDevice';
  app: Scalars['String'];
  appVersion?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deviceName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isOnline: Scalars['Boolean'];
  language: UserDeviceLanguage;
  modelName?: Maybe<Scalars['String']>;
  modified: Scalars['DateTime'];
  os: Scalars['String'];
  osVersion?: Maybe<Scalars['String']>;
  pushToken: Scalars['String'];
  udid: Scalars['String'];
  userId: Scalars['Int'];
};

export enum UserDeviceLanguage {
  Ar = 'AR',
  Az = 'AZ',
  Bg = 'BG',
  Bn = 'BN',
  Ca = 'CA',
  Cs = 'CS',
  Da = 'DA',
  De = 'DE',
  El = 'EL',
  En = 'EN',
  Es = 'ES',
  EsCo = 'ES_CO',
  Et = 'ET',
  Fa = 'FA',
  Fi = 'FI',
  Fr = 'FR',
  Hi = 'HI',
  Hu = 'HU',
  Hy = 'HY',
  Id = 'ID',
  Is = 'IS',
  It = 'IT',
  Ja = 'JA',
  Ko = 'KO',
  Lt = 'LT',
  Mn = 'MN',
  Nb = 'NB',
  Nl = 'NL',
  Pl = 'PL',
  Pt = 'PT',
  PtBr = 'PT_BR',
  Ro = 'RO',
  Ru = 'RU',
  Sk = 'SK',
  Sl = 'SL',
  Sq = 'SQ',
  Sr = 'SR',
  Sv = 'SV',
  Sw = 'SW',
  Ta = 'TA',
  Th = 'TH',
  Tr = 'TR',
  Uk = 'UK',
  Vi = 'VI',
  ZhHans = 'ZH_HANS',
  ZhHant = 'ZH_HANT'
}

export type UserDeviceRegister = {
  __typename?: 'UserDeviceRegister';
  accountErrors: Array<NotificationError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  userDevice?: Maybe<UserDevice>;
};

export type UserDeviceRegisterInput = {
  app: AppTypes;
  appVersion?: InputMaybe<Scalars['String']>;
  deviceName?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  modelName?: InputMaybe<Scalars['String']>;
  os: Scalars['String'];
  osVersion?: InputMaybe<Scalars['String']>;
  pushToken: Scalars['String'];
  udid: Scalars['String'];
};

export type UserDeviceUnRegister = {
  __typename?: 'UserDeviceUnRegister';
  accountErrors: Array<NotificationError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  userDevice?: Maybe<UserDevice>;
};

export type UserDeviceUnRegisterInput = {
  app: AppTypes;
  udid: Scalars['String'];
};

export type UserFilterInput = {
  appRole?: InputMaybe<AppRoleTypes>;
  appType?: InputMaybe<AppTypes>;
  branchId?: InputMaybe<Scalars['String']>;
  dateJoined?: InputMaybe<DateRangeInput>;
  joinedPeriod?: InputMaybe<ReportingPeriod>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type UserPermission = {
  __typename?: 'UserPermission';
  code: PermissionEnum;
  name: Scalars['String'];
  sourcePermissionGroups?: Maybe<Array<Group>>;
};


export type UserPermissionSourcePermissionGroupsArgs = {
  userId: Scalars['ID'];
};

export enum UserSortField {
  DateJoined = 'DATE_JOINED',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastMessageSent = 'LAST_MESSAGE_SENT',
  LastName = 'LAST_NAME',
  OrderCount = 'ORDER_COUNT'
}

export type UserSortingInput = {
  direction: OrderDirection;
  field: UserSortField;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

export type UserUpdate = {
  __typename?: 'UserUpdate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserUpdateInput = {
  addGroups?: InputMaybe<Array<Scalars['ID']>>;
  appRole?: InputMaybe<AppRoleTypes>;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  firstName?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  removeGroups?: InputMaybe<Array<Scalars['ID']>>;
};

export type ValidatePrescription = {
  __typename?: 'ValidatePrescription';
  allowedDeliveryTypes?: Maybe<Array<Maybe<DeliveryTypes>>>;
  allowedFastDeliveryTypes?: Maybe<Array<Maybe<DeliveryTypes>>>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  prescription?: Maybe<Prescription>;
  prescriptionErrors: Array<PrescriptionError>;
};

export type ValidatePrescriptionInput = {
  patientIdentifier?: InputMaybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
};

export type VariantPricingInfo = {
  __typename?: 'VariantPricingInfo';
  discount?: Maybe<TaxedMoney>;
  discountLocalCurrency?: Maybe<TaxedMoney>;
  onSale?: Maybe<Scalars['Boolean']>;
  price?: Maybe<TaxedMoney>;
  priceLocalCurrency?: Maybe<TaxedMoney>;
  priceUndiscounted?: Maybe<TaxedMoney>;
};

export enum VariantReviewSortField {
  Date = 'DATE',
  Helpfulness = 'HELPFULNESS',
  Rating = 'RATING'
}

export enum VariantSortField {
  Category = 'CATEGORY',
  Modified = 'MODIFIED',
  Name = 'NAME',
  Newest = 'NEWEST',
  Popular = 'POPULAR',
  ProductType = 'PRODUCT_TYPE'
}

export type VariantSortingInput = {
  direction: OrderDirection;
  field: VariantSortField;
};

export type Vendor = Node & {
  __typename?: 'Vendor';
  address?: Maybe<Address>;
  approved: Scalars['Boolean'];
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<User>;
  authorityCode?: Maybe<Scalars['String']>;
  averageRating: Scalars['Float'];
  backGroundImage?: Maybe<Scalars['String']>;
  bankInfo?: Maybe<VendorBankInfo>;
  branches?: Maybe<BranchCountableConnection>;
  cluster?: Maybe<Scalars['String']>;
  commercialRegistrationNumber: Scalars['String'];
  contactEmail?: Maybe<Scalars['String']>;
  contactMobileNumber?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  customerfavoritevendor: CustomerFavoriteVendorCountableConnection;
  deliveryMinFrom: Scalars['Int'];
  deliveryMinTo: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  division?: Maybe<Division>;
  favoriteCustomers: UserCountableConnection;
  fixedCosts?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['String']>;
  hasMultipleBranches: Scalars['Boolean'];
  hasOwnDrivers: Scalars['Boolean'];
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<VendorImage>>>;
  isActive: Scalars['Boolean'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  isIntegrated: Scalars['Boolean'];
  isShippingFree: Scalars['Boolean'];
  isVip: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  managersContactInfo?: Maybe<VendorManagersContactInfo>;
  maxNumberOfUsers?: Maybe<Scalars['Int']>;
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  nameAr?: Maybe<Scalars['String']>;
  nationalId: Scalars['String'];
  onboardingStatus?: Maybe<Scalars['String']>;
  operationStatus?: Maybe<Scalars['String']>;
  orderMinimumFreeDelivery: Scalars['Float'];
  ordersCount?: Maybe<Scalars['Int']>;
  orgIdNhic?: Maybe<Scalars['String']>;
  ownerName: Scalars['String'];
  paymentReceipts: PaymentReceiptCountableConnection;
  priceRange?: Maybe<PriceRangeEnum>;
  raters: UserCountableConnection;
  ratings?: Maybe<VendorRatingCountableConnection>;
  ratingsSum: Scalars['Float'];
  rejections?: Maybe<VendorRejectionReasonCountableConnection>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  subscriptions: VendorSubscriptionCountableConnection;
  taxLicenseNumber: Scalars['String'];
  totalOrdersCount: Scalars['Float'];
  totalRatings: Scalars['Int'];
  tradeName: Scalars['String'];
  type?: Maybe<VendorType>;
  users?: Maybe<Array<Maybe<User>>>;
  virtualGroup?: Maybe<Scalars['String']>;
};


export type VendorBranchesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BranchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<BranchOrder>;
  userLocation?: InputMaybe<LocationInput>;
};


export type VendorCustomerfavoritevendorArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VendorFavoriteCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VendorFixedCostsArgs = {
  dateFrom: Scalars['Date'];
  dateTill: Scalars['Date'];
};


export type VendorPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VendorRatersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VendorRatingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VendorRejectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VendorSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VendorAdminInput = {
  acceptsDelivery?: InputMaybe<Scalars['Boolean']>;
  acceptsPickup?: InputMaybe<Scalars['Boolean']>;
  addressInput?: InputMaybe<AddressInput>;
  authorityCode?: InputMaybe<Scalars['String']>;
  backGroundImage?: InputMaybe<Scalars['String']>;
  bankInfo?: InputMaybe<VendorBankInfoInput>;
  branchType?: InputMaybe<VendorBranchTypeEnum>;
  branchWorkingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  branchWorkingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
  cluster?: InputMaybe<Scalars['String']>;
  commercialRegistrationNumber?: InputMaybe<Scalars['String']>;
  contactMobileNumber?: InputMaybe<Scalars['String']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']>;
  deliveryMinFrom?: InputMaybe<Scalars['Int']>;
  deliveryMinTo?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  group?: InputMaybe<Scalars['String']>;
  hasMultipleBranches?: InputMaybe<Scalars['Boolean']>;
  hasOwnDrivers?: InputMaybe<Scalars['Boolean']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isVip?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  managersContactInfo?: InputMaybe<VendorManagersContactInfoInput>;
  maxNumberOfUsers?: InputMaybe<Scalars['Int']>;
  mobileNumber: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  nameAr?: InputMaybe<Scalars['String']>;
  nationalId?: InputMaybe<Scalars['String']>;
  operationStatus?: InputMaybe<Scalars['String']>;
  orgIdNhic?: InputMaybe<Scalars['String']>;
  ownerName?: InputMaybe<Scalars['String']>;
  priceRange?: InputMaybe<PriceRangeEnum>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<SubscriptionInput>;
  taxLicenseNumber?: InputMaybe<Scalars['String']>;
  tradeName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<VendorTypeEnum>;
  virtualGroup?: InputMaybe<Scalars['String']>;
};

export type VendorBankInfo = Node & {
  __typename?: 'VendorBankInfo';
  accountName?: Maybe<Scalars['String']>;
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
  iban: Scalars['String'];
  id: Scalars['ID'];
  vendor: Vendor;
};

export type VendorBankInfoInput = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
};

export enum VendorBranchTypeEnum {
  MedicalDeliveryOnly = 'MEDICAL_DELIVERY_ONLY',
  PartnerWarehouse = 'PARTNER_WAREHOUSE',
  Pharmacy = 'PHARMACY',
  Phc = 'PHC',
  ReferencePharmacy = 'REFERENCE_PHARMACY'
}

export type VendorCountableConnection = {
  __typename?: 'VendorCountableConnection';
  edges: Array<VendorCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VendorCountableEdge = {
  __typename?: 'VendorCountableEdge';
  cursor: Scalars['String'];
  node: Vendor;
};

export type VendorCreate = {
  __typename?: 'VendorCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorDelete = {
  __typename?: 'VendorDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorError = {
  __typename?: 'VendorError';
  code: VendorErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum VendorErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  NotVendorsImage = 'NOT_VENDORS_IMAGE',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type VendorFilterInput = {
  approved?: InputMaybe<Scalars['Boolean']>;
  contactMobileNumber?: InputMaybe<Scalars['String']>;
  contactMobileNumber_Icontains?: InputMaybe<Scalars['String']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']>;
  contactPhoneNumber_Icontains?: InputMaybe<Scalars['String']>;
  delivery?: InputMaybe<DecimalRangeInput>;
  description?: InputMaybe<Scalars['String']>;
  description_Icontains?: InputMaybe<Scalars['String']>;
  hasChatWith?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isFavorite?: InputMaybe<Scalars['Boolean']>;
  isVip?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<DistanceFilterInput>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  nationalId?: InputMaybe<Scalars['String']>;
  nationalId_Icontains?: InputMaybe<Scalars['String']>;
  ownerName?: InputMaybe<Scalars['String']>;
  ownerName_Icontains?: InputMaybe<Scalars['String']>;
  priceRange?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<DecimalRangeInput>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  slug_Icontains?: InputMaybe<Scalars['String']>;
  subscriptionExpiry?: InputMaybe<DateRangeInput>;
  type?: InputMaybe<Array<InputMaybe<VendorTypeEnum>>>;
};

export type VendorImage = Node & {
  __typename?: 'VendorImage';
  alt: Scalars['String'];
  id: Scalars['ID'];
  sortOrder?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};


export type VendorImageUrlArgs = {
  size?: InputMaybe<Scalars['Int']>;
};

export type VendorImageCreate = {
  __typename?: 'VendorImageCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<VendorImage>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageCreateInput = {
  alt?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  vendor: Scalars['ID'];
};

export type VendorImageDelete = {
  __typename?: 'VendorImageDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<VendorImage>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageReorder = {
  __typename?: 'VendorImageReorder';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  images?: Maybe<Array<Maybe<VendorImage>>>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageUpdate = {
  __typename?: 'VendorImageUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<VendorImage>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageUpdateInput = {
  alt?: InputMaybe<Scalars['String']>;
};

export type VendorInput = {
  acceptsDelivery?: InputMaybe<Scalars['Boolean']>;
  acceptsPickup?: InputMaybe<Scalars['Boolean']>;
  addressInput?: InputMaybe<AddressInput>;
  backGroundImage?: InputMaybe<Scalars['String']>;
  bankInfo?: InputMaybe<VendorBankInfoInput>;
  branchType?: InputMaybe<VendorBranchTypeEnum>;
  branchWorkingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  branchWorkingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
  commercialRegistrationNumber?: InputMaybe<Scalars['String']>;
  contactMobileNumber?: InputMaybe<Scalars['String']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']>;
  deliveryMinFrom?: InputMaybe<Scalars['Int']>;
  deliveryMinTo?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  hasMultipleBranches?: InputMaybe<Scalars['Boolean']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']>;
  logo?: InputMaybe<Scalars['String']>;
  managersContactInfo?: InputMaybe<VendorManagersContactInfoInput>;
  mobileNumber: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  nameAr?: InputMaybe<Scalars['String']>;
  nationalId?: InputMaybe<Scalars['String']>;
  ownerName?: InputMaybe<Scalars['String']>;
  priceRange?: InputMaybe<PriceRangeEnum>;
  taxLicenseNumber?: InputMaybe<Scalars['String']>;
  tradeName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<VendorTypeEnum>;
};

export type VendorInvoice = Node & {
  __typename?: 'VendorInvoice';
  applePaymentBankFees?: Maybe<Scalars['Float']>;
  attachment?: Maybe<Attachment>;
  attachmentId?: Maybe<Scalars['Int']>;
  branchInvoices?: Maybe<Array<Maybe<BranchInvoice>>>;
  created: Scalars['DateTime'];
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  isPaid: Scalars['Boolean'];
  isReady: Scalars['Boolean'];
  madaPaymentBankFees?: Maybe<Scalars['Float']>;
  modified: Scalars['DateTime'];
  netAmountAfterVat?: Maybe<Scalars['Float']>;
  netAmountBeforeVat?: Maybe<Scalars['Float']>;
  number: Scalars['String'];
  numberOfBranches?: Maybe<Scalars['Int']>;
  orders: OrderCountableConnection;
  serviceCommissionPercentage?: Maybe<Scalars['Float']>;
  totalCashOrders?: Maybe<Scalars['Float']>;
  totalCreditOrders?: Maybe<Scalars['Float']>;
  totalOrders?: Maybe<Scalars['Int']>;
  totalPaidShippingPrice?: Maybe<Scalars['Float']>;
  totalServiceCommission?: Maybe<Scalars['Float']>;
  totalShippingPrice?: Maybe<Scalars['Float']>;
  totalWalletOrders?: Maybe<Scalars['Float']>;
  transferId?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Float']>;
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars['Int']>;
  visaPaymentBankFees?: Maybe<Scalars['Float']>;
};


export type VendorInvoiceOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VendorInvoiceCountableConnection = {
  __typename?: 'VendorInvoiceCountableConnection';
  edges: Array<VendorInvoiceCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VendorInvoiceCountableEdge = {
  __typename?: 'VendorInvoiceCountableEdge';
  cursor: Scalars['String'];
  node: VendorInvoice;
};

export type VendorInvoiceCreate = {
  __typename?: 'VendorInvoiceCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  invoiceErrors: Array<InvoiceError>;
  vendorInvoice?: Maybe<VendorInvoice>;
};

export type VendorInvoiceFilterInput = {
  endDate?: InputMaybe<DateRangeInput>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  isReady?: InputMaybe<Scalars['Boolean']>;
  number?: InputMaybe<Scalars['String']>;
  number_Icontains?: InputMaybe<Scalars['String']>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type VendorInvoiceInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  vendor?: InputMaybe<Scalars['ID']>;
};

export type VendorInvoiceOrder = {
  direction: OrderDirection;
  field?: InputMaybe<VendorInvoiceOrderField>;
};

export enum VendorInvoiceOrderField {
  Created = 'CREATED',
  Date = 'DATE',
  IsPaid = 'IS_PAID',
  IsReady = 'IS_READY',
  Number = 'NUMBER'
}

export type VendorInvoiceUpdate = {
  __typename?: 'VendorInvoiceUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  invoiceErrors: Array<InvoiceError>;
  vendorInvoice?: Maybe<VendorInvoice>;
};

export type VendorInvoiceUpdateInput = {
  attachment: Scalars['ID'];
  isReady: Scalars['Boolean'];
  transferId: Scalars['String'];
};

export type VendorManagersContactInfo = Node & {
  __typename?: 'VendorManagersContactInfo';
  financialManagerEmail?: Maybe<Scalars['String']>;
  financialManagerMobileNumber?: Maybe<Scalars['String']>;
  financialManagerName?: Maybe<Scalars['String']>;
  generalManagerEmail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  purchasingManagerEmail?: Maybe<Scalars['String']>;
  purchasingManagerMobileNumber?: Maybe<Scalars['String']>;
  purchasingManagerName?: Maybe<Scalars['String']>;
  vendor: Vendor;
};

export type VendorManagersContactInfoInput = {
  financialManagerEmail?: InputMaybe<Scalars['String']>;
  financialManagerMobileNumber?: InputMaybe<Scalars['String']>;
  financialManagerName?: InputMaybe<Scalars['String']>;
  generalManagerEmail?: InputMaybe<Scalars['String']>;
  purchasingManagerEmail?: InputMaybe<Scalars['String']>;
  purchasingManagerMobileNumber?: InputMaybe<Scalars['String']>;
  purchasingManagerName?: InputMaybe<Scalars['String']>;
};

export type VendorMarkInvoiceAsPaid = {
  __typename?: 'VendorMarkInvoiceAsPaid';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  invoiceErrors: Array<InvoiceError>;
  vendorInvoice?: Maybe<VendorInvoice>;
};

export type VendorMarkMessagesAsSeen = {
  __typename?: 'VendorMarkMessagesAsSeen';
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  updatedCount?: Maybe<Scalars['Int']>;
};

export type VendorMessageInput = {
  attachments?: InputMaybe<Array<InputMaybe<MessageAttachmentInput>>>;
  branch?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['String']>;
  recipient?: InputMaybe<Scalars['ID']>;
};

export type VendorOrder = {
  direction: OrderDirection;
  field?: InputMaybe<VendorOrderField>;
};

export type VendorOrderAccept = {
  __typename?: 'VendorOrderAccept';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type VendorOrderAcceptInput = {
  acceptedMedications?: InputMaybe<Array<InputMaybe<MedicationOrderLineInput>>>;
};

export type VendorOrderCancel = {
  __typename?: 'VendorOrderCancel';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type VendorOrderDeliveryStatusUpdate = {
  __typename?: 'VendorOrderDeliveryStatusUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export enum VendorOrderField {
  Created = 'CREATED',
  Name = 'NAME',
  Nearest = 'NEAREST',
  OrderCount = 'ORDER_COUNT',
  Rating = 'RATING'
}

export type VendorOrderReject = {
  __typename?: 'VendorOrderReject';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type VendorProductFilterInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  priceAmount?: InputMaybe<Scalars['Float']>;
  productBrands?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type VendorProductSortingInput = {
  direction: OrderDirection;
  field: VariantSortField;
};

export type VendorProductVariant = Node & {
  __typename?: 'VendorProductVariant';
  created: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  price?: Maybe<Money>;
  priceAmount?: Maybe<Scalars['Float']>;
  pricing?: Maybe<VariantPricingInfo>;
  productVariant: ProductVariant;
  soldCount: Scalars['Int'];
  stocks?: Maybe<Array<Maybe<Stock>>>;
  trackInventory: Scalars['Boolean'];
  vendor?: Maybe<Vendor>;
  vendorId: Scalars['Int'];
  wishlistItems: WishlistItemCountableConnection;
};


export type VendorProductVariantWishlistItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VendorProductVariantCountableConnection = {
  __typename?: 'VendorProductVariantCountableConnection';
  edges: Array<VendorProductVariantCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VendorProductVariantCountableEdge = {
  __typename?: 'VendorProductVariantCountableEdge';
  cursor: Scalars['String'];
  node: VendorProductVariant;
};

export type VendorProductVariantStocksCreate = {
  __typename?: 'VendorProductVariantStocksCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  stockErrors: Array<StockError>;
  vendorProductVariant?: Maybe<VendorProductVariant>;
};

export type VendorProductVariantStocksCreateInput = {
  price: Scalars['Decimal'];
  productVariant: Scalars['ID'];
  stocks: Array<StockInput>;
};

export type VendorProductVariantStocksUpdate = {
  __typename?: 'VendorProductVariantStocksUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  stockErrors: Array<StockError>;
  vendorProductVariant?: Maybe<VendorProductVariant>;
};

export type VendorProductVariantStocksUpdateInput = {
  price?: InputMaybe<Scalars['Decimal']>;
  productVariant: Scalars['ID'];
  stocks?: InputMaybe<Array<StockInput>>;
};

export type VendorRating = Node & {
  __typename?: 'VendorRating';
  comment?: Maybe<Scalars['String']>;
  customer?: Maybe<User>;
  id: Scalars['ID'];
  rating: Scalars['Float'];
  vendor?: Maybe<Vendor>;
};

export type VendorRatingCountableConnection = {
  __typename?: 'VendorRatingCountableConnection';
  edges: Array<VendorRatingCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VendorRatingCountableEdge = {
  __typename?: 'VendorRatingCountableEdge';
  cursor: Scalars['String'];
  node: VendorRating;
};

export type VendorRatingInput = {
  comment?: InputMaybe<Scalars['String']>;
  rating: Scalars['Decimal'];
  vendor: Scalars['ID'];
};

export type VendorRegister = {
  __typename?: 'VendorRegister';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorRejectionReason = Node & {
  __typename?: 'VendorRejectionReason';
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  reason: Scalars['String'];
  vendor: Vendor;
};

export type VendorRejectionReasonCountableConnection = {
  __typename?: 'VendorRejectionReasonCountableConnection';
  edges: Array<VendorRejectionReasonCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VendorRejectionReasonCountableEdge = {
  __typename?: 'VendorRejectionReasonCountableEdge';
  cursor: Scalars['String'];
  node: VendorRejectionReason;
};

export type VendorRejectionReasonInput = {
  reason: Scalars['String'];
  vendor: Scalars['ID'];
};

export type VendorSendMessage = {
  __typename?: 'VendorSendMessage';
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  message?: Maybe<Message>;
};

export type VendorSubscription = Node & {
  __typename?: 'VendorSubscription';
  created: Scalars['DateTime'];
  currency: Scalars['String'];
  fixedCost?: Maybe<Money>;
  fixedCostAmount: Scalars['Float'];
  fixedOrderCost?: Maybe<Money>;
  fixedOrderCostAmount: Scalars['Float'];
  fixedOrderPercentage: Scalars['Float'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  modified: Scalars['DateTime'];
  plan?: Maybe<Plan>;
  validFrom: Scalars['DateTime'];
  validTill?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<Vendor>;
};

export type VendorSubscriptionCountableConnection = {
  __typename?: 'VendorSubscriptionCountableConnection';
  edges: Array<VendorSubscriptionCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VendorSubscriptionCountableEdge = {
  __typename?: 'VendorSubscriptionCountableEdge';
  cursor: Scalars['String'];
  node: VendorSubscription;
};

export enum VendorType {
  PartnerWarehouse = 'PARTNER_WAREHOUSE',
  Pharmacy = 'PHARMACY',
  Phc = 'PHC'
}

export enum VendorTypeEnum {
  PartnerWarehouse = 'PARTNER_WAREHOUSE',
  Pharmacy = 'PHARMACY',
  Phc = 'PHC'
}

export type VendorUpdate = {
  __typename?: 'VendorUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorUpdateAdminInput = {
  acceptsDelivery?: InputMaybe<Scalars['Boolean']>;
  acceptsPickup?: InputMaybe<Scalars['Boolean']>;
  addressInput?: InputMaybe<AddressInput>;
  authorityCode?: InputMaybe<Scalars['String']>;
  backGroundImage?: InputMaybe<Scalars['String']>;
  bankInfo?: InputMaybe<VendorBankInfoInput>;
  branchType?: InputMaybe<VendorBranchTypeEnum>;
  branchWorkingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  branchWorkingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
  cluster?: InputMaybe<Scalars['String']>;
  commercialRegistrationNumber?: InputMaybe<Scalars['String']>;
  contactMobileNumber?: InputMaybe<Scalars['String']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']>;
  deliveryMinFrom?: InputMaybe<Scalars['Int']>;
  deliveryMinTo?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  hasMultipleBranches?: InputMaybe<Scalars['Boolean']>;
  hasOwnDrivers?: InputMaybe<Scalars['Boolean']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isVip?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  managersContactInfo?: InputMaybe<VendorManagersContactInfoInput>;
  maxNumberOfUsers?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nameAr?: InputMaybe<Scalars['String']>;
  nationalId?: InputMaybe<Scalars['String']>;
  operationStatus?: InputMaybe<Scalars['String']>;
  orgIdNhic?: InputMaybe<Scalars['String']>;
  ownerName?: InputMaybe<Scalars['String']>;
  priceRange?: InputMaybe<PriceRangeEnum>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<SubscriptionInput>;
  taxLicenseNumber?: InputMaybe<Scalars['String']>;
  tradeName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<VendorTypeEnum>;
  virtualGroup?: InputMaybe<Scalars['String']>;
};

export type VendorUpdateIsIntegrated = {
  __typename?: 'VendorUpdateIsIntegrated';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorUpdateIsIntegratedInput = {
  isIntegrated: Scalars['Boolean'];
};

export type VendorUserCreate = {
  __typename?: 'VendorUserCreate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type VendorUserCreateInput = {
  appRole: AppRoleTypes;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobile: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type VendorUserDelete = {
  __typename?: 'VendorUserDelete';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type VendorUserUpdate = {
  __typename?: 'VendorUserUpdate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type VendorUserUpdateInput = {
  appRole?: InputMaybe<AppRoleTypes>;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  firstName?: InputMaybe<Scalars['String']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type VendorWebhook = {
  __typename?: 'VendorWebhook';
  errors?: Maybe<Array<Maybe<IntegrationApiErrorCodes>>>;
  isActive?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
  vendor?: Maybe<Vendor>;
  vendorUrl?: Maybe<Scalars['String']>;
};

export type VendorWebhookInput = {
  isActive: Scalars['Boolean'];
  secretKey: Scalars['String'];
  vendor: Scalars['ID'];
  vendorUrl: Scalars['String'];
};

export type Wallet = Node & {
  __typename?: 'Wallet';
  balance?: Maybe<Money>;
  balanceAmount: Scalars['Float'];
  created: Scalars['DateTime'];
  currency: Scalars['String'];
  customer?: Maybe<User>;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  transactions: WalletTransactionCountableConnection;
};


export type WalletTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type WalletCountableConnection = {
  __typename?: 'WalletCountableConnection';
  edges: Array<WalletCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type WalletCountableEdge = {
  __typename?: 'WalletCountableEdge';
  cursor: Scalars['String'];
  node: Wallet;
};

export type WalletError = {
  __typename?: 'WalletError';
  code: WalletErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum WalletErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type WalletFilterInput = {
  balanceAmount?: InputMaybe<Scalars['Float']>;
  balanceAmount_Gt?: InputMaybe<Scalars['Float']>;
  balanceAmount_Lt?: InputMaybe<Scalars['Float']>;
};

export type WalletOrder = {
  direction: OrderDirection;
  field?: InputMaybe<WalletOrderField>;
};

export enum WalletOrderField {
  Balance = 'BALANCE'
}

export type WalletTransaction = Node & {
  __typename?: 'WalletTransaction';
  amount?: Maybe<Scalars['Float']>;
  created: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  type: WalletTransactionType;
  wallet: Wallet;
};

export type WalletTransactionCountableConnection = {
  __typename?: 'WalletTransactionCountableConnection';
  edges: Array<WalletTransactionCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type WalletTransactionCountableEdge = {
  __typename?: 'WalletTransactionCountableEdge';
  cursor: Scalars['String'];
  node: WalletTransaction;
};

export type WalletTransactionCreate = {
  __typename?: 'WalletTransactionCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<WalletError>;
  wallet?: Maybe<Wallet>;
};

export type WalletTransactionInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<WalletTransactionTypeEnum>;
};

export enum WalletTransactionType {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export enum WalletTransactionTypeEnum {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type WasfatyDiagnosis = {
  __typename?: 'WasfatyDiagnosis';
  code?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type WasfatyEncounter = {
  __typename?: 'WasfatyEncounter';
  end?: Maybe<Scalars['String']>;
  facilityID?: Maybe<Scalars['String']>;
  patientID?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type WasfatyPrescription = {
  __typename?: 'WasfatyPrescription';
  activities?: Maybe<Array<Maybe<Activity>>>;
  clinician?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  dateOrdered?: Maybe<Scalars['String']>;
  denialCode?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Array<Maybe<WasfatyDiagnosis>>>;
  dispenseReferenceID?: Maybe<Scalars['String']>;
  encounter?: Maybe<WasfatyEncounter>;
  gross?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  idPayer?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  memberID?: Maybe<Scalars['String']>;
  nationalIDNumber?: Maybe<Scalars['String']>;
  net?: Maybe<Scalars['Float']>;
  patientName?: Maybe<Scalars['String']>;
  patientShare?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  requestReferenceNumber?: Maybe<Scalars['String']>;
  requestType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type Weight = {
  __typename?: 'Weight';
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export enum WeightUnitsEnum {
  G = 'G',
  Kg = 'KG',
  Lb = 'LB',
  Oz = 'OZ'
}

export type Wishlist = Node & {
  __typename?: 'Wishlist';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<WishlistItem>>>;
};

export type WishlistAddVendorProductVariantMutation = {
  __typename?: 'WishlistAddVendorProductVariantMutation';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  wishlist?: Maybe<Wishlist>;
  wishlistErrors: Array<WishlistError>;
};

export type WishlistError = {
  __typename?: 'WishlistError';
  code: WishlistErrorCode;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum WishlistErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type WishlistItem = Node & {
  __typename?: 'WishlistItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  vendorProductVariant: VendorProductVariant;
  wishlist: Wishlist;
};

export type WishlistItemCountableConnection = {
  __typename?: 'WishlistItemCountableConnection';
  edges: Array<WishlistItemCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type WishlistItemCountableEdge = {
  __typename?: 'WishlistItemCountableEdge';
  cursor: Scalars['String'];
  node: WishlistItem;
};

export type WishlistRemoveVendorProductVariantMutation = {
  __typename?: 'WishlistRemoveVendorProductVariantMutation';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  wishlist?: Maybe<Wishlist>;
  wishlistErrors: Array<WishlistError>;
};

export type WorkingHour = {
  __typename?: 'WorkingHour';
  day?: Maybe<DayOfWeekEnum>;
  openTimeRanges?: Maybe<Array<Maybe<TimeRange>>>;
};

export type WorkingHourInput = {
  day: DayOfWeekEnum;
  openTimeRanges: Array<InputMaybe<TimeRangeInput>>;
};

export type WorkingHourOverride = {
  __typename?: 'WorkingHourOverride';
  date?: Maybe<Scalars['Date']>;
  openTimeRanges?: Maybe<Array<Maybe<TimeRange>>>;
};

export type WorkingHourOverrideInput = {
  date: Scalars['Date'];
  openTimeRanges: Array<InputMaybe<TimeRangeInput>>;
};

export enum Period {
  HalfYear = 'HALF_YEAR',
  Monthly = 'MONTHLY',
  ThreeMonths = 'THREE_MONTHS',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}
