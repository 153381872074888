import { useTranslation } from "@health/i18n";
import {
  formatGraphQLError,
  formatMessageErrors,
  OrderStatusEnum,
  OrderStatusForceUpdateMutation,
  useOrderStatusForceUpdateMutation,
} from "@health/queries";
import { useToasts } from "@health/ui";
import { useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEditStatusHook = ({ id, refetch }: any) => {
  const { t } = useTranslation("Admin");

  const { addToast } = useToasts();

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<string>("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [orderStatusForceUpdate, { loading: isSubmitting }] = useOrderStatusForceUpdateMutation({
    onCompleted: (response: OrderStatusForceUpdateMutation) => {
      const errors = response?.orderStatusForceUpdate?.orderErrors;
      if (!errors?.length) {
        addToast(t("Order Status changed successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        refetch();
      } else if (errors?.length > 0) {
        addToast(formatMessageErrors(errors), {
          appearance: "error",
          autoDismiss: true,
        });
        console.error(t("<<Order Status>>"), formatMessageErrors(errors), { appearance: "error", autoDismiss: true });
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedError = formatGraphQLError(graphQLErrors);
      const message = formattedError || t("Change order status Failed");
      addToast(t(message), {
        appearance: "error",
        autoDismiss: true,
      });
      console.error(t("Change order status Failed"), formattedError);
    },
  });

  const handleClickOpen = () => {
    setOpen(!open);
    setShowConfirmationDialog(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setStatus(value.toUpperCase());
  };

  const handleShowConfirmationDialog = () => {
    setShowConfirmationDialog(true);
  };

  const handleSendStatus = () => {
    setOpen(false);
    setShowConfirmationDialog(false);

    orderStatusForceUpdate({
      variables: {
        id: id, // we need to get the orderId instead of prescription
        input: {
          status: status as OrderStatusEnum,
        },
      },
    });
  };

  return {
    open,
    status,
    handleChange,
    handleClickOpen,
    handleSendStatus,
    handleShowConfirmationDialog,
    showConfirmationDialog,
    isSubmitting,
  };
};
