import { useTranslation } from "@health/i18n";
import {
  Button,
  CustomDialog,
  CustomIcon,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from "@health/ui";
import React, { FC } from "react";
import { useEditStatusHook } from "./EditStatus.hook";
// eslint-disable-next-line @typescript-eslint/ban-types
type EditStatusModelProps = {
  id;
  refetch: () => any;
};

export const EditStatusModel: FC<EditStatusModelProps> = ({ id, refetch }: any) => {
  const { t } = useTranslation("Admin");
  // eslint-disable-next-line prettier/prettier
  const {
    open,
    status,
    handleChange,
    handleClickOpen,
    showConfirmationDialog,
    handleSendStatus,
    handleShowConfirmationDialog,
    // eslint-disable-next-line prettier/prettier
  } = useEditStatusHook({ id, refetch });

  return (
    <CustomDialog
      title={t("Edit Status")}
      open={open}
      onCloseModal={handleClickOpen}
      maxWidth='sm'
      button={
        <StyledButton variant='outlined' onClick={handleClickOpen}>
          {t("Edit")}
        </StyledButton>
      }
    >
      {showConfirmationDialog && status.length !== 0 ? (
        <Stack>
          <CustomIcon icon='expandArrow' color='white' />
          <Typography p={"1rem 0 3rem"} sx={{ textAlign: "center" }}>
            {t("Are you sure you want to change the status to")}
            {"  "}
            <Typography component='span' align='left'>
              {t(`${status}`)}
            </Typography>
          </Typography>
          <Button onClick={handleSendStatus}>{t("Confirm")}</Button>
        </Stack>
      ) : (
        <Stack>
          <RadioGroup name='job-experience-group' aria-labelledby='job-experience-group-label' onChange={handleChange} value={status}>
            <FormControlLabel control={<Radio />} label={t("Delivered")} value='DELIVERED' />
            <FormControlLabel control={<Radio />} label={t("Failed")} value='FAILED' />
          </RadioGroup>
          <Typography color='warning' p={"0.5rem 1rem"}>
            {`"`}
            {t(
              "Changing the status will not update payment claims or other workflow processes, as overriding these elements is non-trivial"
            )}
            {`"`}
          </Typography>
          <DialogActions>
            <Button disabled={status.length === 0} onClick={handleShowConfirmationDialog}>
              {t("Proceed")}
            </Button>
          </DialogActions>
        </Stack>
      )}
    </CustomDialog>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.primary?.dark,
  border: "none",
  "&.MuiButton-containedPrimary": {
    backgroundColor: "transparent",
    color: theme.palette.primary?.dark,
    "& .MuiButton-startIcon > svg > path": {
      fill: "#1B2346",
    },
  },
  transition: theme.transitions.create(["background-color", "color"], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.complex,
  }),
  "&:hover": {
    border: "none",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    transition: theme.transitions.create(["background-color", "color"], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
    "& .MuiButton-startIcon > svg > path": {
      fill: theme.palette.common.white,
    },
  },
}));
